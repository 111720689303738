import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaUsers,
  FaRegClipboard,
  FaUsersCog,
  FaShoppingCart,
  FaComments,
  FaBoxOpen,
  FaClipboardList,
} from 'react-icons/fa';
import StatCard from './StaticCard'; // Adjust the import path as necessary
import CustomTable, { Column } from '../../components/CustomTable';
import AreaChart from '../../components/Charts/AreaChart';
import { chartData } from '../../components/Charts/chartData';
import { load, onChartLoad } from '../../utils/helper';

const OperatorDashboard: React.FC = () => {
  const { t } = useTranslation();

  const chartProperties = {
    style: { textAlign: 'center' },

    primaryXAxis: {
      valueType: 'DateTime',
      labelFormat: 'y',
      majorGridLines: { width: 0 },
      intervalType: 'Years',
      minimum: new Date(2001, 0, 1),
      maximum: new Date(2012, 0, 1),
      edgeLabelPlacement: 'Shift',
    },
    primaryYAxis: {
      labelFormat: '{value}%',
      lineStyle: { width: 0 },
      maximum: 4,
      interval: 1,
      majorTickLines: { width: 0 },
      minorTickLines: { width: 0 },
    },
    load: load.bind(this),
    width: '100%',
    height: '200',
    legendSettings: { enableHighlight: true },
    chartArea: { border: { width: 0 } },
    title: t('inflationRateInPercentage', 'Inflation Rate in Percentage'),
    loaded: onChartLoad.bind(this),
    tooltip: { enable: true },
  };
  const seriesConfigurations = [
    {
      dataSource: chartData[0],
      fill: '#FCC325', // Dynamic color
      xName: 'x',
      yName: 'y',
      name: t('us', 'US'), // Dynamic title
      marker: {
        visible: true,
        isFilled: true,
        height: 6,
        width: 6,
        shape: 'Circle', // Dynamic shape
      },
      opacity: 0.1,
      type: 'SplineArea',
      width: 2,
      border: { width: 2 },
    },
    {
      dataSource: chartData[1],
      fill: '#FEE39A', // Dynamic color
      xName: 'x',
      yName: 'y',
      name: t('france', 'France'), // Dynamic title
      marker: {
        visible: true,
        isFilled: true,
        height: 7,
        width: 7,
        shape: 'Diamond', // Dynamic shape
      },
      opacity: 0.5,
      type: 'SplineArea',
      width: 2,
      border: { width: 2 },
    },
  ];

  const columns: Column[] = [
    { title: t('activity', 'Activity'), dataKey: 'activity', type: 'string' },
    { title: t('date', 'Date'), dataKey: 'date', type: 'string' },
    { title: t('action', 'Action'), dataKey: 'action', type: 'string' },
  ];

  const mockData = [
    { id: 1, activity: 'John Doe', date: '06/08/24', role: 'Admin' },
    { id: 2, activity: 'Jane Smith', date: '12/20/24', role: 'User' },
  ];

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="grid grid-cols-4 gap-6 mb-6">
        <StatCard
          title={t('applications', 'Applications')}
          value={1224}
          icon={FaRegClipboard}
        />
        <StatCard
          title={t('verifiedSuppliers', 'Verified Suppliers')}
          value={127}
          icon={FaUsers}
        />
        <StatCard
          title={t('suppliers', 'Suppliers')}
          value={220}
          icon={FaUsersCog}
        />
        <StatCard
          title={t('verifiedBuyers', 'Verified Buyers')}
          value={202}
          icon={FaShoppingCart}
        />
        <StatCard title={t('buyers', 'Buyers')} value={15} icon={FaUsers} />
        <StatCard
          title={t('ticketsFeedback', 'Tickets/Feedback')}
          value={36}
          icon={FaComments}
        />
        <StatCard
          title={t('ticketsFeedback', 'Tickets/Feedback')}
          value={36}
          icon={FaComments}
        />
        <StatCard
          title={t('goodsServices', 'Goods & Services')}
          value={50}
          icon={FaBoxOpen}
        />
      </div>

      {/* Total Users Graph */}
      <div className="bg-white p-6 rounded shadow mb-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">
            {t('totalUsers', 'Total Users')}
          </h3>
          <button className="bg-blue-500 text-white px-4 py-2 rounded">
            {t('downloadReport', 'Download Report')}
          </button>
        </div>
        <div className="h-[200px]">
          {/* Uncomment this line to show the graph */}
          {/* <Line data={data} options={options} /> */}
          <AreaChart
            chartProperties={chartProperties}
            seriesConfigurations={seriesConfigurations}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        {/* Company Activities */}
        <div className="bg-white p-6 rounded shadow">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">
              {t('companyActivities', 'Company Activities')}
            </h3>
            <a href="#" className="text-blue-500 text-sm">
              {t('seeAll', 'See all')}
            </a>
          </div>
          <CustomTable
            data={mockData}
            columns={columns}
            pageCount={1} // No pagination as mock data has only one page
            showPagination={false}
            showSearch={false}
            // title="Company Activities"
          />
        </div>

        {/* Notifications */}
        <div className="bg-white p-6 rounded shadow">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">
              {t('notifications', 'Notifications')}
            </h3>
            <a href="#" className="text-blue-500 text-sm">
              {t('seeAll', 'See all')}
            </a>
          </div>
          <ul className="space-y-4">
            <li className="flex items-center space-x-3">
              <div className="bg-red-100 text-red-500 rounded-full p-2">
                <FaComments />
              </div>
              <div>
                <p className="text-gray-600">
                  {t('complaintFrom', 'Complaint from')} Green Oil Energy
                </p>
                <p className="text-sm text-gray-400">
                  10 {t('february', 'February')}, 2024
                </p>
              </div>
            </li>
            <li className="flex items-center space-x-3">
              <div className="bg-yellow-100 text-yellow-500 rounded-full p-2">
                <FaClipboardList />
              </div>
              <div>
                <p className="text-gray-600">
                  {t('feedbackFrom', 'Feedback from')} Alzar Oil Ltd.
                </p>
                <p className="text-sm text-gray-400">
                  9 {t('january', 'January')}, 2024
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OperatorDashboard;
