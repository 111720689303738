import React, { useState } from 'react';
import { XIcon } from 'lucide-react';

interface GoodsAndServicesItem {
  itemName: string;
  description: string;
  quantity: number;
  estimatedCost: number;
  procurementMethod: string;
  warrantyDuration: string;
  warrantyType: string;
}

interface GoodsAndServicesProps {
  formData: {
    projectGoodsAndServices: GoodsAndServicesItem[];
  };
  handleChange: (e: { target: { name: string; value: any } }) => void;
}

const GoodsAndServices: React.FC<GoodsAndServicesProps> = ({
  formData,
  handleChange,
}) => {
  const [viewModalItem, setViewModalItem] =
    useState<GoodsAndServicesItem | null>(null);
  const [editModalItem, setEditModalItem] =
    useState<GoodsAndServicesItem | null>(null);
  const [editModalIndex, setEditModalIndex] = useState<number | null>(null); // New state for the index
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const addGoodsAndServices = (newItem: GoodsAndServicesItem) => {
    handleChange({
      target: {
        name: 'projectGoodsAndServices',
        value: [...formData.projectGoodsAndServices, newItem],
      },
    });
    setIsAddModalOpen(false);
  };

  const updateGoodsAndServices = (
    updatedItem: GoodsAndServicesItem,
    index: number
  ) => {
    const updatedGoodsAndServices = formData.projectGoodsAndServices.map(
      (item, i) => {
        if (i === index) {
          return updatedItem;
        }
        return item;
      }
    );

    handleChange({
      target: {
        name: 'projectGoodsAndServices',
        value: updatedGoodsAndServices,
      },
    });
    setEditModalItem(null);
    setEditModalIndex(null); // Reset index
  };

  return (
    <div className="space-y-4 mx-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">
          Goods and Service to be procured
        </h2>
        <button
          onClick={() => setIsAddModalOpen(true)}
          className="px-4 py-2 bg-white border border-black text-black rounded-md"
        >
          + Add
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 text-left">Item Name</th>
              <th className="p-2 text-left">Quantity (Units)</th>
              <th className="p-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {formData.projectGoodsAndServices.map((item, index) => (
              <tr key={index} className="border-b">
                <td className="p-2">{item.itemName}</td>
                <td className="p-2">{item.quantity}</td>
                <td className="p-2">
                  <button
                    onClick={() => setViewModalItem(item)}
                    className="text-green-600 mr-2"
                  >
                    View
                  </button>
                  <button
                    onClick={() => {
                      setEditModalItem(item);
                      setEditModalIndex(index);
                    }}
                    className="text-black-500"
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* View Modal */}
      {viewModalItem && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-3xl w-full max-w-4xl relative">
            {/* Close Button */}
            <button
              onClick={() => setViewModalItem(null)}
              className="absolute top-4 right-4 bg-gray-100 rounded-full p-2"
            >
              <XIcon className="w-5 h-5" />
            </button>

            {/* Title and Edit Button */}
            <div className="flex justify-between items-center mb-8">
              <h3 className="text-2xl font-semibold text-gray-800">
                Goods and Service to be procured
              </h3>
              {formData.projectGoodsAndServices.map((item, index) => (
                <button
                  onClick={() => {
                    setEditModalItem(item);
                    setEditModalIndex(index);
                  }}
                  className="px-4 py-2 mr-7 bg-primary text-black rounded-md font-medium hover:bg-yellow-500 transition"
                >
                  Edit
                </button>
              ))}
            </div>

            {/* Procurement Details */}
            <div className="space-y-6 text-gray-700">
              <div>
                <p className="font-semibold">Item name</p>
                <p>{viewModalItem.itemName}</p>
              </div>
              <div>
                <p className="font-semibold">Description</p>
                <p>{viewModalItem.description}</p>
              </div>
              <div className="grid grid-cols-3 gap-6">
                <div>
                  <p className="font-semibold">Quantity</p>
                  <p>{viewModalItem.quantity} units</p>
                </div>
                <div>
                  <p className="font-semibold">Estimated cost</p>
                  <p>${viewModalItem.estimatedCost}</p>
                </div>
                <div>
                  <p className="font-semibold">Procurement method</p>
                  <p>{viewModalItem.procurementMethod}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit/Add Modal */}
      {(editModalItem || isAddModalOpen) && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-3xl w-full max-w-5xl">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-base font-semibold">
                {editModalItem ? 'Edit' : 'Add'} Good or Service to be procured
              </h3>
              <button
                onClick={() => {
                  setEditModalItem(null);
                  setIsAddModalOpen(false);
                  setEditModalIndex(null);
                }}
              >
                <XIcon className="w-5 h-5" />
              </button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target as HTMLFormElement);
                const newItem = Object.fromEntries(
                  formData.entries()
                ) as unknown as GoodsAndServicesItem;
                if (editModalItem !== null && editModalIndex !== null) {
                  updateGoodsAndServices(newItem, editModalIndex);
                } else {
                  addGoodsAndServices(newItem);
                }
              }}
              className="space-y-4"
            >
              <div>
                <label htmlFor="itemName" className="block mb-1">
                  Item name
                </label>
                <input
                  id="itemName"
                  name="itemName"
                  placeholder="Name of item"
                  defaultValue={editModalItem?.itemName}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div>
                <label htmlFor="description" className="block mb-1">
                  Description
                </label>
                <input
                  id="description"
                  placeholder="Describe the item"
                  name="description"
                  defaultValue={editModalItem?.description}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <label htmlFor="quantity" className="block mb-1">
                    Quantity
                  </label>
                  <input
                    type="number"
                    placeholder="Quantity"
                    id="quantity"
                    name="quantity"
                    defaultValue={editModalItem?.quantity}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="estimatedCost" className="block mb-1">
                    Estimated cost ($)
                  </label>
                  <input
                    type="number"
                    placeholder="Estimated Cost"
                    id="estimatedCost"
                    name="estimatedCost"
                    defaultValue={editModalItem?.estimatedCost}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="procurementMethod" className="block mb-1">
                    Procurement method
                  </label>
                  <select
                    id="procurementMethod"
                    name="procurementMethod"
                    defaultValue={editModalItem?.procurementMethod}
                    className="w-full p-2 border rounded"
                    required
                  >
                    <option value="">Select</option>
                    <option value="Request for proposal">
                      Request for proposal
                    </option>
                    <option value="Tender">Tender</option>
                  </select>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <label htmlFor="warrantyDuration" className="block mb-1">
                    Warranty duration
                  </label>
                  <input
                    type="number"
                    placeholder="Warranty Duration"
                    id="warrantyDuration"
                    name="warrantyDuration"
                    defaultValue={editModalItem?.warrantyDuration}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="warrantyType" className="block mb-1">
                    Warranty type
                  </label>
                  <select
                    id="warrantyType"
                    name="warrantyType"
                    defaultValue={editModalItem?.warrantyType}
                    className="w-full p-2 border rounded"
                    required
                  >
                    <option value="">Select warranty type</option>
                    <option value="Standard">Standard</option>
                    <option value="Extended">Extended</option>
                  </select>
                </div>
                <div className="flex justify-end items-start">
                  <button
                    type="submit"
                    className="w-3/5 h-12 p-2 bg-primary mt-7 text-black rounded-md"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default GoodsAndServices;
