import React, { useState, useEffect } from 'react';
import { CiFilter } from 'react-icons/ci';
import { BsThreeDotsVertical } from 'react-icons/bs';
import SearchBox from './SearchBox'; // Ensure to import the SearchBox component
import DropdownMenu from './DropdownMenu'; // Ensure to import your DropdownMenu component
import Pagination from './Pagination'; // Ensure to import your Pagination component
import {
  procurementWorkflowStatusToString,
  workflowParameterTypeToString,
  workflowTypeToString,
} from '../utils/helper';

type ColumnType =
  | 'string'
  | 'number'
  | 'action'
  | 'status'
  | 'applicationType'
  | 'parameterType'
  | 'procurementWorkflowStatus'
  | 'dateTime';

export interface IPageRequest {
  pageNumber: number;
  pageSize: number;
  workflowId?: string;
}
export interface Column {
  title: string;
  dataKey?: string; // The key to access data in the row
  type: ColumnType;
  statusColors?: { [key: string]: string }; // Optional colors for status type
  path?: string;
  actions?: {
    label: string;
    action: (item: any) => void;
    className?: string;
    status?: boolean;
  }[]; // Optional actions for the column
}

interface CustomTableProps {
  data: any[]; // Array of data objects
  columns: Column[];
  pageCount?: number; // Optional, if pagination is used
  showPagination?: boolean; // Control visibility of pagination
  showSearch?: boolean; // Control visibility of search box
  onSearch?: (value: string) => void; // Callback for search input
  showAddButton?: boolean; // Control visibility of add button
  addButtonText?: string; // Text for the add button
  title?: string; // Text for the page title
  showTitle?: boolean;
  onAddButtonClick?: () => void; // Callback for add button click
  onPageChange?: (selectedPage: number) => void;
}

const CustomTable: React.FC<CustomTableProps> = ({
  data,
  columns,
  pageCount = 0,
  showPagination = false,
  showSearch = false,
  onSearch,
  showAddButton = false,
  addButtonText = 'Add',
  title = '',
  showTitle,
  onAddButtonClick,
  onPageChange,
}) => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );

  const [currentItems, setCurrentItems] = useState<any[]>(data); // State for current items

  // Effect to update currentItems based on pagination
  useEffect(() => {
    setCurrentItems(data);
  }, [data]);

  const handleSearch = (value: string) => {
    if (onSearch) {
      onSearch(value);
    }
  };

  const toggleDropdown = (index: number) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handlePageClick = (event: { selected: number }) => {
    if (onPageChange) {
      onPageChange(event.selected); // Emit the selected page to the parent component
    }
  };

  const getStatusColor = (status: string | undefined, column: Column) => {
    if (!status || !column.statusColors) return 'bg-gray-200 text-black'; // Default style
    const colorClass = column.statusColors[status.toLowerCase()];
    return colorClass || 'bg-gray-200 text-black'; // Fallback if status is not in the color map
  };

  const getValue = (item: any, column: Column) => {
    if (column.dataKey) {
      if (column.type === 'dateTime') {
        const date = new Date(item[column.dataKey as keyof typeof item]);
        return date.toLocaleString();
      }
      return item[column.dataKey as keyof typeof item] ?? '-';
    }
    if (column.path) {
      return (
        column.path.split('.').reduce((acc, part) => acc?.[part], item) ?? '-'
      );
    }
    return '-';
  };

  return (
    <div>
      <div className={`flex items-center justify-between space-x-2 mb-4`}>
        <div>{showTitle && <h2 className="text-3xl my-16">{title}</h2>}</div>

        {/* Conditional Rendering for Add Button */}
        {showAddButton && (
          <button
            onClick={onAddButtonClick}
            className=" px-10 py-3 border border-[#101828] text-[#101828] font-semibold rounded-lg"
          >
            {addButtonText}
          </button>
        )}
      </div>

      <div className="flex items-center ml-auto space-x-2 my-4">
        {/* Conditional Rendering for Search Box */}
        {showSearch && (
          <div
            className={`flex items-center ${showAddButton ? 'order-1' : 'ml-auto'}`}
          >
            {!showAddButton && <CiFilter size={24} className="ml-3" />}
            <SearchBox placeholder="Search steps..." onSearch={handleSearch} />
            {showAddButton && <CiFilter size={24} className="ml-3" />}
          </div>
        )}
      </div>

      {/* Table */}
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead className="bg-gray-200">
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className="border border-gray-300 px-4 py-3 text-base text-left"
              >
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(currentItems) &&
            currentItems.map((item, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <td
                    key={colIndex}
                    className="border border-gray-300 text-base px-4 py-2 relative"
                  >
                    {column.type === 'action' ? (
                      <>
                        <button
                          onClick={() => toggleDropdown(rowIndex)}
                          className="text-gray-600"
                        >
                          <BsThreeDotsVertical />
                        </button>

                        {/* Render Dropdown Menu */}
                        {openDropdownIndex === rowIndex && (
                          <div className="absolute z-10 left-20 mt-1 bg-white border border-gray-300 rounded-lg shadow-lg">
                            <DropdownMenu
                              open={true}
                              onClose={() => setOpenDropdownIndex(null)}
                              items={
                                columns
                                  .find((col) => col.type === 'action')
                                  ?.actions?.filter(
                                    (action) => action.status === true
                                  )
                                  .map((action) => ({
                                    label: action.label,
                                    action: () => {
                                      action.action(currentItems[rowIndex]); // Execute action with the current item
                                      setOpenDropdownIndex(null); // Close dropdown after action
                                    },
                                    className: action.className, // Pass the optional class name
                                  })) || []
                              } // Default to an empty array if no actions are found
                            />
                          </div>
                        )}
                      </>
                    ) : column.type === 'status' ? (
                      // Check if the column is of type 'status'
                      <span
                        className={`border rounded-lg px-2 py-1 ${getStatusColor(item[column.dataKey as keyof typeof item], column)}`}
                      >
                        {item[column.dataKey as keyof typeof item] || '-'}
                      </span>
                    ) : column.type === 'parameterType' ? (
                      workflowParameterTypeToString(
                        item[column.dataKey as keyof typeof item]
                      )
                    ) : column.type === 'applicationType' ? (
                      workflowTypeToString(
                        item[column.dataKey as keyof typeof item]
                      )
                    ) : column.type === 'procurementWorkflowStatus' ? (
                      procurementWorkflowStatusToString(
                        item[column.dataKey as keyof typeof item]
                      )
                    ) : (
                      // Default handling for 'string' or 'number'
                      getValue(item, column)
                    )}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>

      {/* Pagination */}
      {showPagination && (
        <Pagination pageCount={pageCount} onPageChange={handlePageClick} />
      )}
    </div>
  );
};

export default CustomTable;
