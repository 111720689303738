import React, { useEffect, useState } from 'react';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import './Dashboard.css';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { getSupplierHistory } from '../../services/supplierService';
import { toast } from 'react-toastify';

const Dashboard = () => {
  const [history, setHistory] = useState<any>([]);
  const { t } = useTranslation();
  const { supplierId } = useGlobalContext();
  const cards = [
    { id: 1, number: 10, text: `${t('new_supplier', 'New Suppliers')}` },
    {
      id: 2,
      number: 50,
      text: `${t('verified_suppliers', 'Verified Suppliers')}`,
    },
    {
      id: 3,
      number: 25,
      text: `${t('pending_verification', 'Pending Verification')}`,
    },
    {
      id: 4,
      number: 5,
      text: `${t('rejected_suppliers', 'Rejected Suppliers')}`,
    },
  ];

  const getSupplierHistoryData = async () => {
    try {
      if (!supplierId) return;
      const response = await getSupplierHistory(supplierId);
      if (response?.isSuccess) {
        setHistory(response.data);
      } else {
        toast.error(response.errorMessage);
      }
    } catch (error) {
      toast.error('There was a problem with the fetch operation');
    }
  };

  useEffect(() => {
    getSupplierHistoryData();
  }, []);

  return (
    <div className="view-area">
      <div className=" mb-6">
        <h1 className=" font-semibold text-[24px]">
          {t('overview', 'Overview')}
        </h1>
        <p className="">
          {t('view_team_activities', 'View your team’s activities.')}
        </p>
      </div>

      {!supplierId && (
        <div className="cards-container">
          {cards.map((card) => (
            <div key={card.id} className="card flex">
              <div className=" ">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/chooseDemo/supplier-icon.svg`}
                  alt="Supplier Icon"
                />
              </div>
              <div className="card-info">
                <p className="card-number">{card.number}</p>
                <p className="text-[12px]">{card.text}</p>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="progress-section">
        <ProgressBar
          currentStages={history.currentStages}
          totalStages={history.totalStages}
          currentstep={history.currentstep}
          data={history.data}
        />
      </div>
    </div>
  );
};

export default Dashboard;
