import React, { useState, useEffect } from 'react';
import { Search, X } from 'lucide-react';
import axiosInstance from '../../axiosInstance';
import ProcurementDetailsForSupplier from './ProcurementDetailsForSupplier';

type ProcurementStatus =
  | 'Top Matches'
  | 'Newest'
  | 'Saved'
  | 'Approved'
  | 'Drafts';
type Tag = { tagName: string };

interface Procurement {
  procurementProjectId: string;
  projectTitle: string;
  projectDescription: string;
  prerequisites: string;
  status: number;
  lastUpdated: string;
  additionalInformation: string;
  tags: { tagName: string }[];
  industryId: string;
  isBiddingProject: boolean;
  supplierTypeToBid: string;
  projectGoodsAndServices: any[];
  expressionOfInterests: any[];
  technicalInformations: any[];
  commercialInformations: any[];
  referenceToWork: string;
  assignPointsToReferenceToWork: boolean;
  approvedDate: Date | null;
  biddingStartDate: Date;
  biddingEndDate: Date;
  bidRequirement: string;
  isTopMatch: boolean;
  canView: boolean;
  isSaved: boolean;
  accessRequested: boolean;
  accessRequestedToBid: boolean | null;
  canBid: boolean | null;
}

interface ApiResponse {
  data: Procurement[];
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
}

interface SaveResponse {
  isSuccess: boolean;
  data: {
    id: string;
    message: string;
  };
  errorMessage: null | string;
}
const baseUrl = process.env.REACT_APP_BASE_URL;

const ProcurementsForSupplier = () => {
  const [activeTab, setActiveTab] = useState('Tender Opportunities');
  const [activeFilter, setActiveFilter] =
    useState<ProcurementStatus>('Top Matches');
  const [searchTerm, setSearchTerm] = useState('');
  const [procurements, setProcurements] = useState<Procurement[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [savingIds, setSavingIds] = useState<Set<string>>(new Set());
  const [saveMessage, setSaveMessage] = useState<string | null>(null);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showProcurementDetails, setShowProcurementDetails] = useState(false);
  const [selectedProcurement, setSelectedProcurement] = useState<string | null>(
    null
  );
  const [selectedProcurement2, setSelectedProcurement2] =
    useState<Procurement | null>(null);
  const [requestLoading, setRequestLoading] = useState(false);

  useEffect(() => {
    fetchProcurements();
  }, []);

  const fetchProcurements = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get<ApiResponse>(
        `${baseUrl}/procurementProjects/getAllProcurementProjectsForSupplier`,
        {
          params: {
            status: 0,
            pageNumber: 1,
            pageSize: 1000,
          },
        }
      );
      setProcurements(response.data.data);
    } catch (err) {
      setError('Failed to fetch procurements');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (
    procurementId: string,
    currentSaveState: boolean
  ) => {
    setSavingIds((prev) => new Set(prev).add(procurementId));
    setSaveMessage(null);

    try {
      const response = await axiosInstance.post<SaveResponse>(
        `${baseUrl}/procurementProjects/saveProcurement`,
        {
          toSave: !currentSaveState,
          procurementProjectId: procurementId,
        }
      );

      if (response.data.isSuccess) {
        setProcurements((prev) =>
          prev.map((proc) =>
            proc.procurementProjectId === procurementId
              ? { ...proc, isSaved: !currentSaveState }
              : proc
          )
        );
        setSaveMessage(response.data.data.message);
      } else {
        setError(response.data.errorMessage || 'Failed to save procurement');
      }
    } catch (err) {
      setError('Failed to save procurement');
    } finally {
      setSavingIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(procurementId);
        return newSet;
      });

      setTimeout(() => {
        setSaveMessage(null);
      }, 3000);
    }
  };

  const handleRequestAccess = async () => {
    if (!selectedProcurement) return;

    setRequestLoading(true);
    try {
      const response = await axiosInstance.post(
        `${baseUrl}/procurementProjects/requestAccessToViewProcurement`,
        {
          procurementProjectId: selectedProcurement,
        }
      );

      if (response.data.isSuccess) {
        setShowRequestModal(false);
        setShowSuccessModal(true);
        setProcurements((prev) =>
          prev.map((proc) =>
            proc.procurementProjectId === selectedProcurement
              ? { ...proc, accessRequested: true }
              : proc
          )
        );
      } else {
        setError(response.data.errorMessage || 'Failed to request access');
      }
    } catch (err) {
      setError('Failed to request access');
    } finally {
      setRequestLoading(false);
    }
  };

  const formatDate = (dateString: string) => {
    const minutes = Math.floor(
      (new Date().getTime() - new Date(dateString).getTime()) / 1000 / 60
    );
    if (minutes < 60) return `${minutes}m ago`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours}h ago`;
    return `${Math.floor(hours / 24)}d ago`;
  };

  const filteredProcurements = procurements.filter((proc) => {
    const matchesSearch =
      proc.projectTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      proc.projectDescription.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFilter = {
      'Top Matches': proc.status === 0 && proc.isTopMatch,
      Newest: proc.status === 0,
      Saved: proc.status === 0 && proc.isSaved,
      Approved: proc.status === 0 && proc.canView,
      Drafts: proc.status === 3,
    }[activeFilter];

    return matchesSearch && matchesFilter;
  });

  const handleViewDetails = (procurement: Procurement) => {
    setSelectedProcurement2(procurement);
    setShowProcurementDetails(true);
  };

  const handleProcurementDetailsClose = () => {
    setSelectedProcurement2(null);
    setShowProcurementDetails(false);
  };

  const RequestModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-3xl w-1/5 h-72 p-6">
        <div className="grid justify-items-end">
          <button
            onClick={() => setShowRequestModal(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={15} />
          </button>
        </div>
        <div className="pt-10">
          <h2 className="text-2xl font-semibold text-center">
            Request Access?
          </h2>

          <p className="text-gray-600 mb-2 text-center">
            Request to access detailed project information.
          </p>
          <p className="text-gray-600 mb-6 text-center">
            Once accepted, you'll be able to view more details and submit your
            expression of interest.
          </p>
          <button
            onClick={handleRequestAccess}
            disabled={requestLoading}
            className="w-full bg-yellow-400 text-black py-2 rounded-lg font-medium hover:bg-yellow-500 disabled:opacity-50"
          >
            Send Request
          </button>
        </div>
      </div>
    </div>
  );

  const SuccessModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-3xl w-1/5 h-72 p-6 pt-14">
        <div className="flex justify-center mb-4">
          <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center">
            <svg
              className="w-8 h-8 text-green-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        </div>
        <h2 className="text-xl font-semibold text-center mb-2">Request Sent</h2>
        <p className="text-gray-600 text-center mb-6">
          You will be notified when your request has been approved.
        </p>
        <button
          onClick={() => setShowSuccessModal(false)}
          className="w-full bg-yellow-400 text-black py-2 rounded-lg font-medium hover:bg-yellow-500"
        >
          Okay
        </button>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50 relative">
      {showRequestModal && <RequestModal />}
      {showSuccessModal && <SuccessModal />}

      {showProcurementDetails && selectedProcurement2 && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-400 bg-opacity-50 flex items-center justify-center z-50">
          <ProcurementDetailsForSupplier
            project={selectedProcurement2}
            onClose={handleProcurementDetailsClose}
          />
        </div>
      )}

      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {saveMessage && (
          <div className="fixed top-4 right-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">
            {saveMessage}
          </div>
        )}

        {error && (
          <div className="fixed top-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
            {error}
          </div>
        )}

        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-3xl font-bold text-gray-900">
              Electronic Procurement
            </h1>
            <p className="text-gray-600 mt-1">
              Create tender opportunities and get qualified suppliers
            </p>
          </div>
          <button className="bg-yellow-400 px-6 py-2 rounded-lg font-semibold">
            + Create
          </button>
        </div>

        <div className="flex space-x-8 border-b border-gray-200 mb-6">
          {['Tender Opportunities', 'Bidding'].map((tab) => (
            <button
              key={tab}
              className={`pb-4 px-1 ${
                activeTab === tab
                  ? 'border-b-2 border-yellow-400 text-gray-900'
                  : 'text-gray-500'
              } font-medium`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className="relative mb-6">
          <input
            type="text"
            placeholder="Search"
            className="w-full px-4 py-3 pl-12 rounded-lg border border-gray-300"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search
            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400"
            size={20}
          />
        </div>

        <div className="flex space-x-4 mb-8">
          {['Top Matches', 'Newest', 'Saved', 'Approved', 'Drafts'].map(
            (filter) => (
              <button
                key={filter}
                className={`px-6 py-2 rounded-full font-medium ${
                  activeFilter === filter
                    ? 'bg-green-600 text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
                onClick={() => setActiveFilter(filter as ProcurementStatus)}
              >
                {filter}
              </button>
            )
          )}
        </div>

        <div className="flex space-x-4 mb-8 overflow-x-auto py-2">
          {[
            'Maintenance',
            'Pipeline Inspection',
            'Contract',
            'Equipment',
            'Oil and Gas',
          ].map((tag) => (
            <button
              key={tag}
              className="px-4 py-1 rounded-full bg-gray-100 text-gray-600 whitespace-nowrap hover:bg-gray-200"
            >
              {tag}
            </button>
          ))}
        </div>

        {loading ? (
          <div className="text-center py-8">Loading...</div>
        ) : error ? (
          <div className="text-center py-8 text-red-600">{error}</div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-5/6">
            {filteredProcurements.map((procurement) => (
              <div
                key={procurement.procurementProjectId}
                className="bg-white rounded-lg shadow-sm border border-gray-200 h-64 p-4"
              >
                <div className="flex justify-between items-start mb-4">
                  <span className="text-gray-500 text-sm">
                    {formatDate(procurement.lastUpdated)}
                  </span>
                  <button
                    onClick={() =>
                      handleSave(
                        procurement.procurementProjectId,
                        procurement.isSaved
                      )
                    }
                    disabled={savingIds.has(procurement.procurementProjectId)}
                    className={
                      savingIds.has(procurement.procurementProjectId)
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                    }
                  >
                    <svg
                      className={`w-6 h-6 ${procurement.isSaved ? 'text-red-500 fill-current' : 'text-gray-400'}`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      fill="none"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                      />
                    </svg>
                  </button>
                </div>
                <h3 className="font-semibold text-lg mb-2">
                  {procurement.projectTitle}
                </h3>
                <p className="text-gray-600 mb-4 line-clamp-2">
                  {procurement.projectDescription}
                </p>
                <button
                  onClick={() => {
                    if (procurement.canView) {
                      handleViewDetails(procurement);
                    } else {
                      setSelectedProcurement(procurement.procurementProjectId);
                      setShowRequestModal(true);
                    }
                  }}
                  className={`w-full py-2 rounded-lg text-center font-medium mt-12 ${
                    procurement.canView
                      ? 'bg-white-100 text-green-600 text-xl font-semibold border border-green-400 hover:bg-gray-200'
                      : procurement.accessRequested
                        ? 'bg-white-100 text-gray-600 text-xl font-semibold border border-gray-400 cursor-not-allowed'
                        : 'bg-white-100 text-gray-600 text-xl font-semibold border border-gray-400 hover:bg-gray-200'
                  }`}
                  disabled={
                    procurement.canView
                      ? false
                      : procurement.accessRequested
                        ? true
                        : false
                  }
                >
                  {procurement.canView
                    ? 'View Details'
                    : procurement.accessRequested
                      ? 'Access Already Requested'
                      : 'Request to View'}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProcurementsForSupplier;
