import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../components/BreadCrumb/BreadCrumb';
import {
  FaChevronRight,
  FaInfoCircle,
  FaMinus,
  FaPlus,
  FaRegTrashAlt,
  FaTimes,
} from 'react-icons/fa';
import AppButton from '../../../components/AppButton/AppButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import OrderCompletedModal from '../../../components/OrderCompletedModal/OrderCompletedModal';
import { getCartList, removeFromCart } from '../../../services/catalogService';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { IOrder } from '../../../types/interfaces';
import { use } from 'i18next';
import { toast } from 'react-toastify';
import FileUpload from '../../../components/FileUpload/file-upload';
import { InfoIcon } from 'lucide-react';

const CartPage = () => {
  const paths = [
    { label: 'Dashboard', path: '/dashboard' },
    { label: 'Catalogue', path: '/catalogue' },
    { label: 'Cart', path: '/cart' },
  ];
  const [showCart, setShowCart] = useState(true);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [isShippingAddressOpen, setIshippingAddressOpen] = useState(false);
  const [isPaymentMethodOpen, setIsPaymentMethodOpen] = useState(false);
  const [items, setItems] = useState<IOrder[]>([]);

  const { supplierId, setSupplierId } = useGlobalContext();
  const discount = 0;
  const shippingFee = 0;

  const toggleContactAccordion = () => {
    setIsContactOpen(!isContactOpen);
  };
  const toggleShippingAccordion = () => {
    setIshippingAddressOpen(!isShippingAddressOpen);
  };
  const togglePaymentAccordion = () => {
    setIsPaymentMethodOpen(!isPaymentMethodOpen);
  };

  //add a method to fetch the cart items
  const fetchCartItems = async () => {
    if (!supplierId) return;
    const response = await getCartList(supplierId, {
      pageNumber: 1,
      pageSize: 100,
    });
    if (response) {
      setItems(response.data);
    }
  };

  // const initialItems = [
  //   {
  //     id: 1,
  //     title: 'GAS COMPRESSOR SERIES 300',
  //     description: 'Few units left',
  //     price: 12500,
  //     quantity: 1,
  //     image: `${process.env.PUBLIC_URL}/assets/images/compressor.svg`,
  //   },
  // ];
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    shippingEmail: Yup.string().email('Invalid email').required('Required'),
    phoneNumber: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    addressLine1: Yup.string().required('Required'),
    addressLine2: Yup.string().required('Required'),
    municipality: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    suburb: Yup.string().required('Required'),
    cardNumber: Yup.string()
      .required('Card Number is required')
      .matches(/^[0-9- ]{16,19}$/, 'Invalid Card Number'),
    cvv: Yup.string()
      .required('CVV is required')
      .matches(/^[0-9]{3,4}$/, 'Invalid CVV'),
    paymentEmail: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    paymentPhoneNumber: Yup.string().required('Phone number is required'),
    cardholderName: Yup.string().required('Cardholder name is required'),
    paymentMode: Yup.string().oneOf(['online', 'offline']).required('Required'),
    paymentDescription: Yup.string().required('Please provide a description'),
    proofFile: Yup.mixed().nullable(),
  });
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      shippingEmail: '',
      phoneNumber: '',
      country: '',
      addressLine1: '',
      addressLine2: '',
      municipality: '',
      city: '',
      suburb: '',
      shippingSaveInfo: '',
      cardNumber: '',
      cvv: '',
      paymentEmail: '',
      paymentPhoneNumber: '',
      cardholderName: '',
      paymentMode: 'online',
      paymentDescription: '',
      proofFile: null,
      saveInfo: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log('my value', values);
      formik.resetForm();
      setIsOrderModalOpen(true);
    },
  });
  const handleView = () => {
    alert('View button clicked');
  };

  const handleBack = () => {
    alert('Back button clicked');
    setIsOrderModalOpen(false);
  };
  const handleIncrease = (id: string) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.orderId === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleDecrease = (id: string) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.orderId === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const handleDelete = async (id: string) => {
    const response = await removeFromCart(id);
    if (response) {
      toast.success(response?.message);
      fetchCartItems();
    } else {
      toast.error(response?.errorMessage);
    }
  };

  const handleFileSelect = (file: File | null) => {
    // console.log('file:', file)
    formik.setFieldValue('proofFile', file);
  };

  const handleUpload = () => {
    if (formik.values.proofFile) {
      // Handle file upload logic here
      // console.log('Uploading file:', formik.values.proofFile)
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  return (
    <>
      <div className="w-full py-3 pr-4">
        <div>
          <div>
            {isOrderModalOpen && (
              <OrderCompletedModal
                orderNumber="#389012"
                onView={handleView}
                onBack={handleBack}
              />
            )}
          </div>
          <div>
            <h2 className="text-2xl">Cart</h2>
          </div>
          <div className="flex items-center justify-between">
            <Breadcrumb paths={paths} />
            <p className="mr-40">
              <span>2</span> <span>Items</span>
            </p>
          </div>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className=" w-full flex gap-8 mt-10"
        >
          <div className="w-[50%] h-[calc(100vh-12rem)] overflow-y-auto">
            {showCart ? (
              <div className="w-full space-y-6">
                {items.map((item) => (
                  <div
                    key={item.orderId}
                    className="flex justify-between border rounded-2xl border-gray-300 py-6 px-4"
                  >
                    <div>
                      <img
                        src={
                          item.image ||
                          `${process.env.PUBLIC_URL}/assets/images/compressor.svg`
                        }
                        alt={item.descriptions || 'product'}
                        className="w-full h-36 object-cover rounded-t-lg"
                      />
                    </div>
                    <div>
                      <p className="text-lg text-gray-600 font-semibold">
                        {item.descriptions}
                      </p>
                      <p className="text-gray-700">{item.descriptions}</p>
                    </div>
                    <div className="flex gap-14">
                      <div className="flex flex-col space-y-14">
                        <div>
                          <p className="font-bold text-xl">
                            ${item.cost?.toLocaleString()}
                          </p>
                        </div>
                        <div className="flex gap-4 items-center">
                          <button
                            onClick={() => handleDecrease(item.orderId)}
                            className="text-xl text-gray-600"
                          >
                            <FaMinus />
                          </button>
                          <p className="text-xl">{item.quantity}</p>
                          <button
                            onClick={() => handleIncrease(item.orderId)}
                            className="text-xl text-gray-600"
                          >
                            <FaPlus />
                          </button>
                        </div>
                      </div>
                      <div>
                        <button
                          onClick={() => handleDelete(item.itemId)}
                          className="text-red-500 mt-10"
                        >
                          <FaRegTrashAlt size={20} />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="w-full">
                <div className="">
                  {/* Accordion */}
                  <div
                    className={`px-8 py-6 border border-gray-300 rounded-lg mb-4 ${isContactOpen ? 'bg-gray-50' : 'shadow-sm bg-white'}`}
                  >
                    <div
                      className="flex py-4 justify-between items-center cursor-pointer"
                      onClick={toggleContactAccordion}
                    >
                      <p className="text-xl font-semibold">
                        {'1. Contact Information'}
                      </p>
                      <span className="flex items-center space-x-2 text-red-500 font-medium">
                        {isContactOpen ? null : (
                          <>
                            <span>Change</span>
                          </>
                        )}
                        <span
                          className={`transform transition-transform ${
                            isContactOpen ? '-rotate-90' : 'rotate-0'
                          }`}
                        >
                          <FaChevronRight />
                        </span>
                      </span>
                    </div>
                    {isContactOpen && (
                      <div className=" bg-gray-50">
                        <div className="mt-6 mb-6">
                          <label className="block mb-2 font-medium">
                            Email
                            <span className="text-red-700">*</span>
                          </label>
                          <input
                            type="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Email"
                            className="block w-full border border-gray-400 rounded-lg p-2 outline-none text-base"
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                        <div className="flex justify-end mt-4 mb-4">
                          <AppButton
                            title={'Continue'}
                            onClick={toggleContactAccordion}
                            className="py-3 px-12 bg-gray-100 border border-gray-50 rounded-xl"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`px-8 py-6 border border-gray-300 rounded-lg mb-4 ${isShippingAddressOpen ? 'bg-gray-50' : 'shadow-sm bg-white'}`}
                  >
                    <div
                      className="flex py-4 justify-between items-center cursor-pointer"
                      onClick={toggleShippingAccordion}
                    >
                      <p className="text-xl font-semibold">
                        {'2. Shipping Address'}
                      </p>
                      <span className="flex items-center space-x-2 text-red-500 font-medium">
                        {isShippingAddressOpen ? null : (
                          <>
                            <span>Change</span>
                          </>
                        )}
                        <span
                          className={`transform transition-transform ${
                            isShippingAddressOpen ? '-rotate-90' : 'rotate-0'
                          }`}
                        >
                          <FaChevronRight />
                        </span>
                      </span>
                    </div>
                    {isShippingAddressOpen && (
                      <div className=" bg-gray-50">
                        <div className="grid grid-cols-2 pt-4 gap-3">
                          <div className="">
                            <label className="block mb-2 font-medium">
                              First Name
                              <span className="text-red-700">*</span>
                            </label>
                            <input
                              type="text"
                              name="firstName"
                              value={formik.values.firstName}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="First Name"
                              className="block w-full border border-gray-400 rounded-lg p-2 outline-none text-base"
                            />
                            {formik.touched.firstName &&
                            formik.errors.firstName ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.firstName}
                              </div>
                            ) : null}
                          </div>
                          <div className="">
                            <label className="block mb-2 font-medium">
                              Last Name
                              <span className="text-red-700">*</span>
                            </label>
                            <input
                              type="text"
                              name="lastName"
                              value={formik.values.lastName}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Last Name"
                              className="block w-full border border-gray-400 rounded-lg p-2 outline-none text-base"
                            />
                            {formik.touched.lastName &&
                            formik.errors.lastName ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.lastName}
                              </div>
                            ) : null}
                          </div>
                          <div className="">
                            <label className="block mb-2 font-medium">
                              Email
                              <span className="text-red-700">*</span>
                            </label>
                            <input
                              type="email"
                              name="shippingEmail"
                              value={formik.values.shippingEmail}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Email@yahoo.com"
                              className="block w-full border border-gray-400 rounded-lg p-2 outline-none text-base"
                            />
                            {formik.touched.shippingEmail &&
                            formik.errors.shippingEmail ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.shippingEmail}
                              </div>
                            ) : null}
                          </div>
                          <div className="">
                            <label className="block mb-2 font-medium">
                              Phone Number
                              <span className="text-red-700">*</span>
                            </label>

                            <PhoneInput
                              country={'us'}
                              value={formik.values.country}
                              onChange={(value) =>
                                formik.setFieldValue('phoneNumber', value)
                              }
                              onBlur={() =>
                                formik.setFieldTouched('phoneNumber', true)
                              }
                              placeholder="Enter Phone number"
                              inputStyle={{
                                width: '100%',
                                border: '1px solid #cbd5e1',
                                borderRadius: '0.5rem',
                                padding: '0 5rem',
                                outline: 'none',
                                fontSize: '1rem',
                                height: '30px',
                              }}
                              //
                            />
                            {formik.touched.phoneNumber &&
                            formik.errors.phoneNumber ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.phoneNumber}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="grid grid-cols-1 mt-2 gap-2">
                          <div>
                            <label className="block mb-2 font-medium">
                              Country <span className="text-red-700">*</span>
                            </label>
                            <select
                              name="country"
                              value={formik.values.country}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="w-full border border-gray-400 rounded-lg px-2 py-3 outline-none"
                            >
                              <option value="">Select</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Germany">Germany</option>
                            </select>
                            {formik.touched.country && formik.errors.country ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.country}
                              </div>
                            ) : null}
                          </div>
                          <div className="">
                            <label className="block mb-2 font-medium">
                              Office Address Line 1
                              <span className="text-red-700">*</span>
                            </label>
                            <input
                              type="text"
                              name="addressLine1"
                              value={formik.values.addressLine1}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Enter office address"
                              className="block w-full border border-gray-400 rounded-lg p-2 outline-none text-base"
                            />
                            {formik.touched.addressLine1 &&
                            formik.errors.addressLine1 ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.addressLine1}
                              </div>
                            ) : null}
                          </div>
                          <div className="">
                            <label className="block mb-2 font-medium">
                              Home Address Line 2
                              <span className="text-red-700">*</span>
                            </label>
                            <input
                              type="text"
                              name="addressLine2"
                              value={formik.values.addressLine2}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Enter office address"
                              className="block w-full border border-gray-400 rounded-lg p-2 outline-none text-base"
                            />
                            {formik.touched.addressLine2 &&
                            formik.errors.addressLine2 ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.addressLine2}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <label className="block mb-2 font-medium">
                              Municipality/Region{' '}
                              <span className="text-red-700">*</span>
                            </label>
                            <select
                              name="municipality"
                              value={formik.values.municipality}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="w-full border border-gray-400 rounded-lg px-2 py-3 outline-none"
                            >
                              <option value="">Select</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Germany">Germany</option>
                            </select>
                            {formik.touched.municipality &&
                            formik.errors.municipality ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.municipality}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <label className="block mb-2 font-medium">
                              Post Administrative/City{' '}
                              <span className="text-red-700">*</span>
                            </label>
                            <select
                              name="city"
                              value={formik.values.city}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="w-full border border-gray-400 rounded-lg px-2 py-3 outline-none"
                            >
                              <option value="">Select</option>
                              <option value="lagos">Lagos</option>
                              <option value="Delta">Delta</option>
                            </select>
                            {formik.touched.city && formik.errors.city ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.city}
                              </div>
                            ) : null}
                          </div>
                          <div className="">
                            <label className="block mb-2 font-medium">
                              Suburb or Town
                              <span className="text-red-700">*</span>
                            </label>
                            <input
                              type="text"
                              name="suburb"
                              value={formik.values.suburb}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Enter region"
                              className="block w-full border border-gray-400 rounded-lg p-2 outline-none text-base"
                            />
                            {formik.touched.suburb && formik.errors.suburb ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.suburb}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="mb-4 my-4">
                          <label className="flex items-center space-x-2 ">
                            <input
                              type="checkbox"
                              name="shippingSaveInfo"
                              onChange={formik.handleChange}
                              className="form-checkbox w-[20px] text-primary rounded border-gray-400"
                            />
                            <span>
                              Save this information for a future fast checkout
                            </span>
                          </label>
                        </div>
                        <AppButton
                          title={'Save'}
                          onClick={function (): void {
                            throw new Error('Function not implemented.');
                          }}
                          className="bg-primary py-2 rounded-xl text-lg font-semibold w-full"
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={`px-8 py-6 border border-gray-300 rounded-lg mb-4 ${isPaymentMethodOpen ? 'bg-gray-50' : 'shadow-sm bg-white'}`}
                  >
                    <div
                      className="flex py-4 justify-between items-center cursor-pointer"
                      onClick={togglePaymentAccordion}
                    >
                      <p className="text-xl flex w-full justify-between font-semibold">
                        {'3. Payment Method'}
                        {isPaymentMethodOpen && (
                          <span className="flex gap-2 text-gray-500">
                            Payment Tip
                            <div className="relative group">
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/info.svg`}
                                alt={'info'}
                                className={`  object-cover rounded-t-lg`}
                              />
                              <div className="absolute top-full right-0 mt-3 mb-3 w-40 bg-black text-white text-xs rounded-lg p-3 hidden group-hover:block">
                                Apple Pay only supports Safari web browser
                              </div>
                            </div>
                          </span>
                        )}
                      </p>

                      <span className="flex items-center space-x-2 text-red-500 font-medium">
                        {isPaymentMethodOpen ? null : (
                          <>
                            <span>Change</span>
                          </>
                        )}
                        <span
                          className={`transform transition-transform ${
                            isPaymentMethodOpen ? '-rotate-90 ml-6' : 'rotate-0'
                          }`}
                        >
                          <FaChevronRight />
                        </span>
                      </span>
                    </div>
                    {isPaymentMethodOpen && (
                      <div>
                        <div className="flex gap-8 mb-6">
                          <label className="flex items-center space-x-2 cursor-pointer">
                            <input
                              type="radio"
                              name="paymentMode"
                              value="online"
                              checked={formik.values.paymentMode === 'online'}
                              onChange={() =>
                                formik.setFieldValue('paymentMode', 'online')
                              }
                              className="form-radio h-6 w-6 text-primary border-gray-300 focus:ring-primary"
                            />
                            <span className="text-base font-medium text-gray-900">
                              Online Mode
                            </span>
                            <InfoIcon className="h-4 w-4 text-gray-500" />
                          </label>
                          <label className="flex items-center space-x-2 cursor-pointer">
                            <input
                              type="radio"
                              name="paymentMode"
                              value="offline"
                              checked={formik.values.paymentMode === 'offline'}
                              onChange={() =>
                                formik.setFieldValue('paymentMode', 'offline')
                              }
                              className="form-radio h-6 w-6 text-primary border-gray-300 focus:ring-primary"
                            />
                            <span className="text-base font-medium text-gray-900">
                              Offline Mode
                            </span>
                            <InfoIcon className="h-4 w-4 text-gray-500" />
                          </label>
                        </div>

                        {formik.values.paymentMode === 'offline' && (
                          <>
                            <div className="space-y-2 mb-4">
                              <label
                                htmlFor="paymentDescription"
                                className="block font-medium"
                              >
                                Description or reason for payment
                              </label>
                              <textarea
                                id="paymentDescription"
                                className="w-full p-2 border rounded-lg text-base outline-none"
                                rows={4}
                                placeholder="Provide brief description for the payment"
                                {...formik.getFieldProps('paymentDescription')}
                              ></textarea>
                              {formik.touched.paymentDescription &&
                                formik.errors.paymentDescription && (
                                  <p className="text-sm text-red-500">
                                    {formik.errors.paymentDescription}
                                  </p>
                                )}
                            </div>

                            <div className="space-y-2">
                              <label className="block font-medium">
                                Upload proof of payment
                              </label>
                              <FileUpload
                                onFileSelect={handleFileSelect}
                                onUpload={handleUpload}
                                error={
                                  formik.touched.proofFile &&
                                  formik.errors.proofFile
                                    ? formik.errors.proofFile
                                    : undefined
                                }
                              />
                            </div>
                          </>
                        )}

                        {formik.values.paymentMode === 'online' && (
                          <div className=" bg-gray-50">
                            <p className="mb-4">Credit Card</p>
                            <div className="flex w-full justify-between mb-6">
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/flutterwave.svg`}
                                alt={'flutterwave'}
                                className={`w-[40%]  object-cover rounded-t-lg`}
                              />
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/GooglePay.svg`}
                                alt={'GooglePay'}
                                className={`w-[38%]  object-cover rounded-t-lg`}
                              />
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/applePay.svg`}
                                alt={'applePay'}
                                className={`w-[22%]  object-cover rounded-t-lg`}
                              />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                              {/* Card Number */}
                              <div>
                                <label className="block mb-2 font-medium">
                                  Card Number{' '}
                                  <span className="text-red-700">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="cardNumber"
                                  value={formik.values.cardNumber}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  placeholder="1234-5678-9001-2345"
                                  className="w-full border border-gray-400 text-base rounded-lg px-3 py-1 outline-none"
                                />
                                {formik.touched.cardNumber &&
                                  formik.errors.cardNumber && (
                                    <div className="text-red-500 text-sm">
                                      {formik.errors.cardNumber}
                                    </div>
                                  )}
                              </div>

                              {/* CVV */}
                              <div>
                                <label className="block mb-2 font-medium">
                                  CVV <span className="text-red-700">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="cvv"
                                  value={formik.values.cvv}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  placeholder="Enter CVV"
                                  className="w-full border border-gray-400 text-base rounded-lg px-3 py-1 outline-none"
                                />
                                {formik.touched.cvv && formik.errors.cvv && (
                                  <div className="text-red-500 text-sm">
                                    {formik.errors.cvv}
                                  </div>
                                )}
                              </div>

                              {/* Email */}
                              <div>
                                <label className="block mb-2 font-medium">
                                  Email <span className="text-red-700">*</span>
                                </label>
                                <input
                                  type="email"
                                  name="paymentEmail"
                                  value={formik.values.paymentEmail}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  placeholder="yourname@email.com"
                                  className="w-full border border-gray-400 text-base rounded-lg px-3 py-1 outline-none"
                                />
                                {formik.touched.paymentEmail &&
                                  formik.errors.paymentEmail && (
                                    <div className="text-red-500 text-sm">
                                      {formik.errors.paymentEmail}
                                    </div>
                                  )}
                              </div>

                              {/* Phone Number */}
                              <div>
                                <label className="block mb-2 font-medium">
                                  Phone number{' '}
                                  <span className="text-red-700">*</span>
                                </label>
                                <PhoneInput
                                  country="us"
                                  value={formik.values.paymentPhoneNumber}
                                  onChange={(value) =>
                                    formik.setFieldValue(
                                      'paymentPhoneNumber',
                                      value
                                    )
                                  }
                                  onBlur={() =>
                                    formik.setFieldTouched(
                                      'paymentPhoneNumber',
                                      true
                                    )
                                  }
                                  placeholder="Enter Phone number"
                                  inputStyle={{
                                    width: '100%',
                                    border: '1px solid #cbd5e1',
                                    borderRadius: '0.5rem',
                                    padding: '0 5rem',
                                    fontSize: '1.2rem',
                                    height: '26px',
                                  }}
                                />
                                {formik.touched.paymentPhoneNumber &&
                                  formik.errors.paymentPhoneNumber && (
                                    <div className="text-red-500 text-sm">
                                      {formik.errors.paymentPhoneNumber}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div>
                              <label className="block mb-2 mt-4 font-medium">
                                Cardholder Name{' '}
                                <span className="text-red-700">*</span>
                              </label>
                              <input
                                type="text"
                                name="cardholderName"
                                value={formik.values.cardholderName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="Cardholder Name"
                                className="w-full border border-gray-400 text-base rounded-lg px-3 py-1 outline-none"
                              />
                              {formik.touched.cardholderName &&
                                formik.errors.cardholderName && (
                                  <div className="text-red-500 text-sm">
                                    {formik.errors.cardholderName}
                                  </div>
                                )}
                            </div>

                            {/* Save Info */}
                            <div className="my-4">
                              <label className="flex items-center space-x-2 ">
                                <input
                                  type="checkbox"
                                  name="saveInfo"
                                  checked={formik.values.saveInfo}
                                  onChange={formik.handleChange}
                                  className="form-checkbox text-primary rounded border-gray-400 w-[20px]"
                                />
                                <span>
                                  Save this information for a future fast
                                  checkout
                                </span>
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Order Summary */}
          <div className="w-[40%] bg-white shadow-md rounded-lg p-4">
            <div className="flex justify-between pr-4 mt-2">
              <div>
                <h2 className="text-3xl">Order Summary</h2>
              </div>
              <div>
                <FaTimes size={'16px'} />
              </div>
            </div>
            <div className="mb-20">
              {items.map((item) => (
                <div key={item.orderId} className="flex justify-between mt-8">
                  <div>
                    <img
                      src={
                        item.image ||
                        `${process.env.PUBLIC_URL}/assets/images/compressor.svg`
                      }
                      alt={item.descriptions || 'product'}
                      className={`w-full h-36 object-cover rounded-t-lg`}
                    />
                  </div>
                  <div className="flex flex-col gap-4 mt-4">
                    <p className="text-lg text-gray-600 font-semibold">
                      {item.descriptions}
                    </p>
                    <p className="text-lg font-semibold">
                      ${item.cost?.toLocaleString()}
                    </p>
                  </div>
                  <div>
                    <p className="text-gray-600 mt-4">{item.quantity}x</p>
                  </div>
                </div>
              ))}
            </div>
            <hr className="text-gray-400" />
            <div className="flex justify-between mt-7 mb-10">
              <div className="space-y-3 text-xl">
                <p>Subtotal</p>
                <p>Shipping</p>
                <p>Discount</p>
                <p>Tax</p>
              </div>
              <div className="space-y-3 text-xl">
                <p className="font-bold">
                  $
                  {items
                    .reduce(
                      (acc, item) => acc + (item.cost || 0) * item.quantity,
                      0
                    )
                    .toLocaleString()}
                </p>
                <p className="text-green-500 font-semibold">
                  {shippingFee <= 0 ? 'FREE' : shippingFee}
                </p>
                <p className="">{discount <= 0 ? '-' : discount}</p>
                <p>
                  {items.reduce(
                    (acc: number, item: IOrder) =>
                      acc + ((item.vat || 0) / 100) * item.cost * item.quantity,
                    0
                  ) <= 0
                    ? '-'
                    : `$${items
                        .reduce(
                          (acc: number, item: IOrder) =>
                            acc +
                            ((item.vat || 0) / 100) * item.cost * item.quantity,
                          0
                        )
                        .toLocaleString()}`}
                </p>
              </div>
            </div>
            <hr className="text-gray-400" />
            <div>
              <div className="flex justify-between mt-5 mb-8 items-center">
                <p className="text-xl">Total</p>
                <p className="text-2xl font-semibold">
                  $
                  {(
                    items.reduce(
                      (acc, item) => acc + (item.cost || 0) * item.quantity,
                      0
                    ) -
                    discount +
                    items.reduce(
                      (acc: number, item: IOrder) =>
                        acc +
                        ((item.vat || 0) / 100) * item.cost * item.quantity,
                      0
                    )
                  ).toLocaleString()}
                </p>
              </div>
              <div>
                {showCart ? (
                  <AppButton
                    title={'Proceed'}
                    className="bg-primary py-2 rounded-xl text-lg font-semibold w-full"
                    onClick={() => setShowCart(false)}
                  />
                ) : (
                  <button
                    type="submit"
                    className={`bg-primary py-2 rounded-xl text-lg font-semibold w-full ${
                      !formik.isValid || formik.isSubmitting
                        ? 'opacity-50 cursor-not-allowed'
                        : 'opacity-100'
                    }`}
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    Checkout
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CartPage;
