import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../pages/Auth/Login/Login';
import PageNotFound from '../components/WildCard/PageNotFound';
import SignUp from '../pages/Auth/SignUp/SignUp';
import Otp from '../components/Otp/Otp';
import SuccessCard from '../components/SuccessCard/SuccessCard';
import ForgetPassword from '../pages/Auth/Forget-Password/ForgetPassword';
import PasswordReset from '../pages/Auth/Forget-Password/PassswordReset';
import ChooseDemo from '../pages/ChooseDemo/ChooseDemo';

import SupplierManagement from '../pages/SupplierManagement/SupplierManagement';
import SupplierApplication from '../pages/SupplierManagement/SupplierApplication/SupplierApplication';
import ProtectedRoute from '../guards/ProtectedRoute';
import WorkflowConfiguration from '../pages/Settings/WorkFlowConfiguration/WorkflowList';
import CommandConfig from '../pages/Settings/WorkFlowConfiguration/CommandConfig/CommandConfig';
import StepConfig from '../pages/Settings/WorkFlowConfiguration/StepConfig/AddEditStepConfig';
import ProtectedLayout from '../layouts/ProtectedLayout';
import WorkFlowSteps from '../pages/Settings/WorkFlowConfiguration/StepConfig/StepList';
import ApplicationsOnMyDesk from '../pages/ApplicationDesk/ApplicationDesk';
import UserManagement from '../pages/Settings/UserManagement/UserManagement';
import Operator from '../pages/Settings/UserManagement/Operator';
import ProcurementHomepage from '../pages/ProcurementProject/ProjectProcurementHomepage';
import CreateProcurementForm from '../pages/ProcurementProject/CreateProcurementForm';
import ParameterConfig from '../pages/Settings/WorkFlowConfiguration/ParameterConfig/ParameterConfig';
import RolePermission from '../pages/Settings/RoleManagement/RolePermission';
import NavigationMenu from '../pages/Settings/NavigationMenu/NavigationMenu';
import SupplierRequests from '../pages/SupplierRequests/SupplierRequests';
import AccessibleModules from '../pages/AccessibleModules/ApplicationModules';
import OperatorModule from '../pages/OperatorModule/OperatorModule';
import OperatorDashboard from '../pages/OperatorModule/OperatorDashboard';
import Dashboard from '../pages/Dashboard/Dashboard';
import GoodsServiceCatalogue from '../pages/GoodsServiceCatalogue/GoodsServiceCatalogue';
import ProductDetails from '../pages/GoodsServiceCatalogue/components/ProductDetails';
import CartPage from '../pages/GoodsServiceCatalogue/components/CartPage';
import SearchResults from '../pages/GoodsServiceCatalogue/SearchResults/SearchResults';
import Profile from '../pages/Settings/Profile/Profile';
import OrderDetailsPage from '../pages/GoodsServiceCatalogue/components/OrdersPage/OrderDetailsPage';
import ProcurementRequests from '../pages/ProcurementProject/ProcurementRequests';
import ProcurementsForSupplier from '../pages/ProcurementProject/ProcurementsForSupplier';
import SupplierRequestsView from '../pages/ProcurementProject/SupplierRequestsView';
import ExpressionOfInterestBid from '../pages/ProcurementProject/ExpressionOfInterestBid';
import ProcurementDashboard from '../pages/ProcurementProject/ProcurementDashboard';
import LookupComponent from '../pages/Settings/Lookup/Lookup';

const RouterConfig: React.FC = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/success-card" element={<SuccessCard title="Success" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/otp" element={<Otp />} />
      <Route path="reset-password" element={<PasswordReset />} />
      <Route path="/forgot-password" element={<ForgetPassword />} />
      <Route path="/choose-demo" element={<ChooseDemo />} />
      <Route path="/application-module" element={<AccessibleModules />} />
      <Route path="/catalogue/search" element={<SearchResults />} />

      {/* Protected Routes with Shared Layout */}
      <Route
        element={
          <ProtectedRoute>
            <ProtectedLayout />
          </ProtectedRoute>
        }
      >
        <Route element={<ProtectedLayout />}></Route>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/supplier-registration" element={<SupplierManagement />} />
        <Route path="/operator-module" element={<OperatorModule />} />
        <Route path="/supplier-application" element={<SupplierApplication />} />
        <Route path="/settings/workflow" element={<WorkflowConfiguration />} />
        <Route
          path="/settings/workflow/parameter"
          element={<ParameterConfig />}
        />
        <Route path="/catalogue" element={<GoodsServiceCatalogue />} />
        <Route path="/settings/workflow/command" element={<CommandConfig />} />
        <Route path="/settings/workflow/steps" element={<WorkFlowSteps />} />
        <Route path="/settings/workflow/step" element={<StepConfig />} />
        <Route path="/settings/profile" element={<Profile />} />
        <Route
          path="/catalogue/orderdetailspage"
          element={<OrderDetailsPage />}
        />

        <Route
          path="/requests/supplier-requests"
          element={<SupplierRequests />}
        />
        <Route path="/operator-dashboard" element={<OperatorDashboard />} />
        <Route path="/application-desk" element={<ApplicationsOnMyDesk />} />
        <Route path="/settings/roles" element={<RolePermission />} />
        <Route path="/settings/menu" element={<NavigationMenu />} />
        <Route path="/settings/lookup" element={<LookupComponent />} />
        <Route
          path="/settings/users"
          element={<UserManagement isEditable={false} />}
        />
        <Route path="/goods/detail" element={<ProductDetails />} />
        <Route path="/services/detail" element={<ProductDetails />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/operator" element={<Operator isEditable={false} />} />
        <Route path="/procurement" element={<ProcurementHomepage />} />
        <Route path="/appdesk" element={<ApplicationsOnMyDesk />} />
        <Route
          path="/procurement/create"
          element={<CreateProcurementForm onClose={() => {}} />}
        />
        <Route
          path="/procurement/edit/:id"
          element={<CreateProcurementForm onClose={() => {}} />}
        />
        <Route
          path="/requests/procurement-requests"
          element={<ProcurementRequests />}
        />
        <Route
          path="/supplierProcurements"
          element={<ProcurementsForSupplier />}
        />
        <Route
          path="/procurement/supplierRequests/:procurementId"
          element={<SupplierRequestsView onClose={() => {}} />}
        />
        <Route
          path="/supplierProcurements/bid"
          element={<ExpressionOfInterestBid />}
        />
        <Route
          path="/supplierProcurement/procurement-dashboard"
          element={<ProcurementDashboard />}
        />
        {/* Wildcard Route */}
        <Route path="*" element={<PageNotFound />} />
      </Route>

      {/* Wildcard Route */}
      {/* <Route path="*" element={<PageNotFound />} /> */}
    </Routes>
  );
};

export default RouterConfig;
