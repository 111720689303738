import React from 'react';

interface StarRatingProps {
  stars: number; // Number of stars (e.g., 4 out of 5)
  maxStars?: number; // Total stars (default is 5)
}

const StarRatings: React.FC<StarRatingProps> = ({ stars, maxStars = 5 }) => {
  return (
    <div className="flex items-center">
      {Array.from({ length: maxStars }, (_, i) => (
        <svg
          key={i}
          xmlns="http://www.w3.org/2000/svg"
          fill={i < stars ? 'gold' : 'none'}
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={`w-5 h-5 ${i < stars ? 'text-yellow-400' : 'text-gray-300'}`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 17.25l6.16 3.557-1.174-7.235 5.014-4.88-7.268-.563L12 2.75 9.268 8.129l-7.268.563 5.014 4.88-1.174 7.235L12 17.25z"
          />
        </svg>
      ))}
    </div>
  );
};

export default StarRatings;
