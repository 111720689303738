// Module.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import ModuleCard from '../../../components/moduleCard/ModuleCard';
import './Module.css';
import Navbar from '../../../layouts/NavBar/Navbar';

// Define the props for the reusable module component
interface ModuleProps {
  title: string;
  desc: string;
  modulesData: Array<{
    id: number;
    imageSrc: string;
    moduleTitle: string;
    moduleType: string;
    link: string;
    external: boolean;
  }>;
}

const Module: React.FC<ModuleProps> = ({ title, desc, modulesData }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Navbar />
      <div className="flex flex-wrap justify-start gap-6 m-6 mx-0 sm:mx-0 md:mx-60 lg:mx-60">
        <div className="w-full flex flex-col gap-3">
          <p className="textStyle">{t(title)}</p>
          <p className="textStyle2">{t(desc)}</p>
        </div>
        {modulesData.map((module) => (
          <ModuleCard
            key={module.id}
            imageSrc={module.imageSrc}
            moduleTitle={t(module.moduleTitle)}
            moduleType={t(module.moduleType)}
            link={module.link}
            external={module.external}
          />
        ))}
      </div>
    </div>
  );
};

export default Module;
