import React from 'react';
import { FaFilter } from 'react-icons/fa';
import { FilterButtonProps } from '../../types/interfaces';
const FilterButton: React.FC<FilterButtonProps> = ({
  label = 'Filters',
  onClick,
  className = '',
}) => {
  return (
    <div
      className={`flex items-center justify-center space-x-2 border rounded-2xl border-gray-400 px-4 py-2 font-bold cursor-pointer ${className}`}
      onClick={onClick}
    >
      <FaFilter />
      <span className="text-gray-700 font-semibold">{label}</span>
    </div>
  );
};

export default FilterButton;
