import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppButton from './AppButton/AppButton';

interface ConfirmationProps {
  title: string;
  appBtnConfirm?: string;
  appBtnCancel?: string;
  desc: string;
  promptText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationProps> = ({
  title,
  appBtnConfirm = 'Confirm',
  appBtnCancel = 'Cancel',
  desc,
  promptText = 'Do you want to continue?',
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-12 rounded-2xl shadow-lg max-w-xl w-full flex flex-col justify-center items-center">
            <h1 className="text-3xl flex justify-center items-center font-bold mb-4">
              {title}
            </h1>
            <p className="text-xl text-gray-600 mb-6 flex justify-center w-full text-center">
              {desc}
            </p>
            <p className="text-xl text-gray-600 mb-6 flex justify-center w-full">
              {promptText}
            </p>
            <div className="flex gap-4">
              <AppButton
                title={appBtnCancel}
                onClick={() => {
                  setIsOpen(false);
                  onCancel();
                }}
                className="bg-gray-300 text-gray-700 px-12 h-16 py-2 rounded-lg font-semibold"
              />
              <AppButton
                title={appBtnConfirm}
                onClick={() => {
                  setIsOpen(false);
                  onConfirm();
                }}
                className="bg-green-500 text-white px-12 h-16 py-2 rounded-lg font-semibold"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationModal;
