import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTable, { Column } from '../../../components/CustomTable';

const InitiatedTaskTabContent: React.FC = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initiatedTasks, setInitiatedTasks] = useState<any>([]);

  // const getInitiatedTaskData = async () => {
  //   const response = await getAssignedTasks();
  //   if (!response.isSuccess) {
  //     return;
  //   }
  //   setInitiatedTasks(response.data);
  // };

  // useEffect(() => {
  //   getInitiatedTaskData();
  // }, []);

  // Handlers for actions
  const handleView = (item: any) => {
    // setSelectedPersonnel(item);
    setIsModalOpen(true); // Open the modal to view the item
  };

  const openModal = () => {
    setIsModalOpen(true);
    // setSelectedPersonnel(null);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // setSelectedPersonnel(null);
  };

  // Define column structure
  const columns: Column[] = [
    {
      title: t('description', 'Description'),
      dataKey: 'description',
      type: 'string',
    },
    {
      title: t('currentLevel', 'Current Level'),
      dataKey: 'currentLevel',
      type: 'string',
    },
    {
      title: t('preparedBy', 'Prepared By'),
      dataKey: 'preparedBy',
      type: 'string',
    },
    {
      title: t('lastAssignedOn', 'Last Assigned On'),
      dataKey: 'lastAssignedOn',
      type: 'string',
    },
    { title: t('status', 'Status'), dataKey: 'status', type: 'string' },
    {
      title: t('actions', 'Actions'),
      dataKey: 'actions',
      type: 'action',
      actions: [
        {
          label: t('view', 'View'),
          className: 'btn-view',
          action: (item: any) => handleView(item),
          status: true,
        },
      ],
    },
  ];

  return (
    <div>
      <CustomTable
        data={initiatedTasks}
        columns={columns}
        showPagination={true}
        showSearch={true}
        onAddButtonClick={openModal} // Hook for the 'Add' button
      />
    </div>
  );
};

export default InitiatedTaskTabContent;
