import React from 'react';
import { ChipBtn } from '../../types/interfaces';

const Chips: React.FC<ChipBtn> = ({
  title,
  bgColor = 'bg-blue-100',
  borderColor = 'border-gray-400',
  borderRadius = 'rounded-full',
  padding = 'py-2 px-6',
}) => {
  return (
    <div
      className={`${bgColor} border ${borderColor} ${borderRadius} ${padding} `}
    >
      {title}
    </div>
  );
};

export default Chips;
