import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../axiosInstance';
import { useLocation } from 'react-router-dom';

interface ProcurementProject {
  procurementProjectId: string;
  projectTitle: string;
  isBiddingProject: boolean;
  supplierTypeToBid: string;
  status: number;
  biddingStartDate: string;
  biddingEndDate: string;
  canBid: boolean;
}

interface ApiResponse {
  isSuccess: boolean;
  data: ProcurementProject;
  errorMessage: string | null;
}

const baseUrl = process.env.REACT_APP_BASE_URL;

const ProcurementDashboard: React.FC = () => {
  const [project, setProject] = useState<ProcurementProject | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const procurementId = location.state?.ProcurementProjectId;

  useEffect(() => {
    const fetchProcurementProject = async () => {
      try {
        const response = await axiosInstance.get<ApiResponse>(
          `${baseUrl}/procurementProjects/procurementproject/${procurementId}`
        );

        if (response.data.isSuccess) {
          setProject(response.data.data);
        } else {
          setError(response.data.errorMessage || 'Failed to fetch project');
        }
      } catch (err) {
        setError('Error fetching procurement project');
      } finally {
        setLoading(false);
      }
    };

    fetchProcurementProject();
  }, []);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const getStatusLabel = (status: number) => {
    switch (status) {
      case 1:
        return 'Active';
      case 2:
        return 'Completed';
      case 3:
        return 'Cancelled';
      default:
        return 'Unknown';
    }
  };

  if (loading) {
    return (
      <div className="flex h-screen bg-gray-100 items-center justify-center">
        <div className="text-xl">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-screen bg-gray-100 items-center justify-center">
        <div className="text-xl text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <main className="flex-1 p-6">
        <div className="bg-white shadow-md rounded-md p-6">
          <header className="flex items-center justify-between">
            <h1 className="text-xl font-bold">Electronic Procurement</h1>
            <button className="text-yellow-500 hover:underline">
              View Details
            </button>
          </header>
          <div className="mt-4">
            <div className="grid grid-cols-3 gap-4">
              <div>
                <div className="text-sm text-gray-500">Contract Name</div>
                <div className="text-lg font-bold">
                  {project?.projectTitle || 'N/A'}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-500">Type</div>
                <div className="text-lg font-bold">
                  {project?.supplierTypeToBid || 'N/A'}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-500">Status</div>
                <div className="text-lg font-bold">
                  {getStatusLabel(project?.status || 0)}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <h2 className="text-lg font-semibold">Bidding Information</h2>
            <div className="mt-4 border-l-2 border-gray-200">
              {/* Bidding Start */}
              <div className="pl-4 relative">
                <div className="absolute -left-2 top-0 bg-green-500 rounded-full h-4 w-4"></div>
                <div className="flex justify-between">
                  <div className="font-medium">Bidding Start Date</div>
                  <div className="text-sm text-gray-500">
                    {project?.biddingStartDate
                      ? formatDate(project.biddingStartDate)
                      : 'N/A'}
                  </div>
                </div>
              </div>
              {/* Bidding End */}
              <div className="pl-4 relative mt-4">
                <div className="absolute -left-2 top-0 bg-blue-500 rounded-full h-4 w-4"></div>
                <div className="flex justify-between">
                  <div className="font-medium">Bidding End Date</div>
                  <div className="text-sm text-gray-500">
                    {project?.biddingEndDate
                      ? formatDate(project.biddingEndDate)
                      : 'N/A'}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="flex mt-6 justify-end space-x-4">
            <button
              className="bg-gray-200 px-4 py-2 rounded-md hover:bg-gray-300 w-48"
              disabled={!project?.canBid}
            >
              Withdraw?
            </button>
            <button
              className="bg-primary px-4 py-2 text-black rounded-md hover:bg-yellow-600 w-48"
              disabled={!project?.canBid}
            >
              Continue Application
            </button>
          </footer>
        </div>
      </main>
    </div>
  );
};

export default ProcurementDashboard;
