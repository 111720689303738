import React from 'react';
import FileUpload from '../../../components/FileUpload/file-upload';
import { ProfileProps } from '../../../types/interfaces';

const Certifications: React.FC<ProfileProps> = ({ company }) => {
  const files = [
    { name: 'CR Certificate.png' },
    { name: 'International Passport.pdf' },
    { name: 'Tax Certificate.jpg' },
  ];
  return (
    <div className="border border-gray-300 bg-white shadow-sm rounded-lg px-6 pt-4 pb-8">
      <div>
        <h2 className="text-2xl font-medium mb-2">Certifications</h2>
      </div>
      <hr className="mb-3" />
      <div className="grid grid-cols-2 gap-6 px-14">
        {files.map((file, index) => (
          <FileUpload
            key={index}
            onFileSelect={(file) => alert('select')}
            onUpload={() => alert('upload')}
            fileName={file.name}
            isViewOnly={true}
          />
        ))}
      </div>
    </div>
  );
};

export default Certifications;
