import React, { useState } from 'react';
import { ArrowLeft, FileText } from 'lucide-react';
import CreateProcurementForm from './CreateProcurementForm';
import axiosInstance from '../../axiosInstance';
import { useNavigate } from 'react-router-dom';
import { Endpoint } from '../../services/endpoint/endpoint';
import {
  UnpublishAProcurement,
  getAProcurement,
} from '../../services/procurementService';
import { isDisabled } from '@testing-library/user-event/dist/utils';

interface ProcurementDetailsForSupplier {
  procurementProjectId: string;
  projectTitle: string;
  projectDescription: string;
  prerequisites: string;
  status: number;
  lastUpdated: string;
  additionalInformation: string;
  tags: { tagName: string }[];
  industryId: string;
  isBiddingProject: boolean;
  supplierTypeToBid: string;
  projectGoodsAndServices: any[];
  expressionOfInterests: any[];
  technicalInformations: any[];
  commercialInformations: any[];
  referenceToWork: string;
  assignPointsToReferenceToWork: boolean;
  approvedDate: Date | null;
  biddingStartDate: Date;
  biddingEndDate: Date;
  bidRequirement: string;
  accessRequestedToBid: boolean | null;
  canBid: boolean | null;
}

interface ProcurementProjectDetailsProps {
  project: ProcurementDetailsForSupplier;
  onClose: () => void;
}

const baseUrl = process.env.REACT_APP_BASE_URL;

const ProcurementDetailsForSupplier: React.FC<
  ProcurementProjectDetailsProps
> = ({ project, onClose }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editFormData, setEditFormData] =
    useState<ProcurementDetailsForSupplier | null>(null);
  const navigate = useNavigate();

  const formatDate = (dateString: string | null) => {
    if (!dateString) return <div className="text-green-500">In Review</div>;
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(date);
  };

  const requestForProposal = async () => {
    try {
      if (project.canBid !== true) {
        const response = await axiosInstance.post(
          `${baseUrl}/procurementProjects/requestAccessToBidProcurement`,
          {
            procurementProjectId: project.procurementProjectId,
          }
        );

        if (response.data.isSuccess) {
          onClose();
          navigate(`/supplierProcurements`);
        }
      } else if (project.canBid === true) {
        onClose();
        navigate(`/supplierProcurements/bid`, {
          state: {
            ProcurementProjectId: project.procurementProjectId,
          },
        });
      }
    } catch {
      //catch the error
    }
  };

  const handleEditClose = () => {
    setIsEditMode(false);
    setEditFormData(null);
  };

  const isDisabled2 =
    project.accessRequestedToBid === true && project.canBid !== true;

  if (isEditMode && editFormData) {
    return (
      <CreateProcurementForm
        onClose={handleEditClose}
        initialData={editFormData}
      />
    );
  }

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-900/50">
      <div className="bg-white w-3/6 h-5/6 flex flex-col rounded-2xl shadow-lg">
        <div className="flex-1 overflow-y-auto px-8 py-6">
          <button
            onClick={onClose}
            className="flex items-center gap-2 text-gray-600 mb-6"
          >
            <ArrowLeft size={20} />
            Back
          </button>

          <h1 className="text-2xl font-bold mb-8">{project.projectTitle}</h1>

          <div className="flex gap-6 mb-8">
            <div className="flex flex-col gap-4">
              <div className="flex items-start">
                <span className="text-gray-500 text-sm w-36">
                  Submission date:
                </span>
                <span>{formatDate(project.lastUpdated)}</span>
              </div>
              <div className="flex items-start">
                <span className="text-gray-500 text-sm w-36">
                  Approved date:
                </span>
                <span>
                  {formatDate(project.approvedDate?.toString() || null)}
                </span>
              </div>
              <div className="flex items-start">
                <span className="text-gray-500 text-sm w-36">
                  Bidding start date:
                </span>
                <span>
                  {formatDate(project.biddingStartDate?.toString() || null)}
                </span>
              </div>
              <div className="flex items-start">
                <span className="text-gray-500 text-sm w-36">
                  Bidding end date:
                </span>
                <span>
                  {formatDate(project.biddingEndDate?.toString() || null)}
                </span>
              </div>
            </div>

            {/* Procurement Activity Section */}
            <div className="rounded-lg ml-16 bg-green-50 border border-green-200 p-4 w-64">
              <h3 className="font-semibold text-gray-700 mb-4">
                Procurement Activity
              </h3>
              <div className="space-y-2">
                <div className="flex justify-between text-gray-600">
                  <p>Interests:</p>
                  <p>112 suppliers</p>
                </div>
                <div className="flex justify-between text-gray-600">
                  <p>Bidding:</p>
                  <p>18 suppliers</p>
                </div>
                <div className="flex justify-between text-gray-600">
                  <p>Contract:</p>
                  <p>0 suppliers</p>
                </div>
              </div>
            </div>
          </div>

          <section className="space-y-6">
            <div>
              <h2 className="font-semibold mb-2">Description:</h2>
              <p className="text-gray-600">{project.projectDescription}</p>
            </div>
            <div>
              <h2 className="font-semibold mb-2">
                Prerequisites or Submission requirements
              </h2>
              <ul className="list-disc pl-5 text-gray-600 space-y-1">
                {project.prerequisites.split('\n').map((item, index) => (
                  <li key={index}>{item.trim()}</li>
                ))}
              </ul>
            </div>
            <div>
              <h2 className="font-semibold mb-2">Bid requirements</h2>
              <ul className="list-disc pl-5 text-gray-600">
                <li>{project.bidRequirement}</li>
              </ul>
            </div>
            <div>
              <h2 className="font-semibold mb-2">Additional information</h2>
              <p className="text-gray-600">{project.additionalInformation}</p>
            </div>
            <div>
              <h2 className="font-semibold mb-2">Supporting document</h2>
              <div className="flex items-center gap-2 text-blue-600 hover:text-blue-700 cursor-pointer bg-gray-50 p-2">
                <FileText size={20} />
                <span>Pipeline Inspection</span>
              </div>
            </div>
            <div>
              <h2 className="font-semibold mb-2">Tags</h2>
              <div className="flex flex-wrap gap-2">
                {project.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm"
                  >
                    {tag.tagName}
                  </span>
                ))}
              </div>
            </div>
          </section>
        </div>

        <div className="border-t border-gray-200 p-4 rounded-2xl bg-white">
          <div className="flex justify-end gap-3">
            <button
              className={`px-6 py-2.5 rounded-lg font-medium ${
                isDisabled2
                  ? 'bg-yellow-200 text-gray-500 cursor-not-allowed'
                  : 'bg-yellow-400 text-black hover:bg-yellow-500'
              }`}
              onClick={requestForProposal}
              disabled={isDisabled2}
            >
              {project.canBid != true
                ? 'Request For Proposal (RFP)'
                : 'Start Bid'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcurementDetailsForSupplier;
