import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import AppButton from '../../../components/AppButton/AppButton';

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleConfirmClick?: () => void;
}

const MyCatalogueDeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  onClose,
  handleConfirmClick = () => {},
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  if (!isOpen) return null;
  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-white rounded-3xl shadow-lg max-w-2xl w-full mx-4 py-8 sm:px-6">
        <button
          onClick={onClose}
          className="absolute top-5 right-8 text-gray-500 hover:text-gray-700"
          aria-label="Close modal"
        >
          <FaTimes size={20} />
        </button>
        <div className="py-6 px-6">
          <h2 className="text-xl font-semibold mt-4">
            Are you sure you want to delete?
          </h2>
          <p className=" text-gray-600 mt-2">
            Deleting this means all data would be lost. Do you wish to continue?
          </p>
          <div className="mt-6 space-y-4">
            {/* Option 1: Yes, Delete */}
            <div
              className={`flex items-start px-4 py-3 ${
                selectedOption === 'delete'
                  ? 'bg-red-100 border border-red-500 rounded-lg'
                  : 'bg-white border-none'
              }`}
            >
              <input
                type="radio"
                id="delete"
                name="action"
                value="delete"
                checked={selectedOption === 'delete'}
                onChange={() => handleOptionChange('delete')}
                className="h-5 w-5 text-red-500 focus:ring-red-500 px-6 py-8"
              />
              <div className="ml-3">
                <label
                  htmlFor="delete"
                  className="text-red-600 font-medium cursor-pointer"
                >
                  Yes, delete
                </label>
                <p className="text-gray-500 text-sm">
                  This action is permanent and irreversible.
                </p>
              </div>
            </div>

            {/* Option 2: Unpublish */}
            <div
              className={`flex items-start px-4 py-2 ${
                selectedOption === 'unpublish'
                  ? 'bg-gray-100 border border-gray-500 rounded-lg'
                  : 'bg-white border-none'
              }`}
            >
              <input
                type="radio"
                id="unpublish"
                name="action"
                value="unpublish"
                checked={selectedOption === 'unpublish'}
                onChange={() => handleOptionChange('unpublish')}
                className="h-5 w-5 text-gray-500 focus:ring-gray-500"
              />
              <div className="ml-3">
                <label
                  htmlFor="unpublish"
                  className="text-gray-800 font-medium cursor-pointer"
                >
                  No, unpublish instead
                </label>
                <p className="text-gray-500 text-sm">
                  This action is temporary and reversible. Operators would no
                  longer be able to view.
                </p>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="mt-8 flex justify-end space-x-4">
            <AppButton
              title={'Confirm'}
              onClick={handleConfirmClick}
              className="py-2 px-10 bg-primary rounded-md hover:bg-yellow-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCatalogueDeleteModal;
