import React from 'react';
import AppButton from '../AppButton/AppButton';
import { FaTimes } from 'react-icons/fa';

type OrderModalProps = {
  orderNumber: string;
  orderText?: string;
  orderStatus?: string;
  appBtnView?: string;
  appBtnOkay?: string;
  onView: () => void;
  onBack: () => void;
};

const OrderCompletedModal: React.FC<OrderModalProps> = ({
  orderNumber,
  orderText = 'Order number:',
  orderStatus = 'Order completed!',
  appBtnView = 'View',
  appBtnOkay = 'Back',
  onView,
  onBack,
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      {/* Modal Container */}
      <div className="bg-white rounded-2xl shadow-lg max-w-sm w-full p-6 relative">
        {!appBtnView && !appBtnOkay && (
          <button
            onClick={onBack}
            className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
            aria-label="Close modal"
          >
            <FaTimes size={15} />
          </button>
        )}
        {/* Icon */}
        <div className="flex justify-center mb-4 ">
          <div className="w-16 h-16 flex justify-center items-center bg-green-100 rounded-full">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/IconSuccess.svg`}
              alt={'success'}
              className=" "
            />
          </div>
        </div>

        {/* Title */}
        <h2 className="text-center text-2xl font-semibold mb-2">
          {orderStatus}
        </h2>

        {/* Order Number */}
        <p className="text-center text-gray-600 text-sm mb-6">
          {orderText} <span className="font-medium">{orderNumber}</span>
        </p>

        {/* Buttons */}
        <div className="flex justify-center gap-5">
          {appBtnView && onView && (
            <AppButton
              title={appBtnView}
              onClick={onView}
              className="px-8 py-2 border border-gray-500 rounded-lg text-gray-700 font-medium hover:bg-gray-100"
            />
          )}
          {appBtnOkay && onBack && (
            <AppButton
              title={appBtnOkay}
              onClick={onBack}
              className="px-8 py-2 bg-primary rounded-lg font-medium hover:bg-yellow-600"
            />
          )}
        </div>
        {/* <div className="flex justify-center gap-5">
          <AppButton
            title={appBtnView}
            onClick={onView}
            className="px-8 py-2 border border-gray-500 rounded-lg text-gray-700 font-medium hover:bg-gray-100"
          />
          <AppButton
            title={appBtnOkay}
            onClick={onBack}
            className="px-8 py-2 bg-primary  rounded-lg font-medium hover:bg-yellow-600"
          />
        </div> */}
      </div>
    </div>
  );
};

export default OrderCompletedModal;
