import React, { useState } from 'react';
import { Calendar } from 'lucide-react';
import axios from 'axios';
import axiosInstance from '../../axiosInstance';
import { useLocation, useNavigate } from 'react-router-dom';

interface UploadedBidDocument {
  uploadedBidDocumentId: string;
  documentType: string;
  points: number;
  documentId: string;
  type: number;
}

interface ExpressionOfInterest {
  expressionOfInterestId?: string;
  address: string;
  location: string;
  state: string;
  latitude: number;
  longitude: number;
  estimatedCostOfProject: number;
  estimatedStartDate: string;
  estimatedEndDate: string;
  purposeOfProject: string;
  statutoryDeclaration: boolean;
  procurementBidId?: string;
  uploadedBidDocuments?: UploadedBidDocument[];
}

const ExpressionOfInterestBid = () => {
  const location = useLocation();
  const procurementId = location.state?.ProcurementProjectId;
  const [formData, setFormData] = useState<ExpressionOfInterest>({
    address: '',
    location: '',
    state: '',
    latitude: 0,
    longitude: 0,
    estimatedCostOfProject: 0,
    estimatedStartDate: '',
    estimatedEndDate: '',
    purposeOfProject: '',
    statutoryDeclaration: false,
  });

  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = e.target;

    // Handle checkbox separately
    if (type === 'checkbox') {
      setFormData((prev) => ({
        ...prev,
        statutoryDeclaration: (e.target as HTMLInputElement).checked,
      }));
      return;
    }

    // Convert numeric fields to numbers
    const numericFields = ['latitude', 'longitude', 'estimatedCostOfProject'];
    const parsedValue = numericFields.includes(name)
      ? parseFloat(value) || 0
      : value;

    setFormData((prev) => ({
      ...prev,
      [name]: parsedValue,
    }));
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Prepare the payload
      const payload = {
        // Generate a unique ID
        procurementProjectId: procurementId, // Generate a unique ID
        expressionOfInterest: {
          ...formData,
          uploadedBidDocuments: selectedFile
            ? [
                {
                  uploadedBidDocumentId: crypto.randomUUID(),
                  documentType: selectedFile.type,
                  points: 0,
                  type: 0,
                },
              ]
            : [],
        },
        expressionOfInterestApprovalStatus: 1,
      };

      // Make the API call
      const response = await axiosInstance.post(
        `${baseUrl}/procurementProjects/createSupplierBid`,
        payload
      );

      if (response.data.isSuccess) {
        navigate(`/supplierProcurement/procurement-dashboard`, {
          state: {
            ProcurementProjectId: procurementId,
          },
        });
      }
    } catch {
      // Handle error
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen max-w-full bg-gray-50">
      {/* Header */}
      <div className="bg-white p-4 border-b">
        <div className="max-w-full mx-auto">
          <nav className="flex items-center space-x-2 text-sm text-gray-500">
            <span>Dashboard</span>
            <span>/</span>
            <span>Pipeline Inspection and Maintenance</span>
            <span>/</span>
            <span>Technical Information</span>
          </nav>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-full mx-auto p-6">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-semibold">Expression of Interest</h1>
          <button className="px-4 py-2 border rounded-md hover:bg-gray-50">
            View details
          </button>
        </div>

        {/* Project Info */}
        <div className="grid grid-cols-4 gap-6 mb-8">
          <div>
            <div className="text-sm text-gray-500">Opportunity</div>
            <div>Pipeline Inspection and Maintenance</div>
          </div>
          <div>
            <div className="text-sm text-gray-500">Type</div>
            <div>Joint Venture</div>
          </div>
          <div>
            <div className="text-sm text-gray-500">Date</div>
            <div>28/08/2024</div>
          </div>
          <div>
            <div className="text-sm text-gray-500">No of Interests</div>
            <div>10</div>
          </div>
        </div>

        {/* Progress Bar */}
        <div className="mb-8">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center space-x-2 w-full">
              <div className="w-8 h-8 rounded-full bg-green-500 flex items-center justify-center text-white">
                1
              </div>
              <div className="flex-1 h-1 bg-gray-200">
                <div className="h-full bg-green-500 w-1/3"></div>
              </div>
              <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                2
              </div>
              <div className="flex-1 h-1 bg-gray-200"></div>
              <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                3
              </div>
            </div>
          </div>
          <div className="bg-green-600 text-white p-2 rounded">
            Stage 1 of 3
          </div>
        </div>

        {/* Form */}
        <form
          onSubmit={handleSubmit}
          className="bg-white p-6 rounded-lg shadow"
        >
          <h2 className="text-lg font-semibold mb-6">
            Fill out the information below
          </h2>

          <div className="space-y-6">
            <div>
              <h3 className="font-medium mb-4">Location</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm mb-1">
                    Address<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Enter Address"
                    value={formData.address}
                    onChange={handleInputChange}
                    required
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm mb-1">
                      Location<span className="text-red-500">*</span>
                    </label>
                    <select
                      name="location"
                      value={formData.location}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="">Select</option>
                      <option value="City A">City A</option>
                      <option value="City B">City B</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm mb-1">
                      State<span className="text-red-500">*</span>
                    </label>
                    <select
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      required
                      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="">Select</option>
                      <option value="State X">State X</option>
                      <option value="State Y">State Y</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3 className="font-medium mb-4">Coordinates</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm mb-1">
                    Latitude<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    name="latitude"
                    step="0.01"
                    placeholder="0.00"
                    value={formData.latitude}
                    onChange={handleInputChange}
                    required
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm mb-1">
                    Longitude<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    name="longitude"
                    step="0.01"
                    placeholder="0.00"
                    value={formData.longitude}
                    onChange={handleInputChange}
                    required
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm mb-1">
                Estimated Cost of Project<span className="text-red-500">*</span>
              </label>
              <div className="flex">
                <select className="w-24 px-3 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500">
                  <option>USD</option>
                </select>
                <input
                  type="number"
                  name="estimatedCostOfProject"
                  placeholder="Enter Amount"
                  value={formData.estimatedCostOfProject}
                  onChange={handleInputChange}
                  required
                  className="flex-1 px-3 py-2 border border-l-0 rounded-r-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm mb-1">
                  Estimated Start Date<span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <input
                    type="date"
                    name="estimatedStartDate"
                    value={formData.estimatedStartDate}
                    onChange={handleInputChange}
                    required
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <Calendar className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
                </div>
              </div>
              <div>
                <label className="block text-sm mb-1">
                  Estimated End Date<span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <input
                    type="date"
                    name="estimatedEndDate"
                    value={formData.estimatedEndDate}
                    onChange={handleInputChange}
                    required
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <Calendar className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm mb-1">Cover Letter</label>
              <textarea
                name="purposeOfProject"
                placeholder="Pitch yourself"
                value={formData.purposeOfProject}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-md min-h-[100px] focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <h3 className="font-medium mb-4">Statutory Declaration</h3>
              <div className="flex items-start space-x-2">
                <div>
                  <input
                    type="checkbox"
                    name="statutoryDeclaration"
                    checked={formData.statutoryDeclaration}
                    onChange={handleInputChange}
                    className="mt-1"
                  />
                </div>
                <div>
                  <label className="text-sm">
                    I, the applicant, hereby attest that the information
                    provided in this submission is accurate and true to the best
                    of my knowledge.
                  </label>
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm mb-1">Upload Signature</label>
              <div className="flex items-center space-x-4">
                <input
                  type="file"
                  //   onChange={handleFileSelect}
                  className="hidden"
                  id="file-upload"
                  accept=".jpeg,.jpg,.png,.pdf"
                />
                <label
                  htmlFor="file-upload"
                  className="px-4 py-2 border rounded-md hover:bg-gray-50 cursor-pointer"
                >
                  Choose File
                </label>
                <span className="text-sm text-gray-500">
                  {selectedFile ? selectedFile.name : 'No File Chosen'}
                </span>
                <button
                  type="button"
                  className="ml-auto px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
                >
                  Upload
                </button>
              </div>
              <p className="text-sm text-gray-500 mt-2">
                Please upload jpeg, jpg, png or pdf, size less than 100KB
              </p>
            </div>
          </div>

          <div className="flex justify-end space-x-4 mt-8">
            <button
              type="button"
              className="px-4 py-2 border rounded-md hover:bg-gray-50"
            >
              Save & Exit
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 bg-primary text-black rounded-md hover:bg-primary disabled:opacity-50"
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExpressionOfInterestBid;
