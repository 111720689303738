import React, { useEffect, useState } from 'react';
import CustomTable, {
  Column,
  IPageRequest,
} from '../../../components/CustomTable';
import {
  CompanyRegisterFormValues,
  ProfileProps,
} from '../../../types/interfaces';
import { useTranslation } from 'react-i18next';
import { getSubsidiaries } from '../../../services/supplierService';

const AffiliatesAndSubsidiaries: React.FC<ProfileProps> = ({ company }) => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [pageCount, setPageCount] = useState(1);
  const [affiliates, setAffiliates] = useState<CompanyRegisterFormValues[]>([]);

  const { t } = useTranslation();

  const pageRequest: IPageRequest = {
    pageNumber: currentPage + 1,
    pageSize: itemsPerPage,
  };

  const columns: Column[] = [
    {
      title: t('businessName', 'Business Name'),
      dataKey: 'businessName',
      type: 'string',
    },
    {
      title: t('commercialRegNo', 'Commercial Reg. No'),
      dataKey: 'registrationNumber',
      type: 'string',
    },
    { title: t('country', 'Country'), dataKey: 'country', type: 'string' },
  ];

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };
  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  const fetchData = async () => {
    if (!company || !company?.companyId) return;
    const response = await getSubsidiaries(company?.companyId, pageRequest);
    if (response) {
      setAffiliates(response.data);
      if (response?.totalRecords) {
        setPageCount(Math.ceil(response.totalRecords / itemsPerPage));
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);

  return (
    <>
      <div className="border border-gray-300 bg-white shadow-sm rounded-lg px-8 py-6">
        <div>
          <h2 className="text-2xl font-medium mb-2">
            Affiliates and Subsidiaries
          </h2>
        </div>
        <hr className="mb-6" />
        <div>
          <CustomTable
            data={affiliates}
            columns={columns}
            pageCount={pageCount} // Total page count for pagination
            showPagination={true} // Show pagination control
            showSearch={true} // Show search bar
            onSearch={handleSearch} // Search function
            showAddButton={false} // Add button (set to false here)
            showTitle={false}
            onPageChange={(selectedPage) => handlePageChange(selectedPage)}
          />
        </div>
      </div>
    </>
  );
};

export default AffiliatesAndSubsidiaries;
