import React, { useEffect, useState } from 'react';
import CommandModal, { WorkflowCommandType } from './CommandModal'; // Importing the new modal
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  deleteCommand,
  getCommands,
} from '../../../../services/workflowServices';
import Delete from '../../../../components/Delete/Delete';
import CustomTable, {
  Column,
  IPageRequest,
} from '../../../../components/CustomTable';
import { useTranslation } from 'react-i18next';

export interface CommandData {
  descriptions: string;
  queryCommand: string;
  className: string;
  methodName: string;
  commandType: string | WorkflowCommandType;
  actionType: string;
  workflowId: string;
  workflowCommandId: string;
}

const CommandConfig: React.FC = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [pageCount, setPageCount] = useState(1);
  const [selectedAll, setSelectedAll] = useState(false);
  const [commandData, setCommandData] = useState<CommandData[]>([]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [commandItem, setCommandItem] = useState<CommandData | null>(null);
  const [selectedItems, setSelectedItems] = useState<boolean[]>(
    commandData.map(() => false)
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );
  const [isModalOpen, setModalOpen] = useState(false); // Modal state
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();

  const location = useLocation();
  const workflowId = location.state?.workflowId;
  const pageRequest: IPageRequest = {
    pageNumber: currentPage + 1,
    pageSize: itemsPerPage,
    workflowId: workflowId,
  };

  const columns: Column[] = [
    { title: t('ID', 'ID'), dataKey: 'workflowCommandId', type: 'string' },
    {
      title: t('Description', 'Description'),
      dataKey: 'descriptions',
      type: 'string',
    },
    {
      title: t('Class Name', 'Class Name'),
      dataKey: 'className',
      type: 'string',
    },
    {
      title: t('Command Type', 'Command Type'),
      dataKey: 'commandType',
      type: 'string',
    },
    {
      title: t('Command Query', 'Command Query'),
      dataKey: 'queryCommand',
      type: 'number',
    },
    {
      title: t('Action Type', 'Action Type'),
      dataKey: 'actionType',
      type: 'number',
    },
    {
      title: t('Actions', 'Actions'),
      dataKey: 'actions', // This key is used but not directly needed for rendering
      type: 'action',
      actions: [
        {
          label: t('Edit', 'Edit'),
          className: 'text-green-500',
          action: (item: any) => handleEdit(item),
          status: true,
        },
        {
          label: t('Delete', 'Delete'),
          className: 'text-red-500',
          action: (item: any) => handleDelete(item),
          status: true,
        },
      ],
    },
  ];

  const handleEdit = (item: any) => {
    editModal(item);
  };

  const handleDelete = (item: any) => {
    deleteModal(item);
  };

  const toggleSelectAll = () => {
    const newSelected = !selectedAll;
    setSelectedAll(newSelected);
    setSelectedItems(commandData.map(() => newSelected));
  };

  const toggleCheckbox = (index: number) => {
    const updatedSelectedItems = [...selectedItems];
    updatedSelectedItems[index] = !updatedSelectedItems[index];
    setSelectedItems(updatedSelectedItems);
  };

  const toggleDropdown = (index: number) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected);
  };

  const editModal = (command: CommandData) => {
    setModalOpen(true);
    setCommandItem(command);
  };
  const deleteModal = (command: CommandData) => {
    setCommandItem(command);
    setIsDeleteModalOpen(true);
  };

  const deleteSelectedCommand = async () => {
    if (!commandItem) return;
    const { workflowCommandId } = commandItem;
    const response: any = await deleteCommand(workflowCommandId);
    if (response.isSuccess) {
      toast.success(
        t('Command deleted successfully', 'Command deleted successfully')
      );
      setCommandItem(null);
      setIsDeleteModalOpen(false);
      fetchCommandList();
    }
  };

  const closeEditModal = () => {
    setModalOpen(false);
    setCommandItem(null);
    fetchCommandList();
  };

  const handleAddButtonClick = () => {
    setModalOpen(true);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    // You can also add your search logic here
  };

  const fetchCommandList = async () => {
    try {
      const response = await getCommands(pageRequest);
      if (response) {
        setCommandData(response.data);
        if (response?.totalRecords) {
          setPageCount(Math.ceil(response.totalRecords / itemsPerPage));
        }
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchCommandList();
  }, [currentPage, itemsPerPage]);

  const goBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  return (
    <>
      <div className="p-4 flex-1">
        {/* Title and Add button */}
        <div className="flex justify-start">
          <button
            type="button"
            onClick={goBack}
            className="text-black px-4 py-2 my-2 w-24 border border-[#101828] text-[#101828] font-semibold rounded-md"
          >
            {t('Back', 'Back')}
          </button>
        </div>

        <CustomTable
          data={commandData}
          columns={columns}
          pageCount={pageCount} // Pass the total page count
          showPagination={true} // Control whether to show pagination
          showSearch={true} // Control whether to show the search box
          onSearch={handleSearch} // Pass the search handler
          showAddButton={true}
          showTitle={true}
          addButtonText={t('+ New Command', '+ New Command')}
          title={t('Workflow Commands', 'Workflow Commands')}
          onAddButtonClick={handleAddButtonClick}
          onPageChange={(selectedPage) => handlePageChange(selectedPage)}
        />

        {/* Modal for adding new command */}
        {isModalOpen && (
          <CommandModal
            isOpen={isModalOpen}
            onClose={() => {
              setModalOpen(false);
              setCommandItem(null);
            }}
            onEdit={closeEditModal}
            workflowId={workflowId}
            initialValues={commandItem}
          />
        )}

        {isDeleteModalOpen && (
          <Delete
            title={t('Are you sure', 'Are you sure')}
            desc={t(
              'This action is permanent, all data would be lost.',
              'This action is permanent, all data would be lost.'
            )}
            onDelete={deleteSelectedCommand}
            onCancel={() => setModalOpen(false)}
          />
        )}
      </div>
    </>
  );
};

export default CommandConfig;
