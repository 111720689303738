import React, { useEffect, useState } from 'react';
import CustomTable, { Column } from '../../../components/CustomTable';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../../components/BreadCrumb/BreadCrumb';
import OperatorModal from './OperatorModal';

interface OperatorManagementProps {
  isEditable: boolean;
}

const Operator: React.FC<OperatorManagementProps> = ({ isEditable }) => {
  const { t } = useTranslation();
  const [operators, setOperators] = useState<any[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItem = operators.slice(indexOfFirstItem, indexOfLastItem);
  const pageCount = Math.ceil(operators.length / itemPerPage);

  const breadcrumbPaths = [
    { label: 'Dashboard', path: '/dashboard' },
    { label: 'Settings', path: '/settings' },
    { label: 'Operators', path: '/operators' },
  ];

  // const fetchOperators = async () => {
  //     try {
  //         const response = await getOperators();
  //         if (response) {
  //             setOperators(response.data);
  //         }
  //     } catch (error) {
  //         // console.error(error);
  //     }
  // };

  useEffect(() => {
    // fetchOperators();
  }, [currentPage, itemPerPage]);

  const openModal = (item: any) => {
    setIsOpenModal(true);
    // You can also pass the item for editing the selected operator
  };

  const deleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const columns: Column[] = [
    {
      title: t('operatorName', 'Operator Name'),
      dataKey: 'operatorName',
      type: 'string',
    },
    {
      title: t('email', 'Email'),
      dataKey: 'email',
      type: 'string',
    },
    {
      title: t('rcNumber', 'RC Number'),
      dataKey: 'rcNumber',
      type: 'string',
    },
    {
      title: t('dateCreated', 'Date Created'),
      dataKey: 'dateCreated',
      type: 'string',
    },
    {
      title: t('actions', 'Actions'),
      dataKey: 'actions',
      type: 'action',
      actions: [
        {
          label: t('view', 'View'),
          className: '',
          action: (item: any) => openModal(item), // Open modal in view mode
          status: true,
        },
        {
          label: t('edit', 'Edit'),
          className: 'text-green-500',
          action: (item: any) => openModal(item), // Open modal in edit mode
          status: true,
        },
        {
          label: t('delete', 'Delete'),
          className: 'text-red-500',
          action: () => deleteModal(), // Handle delete
          status: true,
        },
      ],
    },
  ];

  return (
    <div>
      <div>
        <Breadcrumb paths={breadcrumbPaths} />
      </div>
      <div>
        <CustomTable
          data={currentItem}
          columns={columns}
          pageCount={pageCount}
          showAddButton={true}
          addButtonText="Add Operator"
          onAddButtonClick={() => setIsOpenModal(true)} // Open modal for adding a new operator
          title="Operator Management"
          showTitle={true}
          showPagination={true}
          showSearch={true}
        />
      </div>
      {isOpenModal && (
        <OperatorModal
          onSubmit={() => {}}
          onClose={() => setIsOpenModal(false)}
        />
      )}
    </div>
  );
};

export default Operator;
