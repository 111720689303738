import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import ServiceInformation from './ServiceInformation';
import GoodsAndServices from './GoodsAndServices';
import ExpressionOfInterest from './ExpressionOfInterest';
import TechnicalInformation from './TechnicalInformation';
import Commercials from './Commercials';
import { Endpoint } from '../../services/endpoint/endpoint';
import {
  createAProcurement,
  editAProcurement,
  getAProcurement,
  initiateWorkFlow,
  initiateWorkFlowApproval,
} from '../../services/procurementService';
import { toast } from 'react-toastify';

interface ProcurementFormData {
  procurementProjectId?: string;
  projectTitle: string;
  industryId: string;
  isBiddingProject: boolean;
  supplierTypeToBid: string;
  tags: { tagName: string }[];
  projectDescription: string;
  prerequisites: string;
  projectGoodsAndServices: any[];
  expressionOfInterests: any[];
  technicalInformations: any[];
  commercialInformations: any[];
  referenceToWork: string;
  assignPointsToReferenceToWork: boolean;
  additionalInformation: string;
  status?: number;
  biddingStartDate: Date;
  biddingEndDate: Date;
  bidRequirement: string;
}

interface CreateProcurementFormProps {
  onClose: () => void;
  initialData?: ProcurementFormData;
}

const CreateProcurementForm: React.FC<CreateProcurementFormProps> = ({
  onClose,
  initialData,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState<ProcurementFormData>(
    initialData || {
      projectTitle: '',
      industryId: '',
      isBiddingProject: false,
      supplierTypeToBid: '',
      tags: [],
      projectDescription: '',
      prerequisites: '',
      projectGoodsAndServices: [],
      expressionOfInterests: [],
      technicalInformations: [],
      commercialInformations: [],
      referenceToWork: '',
      assignPointsToReferenceToWork: false,
      additionalInformation: '',
      biddingStartDate: new Date(),
      biddingEndDate: new Date(),
      bidRequirement: '',
    }
  );

  const location = useLocation();
  const isOperator = location.state?.isOperator;
  const isApprover = location.state?.isApprover;
  const requestId = location.state?.requestId;
  const entityName = location.state?.entityName;
  const entityId = location.state?.entityId;

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id && !initialData) {
      loadProcurementProject(id);
    }
  }, [id, initialData]);

  const loadProcurementProject = async (projectId: string) => {
    try {
      const response = await getAProcurement(projectId);
      if (response.isSuccess) {
        const loadedData = response.data;

        setFormData({
          ...loadedData,
          tags: Array.isArray(loadedData.tags) ? loadedData.tags : [],
        });
      }
    } catch {
      // console.error('Error loading procurement project:', error);
    }
  };

  const handleChange = (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      | { target: { name: string; value: any; checked?: boolean } }
  ) => {
    const { name, value, type } = e.target as HTMLInputElement;

    if (type === 'checkbox') {
      const { checked } = e.target as HTMLInputElement;
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else if (name === 'tags') {
      setFormData((prev) => ({
        ...prev,
        tags: value
          .split(',')
          .map((tagName: string) => ({ tagName: tagName.trim() })),
      }));
    } else if (type === 'date') {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: name.includes('Date') ? new Date(value) : value,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (action: 'save' | 'continue' | 'publish') => {
    try {
      const check = formData.status === 0 ? 0 : 3;
      const status = action === 'publish' ? 1 : check;

      const updatedFormData = { ...formData, status };

      const response = formData.procurementProjectId
        ? await editAProcurement(updatedFormData)
        : await createAProcurement(updatedFormData);

      if (response.isSuccess) {
        formData.procurementProjectId = response.data.id;
        if (action === 'save' || action === 'publish') {
          // Navigate to the homepage and pass a state to indicate a refresh is needed
          onClose();
          navigate('/procurement', { state: { refresh: true } });
        } else if (action === 'continue') {
          setCurrentStep((prev) => Math.min(prev + 1, 5));
        }
      } else {
        // console.error('Error submitting procurement project:', response.data.message);
      }
    } catch {
      // console.error('Error submitting procurement project:', error);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <ServiceInformation
            formData={formData}
            handleChange={handleChange}
            handleFileUpload={handleFileUpload}
          />
        );
      case 2:
        return (
          <GoodsAndServices formData={formData} handleChange={handleChange} />
        );
      case 3:
        return (
          <ExpressionOfInterest
            formData={formData}
            handleChange={handleChange}
            setFormData={setFormData}
          />
        );
      case 4:
        return (
          <TechnicalInformation
            formData={formData}
            handleChange={handleChange}
            setFormData={setFormData}
          />
        );
      case 5:
        return (
          <Commercials
            formData={formData}
            handleChange={handleChange}
            setFormData={setFormData}
          />
        );
      default:
        return null;
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFormData((prev) => ({
        ...prev,
      }));
    }
  };

  const handleWorkFlow = async () => {
    const workFlowData = {
      entityId: formData.procurementProjectId,
      entityName: 'Procurement',
      workflowDescription: 'Procurement Approval WorkFlow',
      parameters: [
        {
          parameterName: 'baseUrl',
          parameterValue: `${window.location.origin}`,
        },
        {
          parameterName: 'procurementId',
          parameterValue: formData.procurementProjectId,
        },
      ],
      selectedWorkflowType: 'Procurement',
    };

    const response = await initiateWorkFlow(workFlowData);
    if (response.isSuccess) {
      navigate('/requests/procurement-requests', { state: { refresh: true } });
    }
  };

  const handleWorkFlowApproval = async (workflowAction: number) => {
    const workFlowData = {
      requestId: requestId,
      entityId: entityId,
      entityName: entityName,
      comment: '',
      workflowAction: workflowAction,
      approverId: localStorage.getItem('userId'),
      descriptions: '',
    };

    const response = await initiateWorkFlowApproval(workFlowData);
    if (response?.isSuccess) {
      toast.success(response?.data?.message);
      navigate('/application-desk', { state: { refresh: true } });
    } else {
      toast.error(response.errorMessage);
    }
  };

  const isDisabled = formData.status === 0;

  return (
    <div className="max-w-8xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-6">
        {isOperator === false || isApprover === true
          ? 'Procurement WorkFlow Approval'
          : 'Create New Procurement Plan'}
      </h1>
      {isOperator === false ? (
        <div className="grid justify-items-end">
          <button
            className="bg-green-500 text-white font-medium py-2 px-4 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            onClick={() => handleWorkFlow()}
          >
            Review Application
          </button>
        </div>
      ) : isApprover === true ? (
        <div className="flex justify-end gap-3">
          <button
            className="bg-red-500 text-white font-medium py-2 px-4 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 w-40"
            onClick={() => handleWorkFlowApproval(1)}
          >
            Reject
          </button>
          <button
            className="bg-green-500 text-white font-medium py-2 px-4 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 w-40"
            onClick={() => handleWorkFlowApproval(0)}
          >
            Approve
          </button>
        </div>
      ) : (
        <div></div>
      )}

      <div className="mb-6">
        <div
          className="mt-2 h-2 bg-green-600 mt-8 mb-8"
          style={{ width: `${currentStep * 20}%` }}
        ></div>
        <div className="flex justify-between items-center">
          {[
            'Service Information',
            'Goods and Services',
            'Expression of Interest',
            'Technical Information',
            'Commercials',
          ].map((step, index) => (
            <button
              key={step}
              className={`px-4 py-2 ${currentStep === index + 1 ? 'bg-primary text-black' : 'bg-white-200 text-black-700'} rounded-md`}
              onClick={() => setCurrentStep(index + 1)}
            >
              {step}
            </button>
          ))}
        </div>
      </div>
      {renderStep()}
      <div className="mt-6 flex justify-end">
        <button
          className="px-4 py-3 w-40 bg-gray-200 text-black-700 rounded-md"
          onClick={() => handleSubmit('save')}
        >
          Save and exit
        </button>
        {currentStep < 5 ? (
          <button
            className={`ml-4 px-4 py-3 w-40 bg-primary text-black rounded-md
            ${
              isDisabled
                ? 'bg-yellow-200 text-gray-500 cursor-not-allowed'
                : 'bg-yellow-400 text-black hover:bg-yellow-500'
            }`}
            onClick={() => handleSubmit('continue')}
            disabled={isDisabled}
          >
            Save & Continue
          </button>
        ) : (
          <button
            className="ml-4 px-4 py-3 w-40 bg-primary text-black rounded-md"
            onClick={() => handleSubmit('publish')}
          >
            Publish
          </button>
        )}
      </div>
    </div>
  );
};

export default CreateProcurementForm;
