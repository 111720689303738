import React, { useMemo } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ProjectInterface } from './../ProjectAndAward/ProjectAndAward';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import {
  createProject,
  updateProject,
} from '../../../../services/supplierService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface ProjectModalProps {
  onClose: () => void;
  initialValues?: ProjectInterface | null;
  isEditable: boolean;
}

const validationSchema = Yup.object({
  projectName: Yup.string().required('Project Name is required'),
  clientName: Yup.string().required('Client Name is required'),
  yearOfExecution: Yup.string().required('Year of Execution is required'),
  projectValue: Yup.number().required('Value of Project is required'),
});

const ProjectModal: React.FC<ProjectModalProps> = ({
  onClose,
  initialValues,
  isEditable,
}) => {
  const { supplierId } = useGlobalContext();
  const { t } = useTranslation();

  const formInitialValues = useMemo<ProjectInterface>(() => {
    if (initialValues) {
      return {
        ...initialValues,
      };
    }
    return {
      projectId: '',
      projectName: '',
      projectValue: 0,
      yearOfExecution: 0,
      companyId: '',
      clientName: '',
    };
  }, [initialValues]);

  const handleSubmit = async (values: ProjectInterface) => {
    try {
      const payload = {
        ...values,
        companyId: supplierId,
      };

      if (initialValues) {
        payload.projectId = initialValues.projectId;
        const response = await updateProject(payload);
        if (response.isSuccess) {
          toast.success(
            t('projectUpdatedSuccessfully', 'Project updated successfully')
          );
          onClose();
        }
      } else {
        const { projectId, ...payloadWithoutProjectId } = payload;
        const response = await createProject({
          ...payloadWithoutProjectId,
        });
        if (response.isSuccess) {
          toast.success(
            t('projectCreatedSuccessfully', 'Project created successfully')
          );
          onClose();
        }
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-10">
      <div className="bg-white rounded-2xl shadow-lg w-full max-w-4xl p-8">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            {t('addProjects', 'Add Projects')}
          </h2>
          <button className="text-gray-500 text-xl" onClick={onClose}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
              alt={t('close', 'close')}
            />
          </button>
        </div>

        {/* Divider */}
        <div className="divider my-8 !bg-[#E5E5E5]"></div>

        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="grid grid-cols-2 gap-6">
              {/* Project Name */}
              <div className="mb-4">
                <label htmlFor="projectName" className="block mb-1 font-medium">
                  {t('name', 'Name')}
                  <span className="text-red-500">*</span>
                </label>
                <Field
                  disabled={!isEditable}
                  name="projectName"
                  placeholder={t('enterProjectName', 'Enter Project name')}
                  className="w-full border rounded-lg p-2 text-sm h-11"
                />
                <ErrorMessage
                  name="projectName"
                  component="span"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Client Name */}
              <div className="mb-4">
                <label htmlFor="clientName" className="block mb-1 font-medium">
                  {t('clientName', 'Client Name')}
                  <span className="text-red-500">*</span>
                </label>
                <Field
                  disabled={!isEditable}
                  name="clientName"
                  placeholder={t('enterClientName', "Enter Client's Name")}
                  className="w-full border rounded-lg p-2 text-sm h-11"
                />
                <ErrorMessage
                  name="clientName"
                  component="span"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Year of Execution */}
              <div className="mb-4">
                <label
                  htmlFor="yearOfExecution"
                  className="block mb-1 font-medium"
                >
                  {t('yearOfExecution', 'Year of Execution')}
                  <span className="text-red-500">*</span>
                </label>
                <Field
                  as="select"
                  name="yearOfExecution"
                  disabled={!isEditable}
                  className="w-full border rounded-lg p-2 text-sm h-11"
                >
                  <option value="">{t('select', 'Select')}</option>
                  {Array.from(
                    { length: 50 },
                    (_, i) => new Date().getFullYear() - i
                  ).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="yearOfExecution"
                  component="span"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Value of Project */}
              <div className="mb-4">
                <label
                  htmlFor="projectValue"
                  className="block mb-1 font-medium"
                >
                  {t('valueOfProject(USD)', 'Value of Project (USD)')}
                  <span className="text-red-500">*</span>
                </label>
                <Field
                  type="number"
                  name="projectValue"
                  disabled={!isEditable}
                  placeholder={t(
                    'enterValueOfProject',
                    'Enter Value of Project'
                  )}
                  className="w-full border rounded-lg p-2 text-sm h-11"
                />
                <ErrorMessage
                  name="projectValue"
                  component="span"
                  className="text-red-500 text-sm"
                />
              </div>
            </div>

            {/* Divider */}
            {isEditable && <div className="divider my-6 !bg-[#E5E5E5]"></div>}

            {/* Save and Cancel buttons */}
            <div className="flex justify-end mt-4">
              {isEditable && (
                <button
                  type="submit"
                  className="bg-primary text-black px-12 h-12 py-2 rounded-lg font-semibold"
                >
                  {initialValues ? t('update', 'Update') : t('save', 'Save')}
                </button>
              )}
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ProjectModal;
