import React, { useCallback, useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import debounce from 'lodash/debounce';
import SearchBox from '../../components/SearchBox';
import AppButton from '../../components/AppButton/AppButton';
import { useTranslation } from 'react-i18next';
import AllGoods from './components/Goods/AllGoods';
import AllServices from './components/Services/AllServices';
import MyCatalogue from './components/MyCatalogue';
import Reports from './components/Reports';
import Drafts from './components/Drafts';
import OrdersPage from './components/OrdersPage/OrdersPage';
import { TabItemProps } from '../../types/interfaces';
import ChooseItem from './components/ChooseItem';
import AddProducts from './components/Goods/AddProducts';
import { getAllCategories } from '../../services/catalogService';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { toast } from 'react-toastify';
import AddServices from './components/Services/AddServices';
import { useNavigate } from 'react-router-dom';

const GoodsServiceCatalogue = () => {
  const { t } = useTranslation();
  const [isChooseItemOpen, setIsChooseItemOpen] = useState(false);
  const [isAddProductsOpen, setIsAddProductsOpen] = useState(false);
  const [isAddServicesOpen, setIsAddServicesOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<
    'All Goods' | 'All Services' | 'My Catalogue' | 'Drafts' | 'Reports'
    // | 'Orders'
  >('All Goods');

  const [categories, setCategories] = useState<
    { categorId: string; categoryName: string }[]
  >([]);
  const { selectedCategory, setSelectedCategory } = useGlobalContext();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      const params = { isParentCategory: false };
      const response = await getAllCategories(params);
      if (response?.isSuccess) {
        setCategories(response.data);
      }
    };

    fetchCategories();
  }, []);

  const handleOpenAddProducts = () => {
    setIsChooseItemOpen(false);
    setIsAddProductsOpen(true);
  };

  const handleOpenAddServices = () => {
    setIsChooseItemOpen(false);
    setIsAddServicesOpen(true);
  };

  // Handler for switching tabs
  const handleTabClick = (
    tab: 'All Goods' | 'All Services' | 'My Catalogue' | 'Drafts' | 'Reports'
    // | 'Orders'
  ) => {
    setActiveTab(tab);
  };

  // Handler for category change
  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedCategory(event.target.value);
  };

  const handleSearch = useCallback(
    debounce((value: string) => {
      navigate(`/catalogue/search?query=${value}`);
    }, 600),
    []
  );

  return (
    <>
      <div className="flex flex-col md:flex-row items-center gap-4 p-4 bg-gray-50">
        <div className="relative w-[20%] flex items-center justify-center">
          <select
            className="w-full p-2 h-[3.3rem] text-lg border rounded-lg focus:outline-none bg-white"
            value={selectedCategory ?? ''}
            onChange={handleCategoryChange}
          >
            <option value="">Categories</option>
            {categories.map((category) => (
              <option key={category.categorId} value={category.categorId}>
                {category.categoryName}
              </option>
            ))}
          </select>
          <span className="absolute right-2 flex items-center pointer-events-none">
            <FaChevronDown size={10} />
          </span>
        </div>
        <div className="w-[65%] ">
          <SearchBox onSearch={handleSearch} />
        </div>
        <div>
          <AppButton
            title={'+ Add'}
            className="bg-primary py-3 px-8 border rounded-lg text-lg font-semibold"
            onClick={() => {
              setIsChooseItemOpen(true);
            }}
          />
          <ChooseItem
            show={isChooseItemOpen}
            close={() => setIsChooseItemOpen(false)}
            onAddProductClick={handleOpenAddProducts}
            onAddServiceClick={handleOpenAddServices}
          />
        </div>
        <AddProducts
          show={isAddProductsOpen}
          close={() => setIsAddProductsOpen(false)}
        />
        <AddServices
          show={isAddServicesOpen}
          close={() => setIsAddServicesOpen(false)}
        />
      </div>

      <div className="p-6 bg-gray-50 min-h-screen">
        {/* Title and Button */}
        <div className="flex justify-between items-center ">
          <h1 className="text-2xl font-semibold">
            {t('Goods and Services Catalogue', 'Goods and Services Catalogue')}
          </h1>
        </div>
        <div className="mb-6">
          <h3 className="text-lg font-medium text-gray-500">
            {t(
              'view and search various products',
              'View and search various products'
            )}
          </h3>
        </div>

        {/* Tabs Section */}
        <div className="border-b mb-4">
          <div className="flex justify-start space-x-8">
            <TabItem
              title={t('All Goods', 'All Goods')}
              isActive={activeTab === 'All Goods'}
              onClick={() => handleTabClick('All Goods')}
            />
            <TabItem
              title={t('All Services', ' All Services')}
              isActive={activeTab === 'All Services'}
              onClick={() => handleTabClick('All Services')}
            />
            <TabItem
              title={t('My Catalogue', 'My Catalogue')}
              isActive={activeTab === 'My Catalogue'}
              onClick={() => handleTabClick('My Catalogue')}
            />
            {/* <TabItem
              title={t('Drafts', 'Drafts')}
              isActive={activeTab === 'Drafts'}
              onClick={() => handleTabClick('Drafts')}
            />
            <TabItem
              title={t('Reports', 'Reports')}
              isActive={activeTab === 'Reports'}
              onClick={() => handleTabClick('Reports')}
            /> */}
            {/* <TabItem
              title={t('Orders', 'Orders')}
              isActive={activeTab === 'Orders'}
              onClick={() => handleTabClick('Orders')}
            /> */}
          </div>
        </div>

        {/* Content for Each Tab */}
        <div>
          {activeTab === 'All Goods' && (
            <AllGoods selectedCategory={selectedCategory} />
          )}
          {activeTab === 'All Services' && (
            <AllServices selectedCategory={selectedCategory} />
          )}
          {activeTab === 'My Catalogue' && <MyCatalogue />}
          {activeTab === 'Drafts' && <Drafts />}
          {activeTab === 'Reports' && <Reports />}
          {/* {activeTab === 'Orders' && <OrdersPage />} */}
        </div>
      </div>
    </>
  );
};
const TabItem: React.FC<TabItemProps> = ({ title, isActive, onClick }) => {
  return (
    <button
      className={`pb-2 text-lg font-medium focus:outline-none mb-8 ${
        isActive ? 'border-b-4 border-[#FFC726] font-bold' : 'text-gray-500'
      }`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default GoodsServiceCatalogue;
