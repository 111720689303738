import React, { useEffect, useState } from 'react';
import CustomTable, {
  Column,
  IPageRequest,
} from '../../components/CustomTable';
import { useNavigate } from 'react-router-dom';
import { getSuppliers } from '../../services/supplierService';
import { useTranslation } from 'react-i18next';

const SupplierRequests: React.FC = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [pageCount, setPageCount] = useState(1);
  const [suppliers, setSuppliers] = useState<any[]>([]);

  const pageRequest: IPageRequest = {
    pageNumber: currentPage + 1,
    pageSize: itemsPerPage,
  };

  const navigate = useNavigate();

  const columns: Column[] = [
    {
      title: t('companyName', 'Company Name'),
      dataKey: 'businessName',
      type: 'string',
    },
    { title: t('email', 'Email'), dataKey: 'email', type: 'string' },
    { title: t('country', 'Country'), dataKey: 'country', type: 'string' },
    { title: t('location', 'Location'), dataKey: 'city', type: 'string' },
    { title: t('status', 'Status'), dataKey: 'status', type: 'status' },
    {
      title: t('actions', 'Actions'),
      dataKey: 'actions', // This key is used but not directly needed for rendering
      type: 'action',
      actions: [
        {
          label: t('view', 'View'),
          className: '',
          action: (item: any) => handleView(item),
          status: true,
        },
      ],
    },
  ];

  const handleView = (item: any) => {
    navigate(`/supplier-registration?supplierId=${item.companyId}`, {
      state: {
        isSupplier: false,
      },
    });
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    // You can also add your search logic here
  };

  const fetchSuppliers = async () => {
    const response = await getSuppliers(pageRequest);
    if (response) {
      setSuppliers(response.data);
      if (response?.totalRecords) {
        setPageCount(Math.ceil(response.totalRecords / itemsPerPage));
      }
    }
  };

  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    fetchSuppliers();
  }, [currentPage, itemsPerPage]);

  return (
    <div>
      <CustomTable
        data={suppliers}
        columns={columns}
        pageCount={pageCount} // Pass the total page count
        showPagination={true} // Control whether to show pagination
        showSearch={true} // Control whether to show the search box
        onSearch={handleSearch} // Pass the search handler
        showAddButton={false}
        showTitle={true}
        title={t('supplierRequests', 'Supplier Requests')}
        onPageChange={(selectedPage) => handlePageChange(selectedPage)}
      />
    </div>
  );
};

export default SupplierRequests;
