import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getAssignedTasks } from '../../../services/workflowServices';
import CustomTable, { Column } from '../../../components/CustomTable';

const AssignedTabContent: React.FC = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any | null>(null);
  const [assignedTasks, setAssignedTasks] = useState<any>([]);

  const navigate = useNavigate();

  const getAssignedTaskData = async () => {
    const response = await getAssignedTasks();
    if (!response?.isSuccess) {
      return;
    }
    setAssignedTasks(response.data);
  };

  useEffect(() => {
    getAssignedTaskData();
  }, []);

  // Handlers for actions
  const handleView = (task: any) => {
    const url = new URL(task.navUrl);
    const relativeUrlWithQuery = `${url.pathname}${url.search}`; // Combines pathname and query

    navigate(relativeUrlWithQuery, {
      state: {
        requestId: task.workflowRequestId,
        entityName: task.entityName,
        entityId: task.entityId,
        isReview: true,
        isTerminatingStep: task.terminatingStep,
        isApprover: true,
      },
    });

    setSelectedTask(task);
  };

  const openModal = () => {
    setIsModalOpen(true);
    setSelectedTask(null); // Clear for adding new personnel
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTask(null);
  };

  // Define column structure (now has access to handleView)
  const columns: Column[] = [
    {
      title: t('description', 'Description'),
      dataKey: 'descriptions',
      type: 'string',
    },
    {
      title: t('currentLevel', 'Current Level'),
      dataKey: 'currentLevel',
      type: 'string',
    },
    {
      title: t('preparedBy', 'Prepared By'),
      dataKey: 'preparedBy',
      type: 'string',
    },
    {
      title: t('lastAssignedOn', 'Last Assigned On'),
      dataKey: 'modifiedOn',
      type: 'dateTime',
    },
    {
      title: t('actions', 'Actions'),
      dataKey: 'actions',
      type: 'action',
      actions: [
        {
          label: t('review', 'Review'),
          className: 'btn-view',
          action: (item: any) => handleView(item),
          status: true,
        },
      ],
    },
  ];

  return (
    <div>
      <CustomTable
        data={assignedTasks}
        columns={columns}
        showPagination={true}
        showSearch={true}
        onAddButtonClick={openModal} // Hook for the 'Add' button
      />
    </div>
  );
};

export default AssignedTabContent;
