import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import Cards from '../../../components/CatalogueCards/Cards';
import AppButton from '../../../components/AppButton/AppButton';
import AddProducts from './Goods/AddProducts';
import { ChooseItemProps } from '../../../types/interfaces';

const ChooseItem: React.FC<ChooseItemProps> = ({
  show,
  close,
  onAddProductClick = () => {},
  onAddServiceClick = () => {},
}) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-white rounded-3xl shadow-lg w-full max-w-3xl py-8 sm:px-16">
        <button
          onClick={close}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
          aria-label="Close modal"
        >
          <FaTimes size={20} />
        </button>

        <div className="flex gap-10 mt-8 mb-4">
          <p className="text-2xl font-bold w-[50%]">
            Add Products and Services to your Catalogue
          </p>
          <p className="text-sm text-gray-600 mt-2 w-[50%]">
            Add your oil and gas products effortlessly, whether you're uploading
            individually, requesting certifications, or performing bulk uploads.
          </p>
        </div>

        <div className="flex gap-6">
          {/* Product Card */}
          <div className="flex-1 py-4">
            <Cards
              imagePath={'images/addProduct.svg'}
              title={'Add a New Product'}
              showDescription={true}
              description={'Fill in your listing detail one at a time.'}
            />
            <AppButton
              title={'Add Products +'}
              className="bg-primary  px-4 py-2 rounded-lg mt-6"
              onClick={onAddProductClick}
            />
          </div>

          {/* Service Card */}
          <div className="flex-1 py-4">
            <Cards
              imagePath={'images/addService.svg'}
              title={'Add a New Service'}
              showDescription={true}
              description={'Fill in your listing detail one at a time.'}
            />
            <AppButton
              title={'Add Services +'}
              className="bg-primary px-4 py-2 rounded-lg mt-6"
              onClick={onAddServiceClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseItem;
