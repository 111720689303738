import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import '../Forget-Password/PasswordReset.css';
import { FaArrowLeft, FaArrowRight, FaEye, FaEyeSlash } from 'react-icons/fa';
import AppButton from '../../../components/AppButton/AppButton';
import { PasswordResetValidationSchema } from '../ValidationSchema';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../../../layouts/NavBar/Navbar';
import { ResetPassword } from '../../../services/AccountServices';
import { toast } from 'react-toastify';

const ResetPasswordCard: React.FC = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.state?.token;
  const email = location.state?.email;

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: PasswordResetValidationSchema,
    onSubmit: async (values: {
      newPassword: string;
      confirmPassword: string;
    }) => {
      const payload = {
        newPassword: values.newPassword,
        token: token,
        email: email,
      };

      const response = await ResetPassword(payload);
      if (response?.isSuccess) {
        toast.success(response?.data?.message);
        navigate('/login');
      } else {
        toast.error(response?.errorMessage);
      }
    },
  });

  return (
    <div>
      <Navbar />
      <Modal
        isOpen={true}
        style={{
          content: {
            width: '700px',
            height: '400px',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            overflow: 'hidden',
            border: 'none',
          },
        }}
      >
        <center className="reset-password-card flex flex-col py-10">
          <h2 className="flex justify-start">Reset Password</h2>

          <form onSubmit={(e) => e.preventDefault()}>
            <div className="flex justify-start">
              <label htmlFor="newPassword">
                {t('newPassword', 'New Password')}*
              </label>
            </div>
            <div className="password-input-wrapper">
              <input
                type={showPassword ? 'text' : 'password'}
                id="newPassword"
                name="newPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                placeholder={t('enterNewPassword', 'Enter New Password')}
              />
              <button
                type="button"
                className="password-toggle-btn"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
              {formik.touched.newPassword && formik.errors.newPassword && (
                <span className="text-red-500 flex justify-start">
                  {formik.errors.newPassword}
                </span>
              )}
            </div>

            <div className="flex justify-start mt-4">
              <label htmlFor="confirmPassword">
                {t('confirmNewPassword', 'Confirm New Password')}*
              </label>
            </div>
            <div className="password-input-wrapper">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                placeholder={t('enterConfirmPassword', 'Confirm New Password')}
              />
              <button
                type="button"
                className="password-toggle-btn"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword && (
                  <span className="text-red-500 flex justify-start">
                    {formik.errors.confirmPassword}
                  </span>
                )}
            </div>

            <AppButton
              className="w-full bg-[#FFC726] text-black font-semibold p-5 rounded-md"
              title={t('resetPassword', 'Reset Password')}
              onClick={() => formik.handleSubmit()}
            />

            {/* Forgot Password */}
            <div className="flex items-center gap-2 mt-4 justify-center">
              <FaArrowLeft size={10} />
              <AppButton
                className="text-xs border-b-2 border-black"
                title={t('backToLogin', 'Back to Login')}
                onClick={() => {
                  navigate('/login');
                }}
              />
            </div>
          </form>
        </center>
      </Modal>
    </div>
  );
};

export default ResetPasswordCard;
