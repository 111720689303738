import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  deleteProcess,
  getProcesses,
} from '../../../../services/workflowServices';
import Delete from '../../../../components/Delete/Delete';
import CustomTable, {
  Column,
  IPageRequest,
} from '../../../../components/CustomTable';
import { useTranslation } from 'react-i18next';

interface PageRequest {
  pageNumber: number;
  pageSize: number;
  workflowId: string;
}

const WorkFlowSteps: React.FC = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(25); // Items per page for pagination
  const [pageCount, setPageCount] = useState(1);
  const [steps, setSteps] = useState<any[]>([]);
  const [selectedStepItem, setSelectedStepItem] = useState<any | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const workflowId = location.state?.workflowId;
  const workflowName = location.state?.workflowName;
  const pageRequest: IPageRequest = {
    pageNumber: currentPage + 1,
    pageSize: itemsPerPage,
    workflowId: workflowId,
  };

  const columns: Column[] = [
    { title: t('ID', 'ID'), dataKey: 'workflowStepRelationId', type: 'string' },
    {
      title: t('Step Name (From)', 'Step Name (From)'),
      path: 'fromStep.stepName',
      type: 'string',
    },
    {
      title: t('Step Name (To)', 'Step Name (To)'),
      path: 'toStep.stepName',
      type: 'string',
    },
    {
      title: t('Description', 'Description'),
      dataKey: 'description',
      type: 'string',
    },
    {
      title: t('Review Periods (hrs)', 'Review Periods (hrs)'),
      dataKey: 'duration',
      type: 'number',
    },
    {
      title: t('Actions', 'Actions'),
      dataKey: 'actions', // This key is used but not directly needed for rendering
      type: 'action',
      actions: [
        {
          label: t('Edit', 'Edit'),
          className: 'text-green-500',
          action: (item: any) => handleEdit(item),
          status: true,
        },
        {
          label: t('Delete', 'Delete'),
          className: 'text-red-500',
          action: (item: any) => handleDelete(item),
          status: true,
        },
      ],
    },
  ];

  const handleEdit = (item: any) => {
    navigate('/settings/workflow/step', {
      state: {
        workflowId,
        workflowStepRelationId: item.workflowStepRelationId,
      },
    });
  };

  const handleDelete = (item: any) => {
    deleteModal(item);
  };

  const deleteModal = (step: any) => {
    setSelectedStepItem(step);
    setIsDeleteModalOpen(true);
  };

  const deleteSelectedStep = async () => {
    setIsDeleteModalOpen(false);
    if (!selectedStepItem) return;
    const { workflowStepRelationId } = selectedStepItem;
    if (!workflowStepRelationId) return;
    const response: any = await deleteProcess(workflowStepRelationId);
    if (response?.isSuccess) {
      toast.success(response?.data?.message);
      fetchSteps();
    }
  };

  const fetchSteps = async () => {
    const response = await getProcesses(pageRequest);
    if (response) {
      setSteps(response.data);
      if (response?.totalRecords) {
        setPageCount(Math.ceil(response.totalRecords / itemsPerPage));
      }
    }
  };

  const handleAddButtonClick = () => {
    navigate('/settings/workflow/step', {
      state: { workflowId },
    });
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    // You can also add your search logic here
  };

  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    fetchSteps();
  }, [workflowId, currentPage, itemsPerPage]);

  const goBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <>
      <div className="p-4 flex-1">
        <div className="flex justify-start mb-4">
          <button
            type="button"
            onClick={goBack}
            className="text-black px-4 py-2 my-2 w-24 border border-[#101828] text-[#101828] font-semibold rounded-md"
          >
            {t('Back', 'Back')}
          </button>
        </div>
        {/* Path and Title */}
        <div className="mb-4">
          <h1 className="text-3xl">{workflowName} - Workflow Steps</h1>
        </div>

        {/* Card-like Container */}
        {/* <div className="bg-white flex gap-10  p-4 rounded-lg shadow-md mb-6">
          <div className="mb-4">
            <div className="flex flex-col">
              <strong>{t('Application Type:', 'Application Type:')}</strong>
              <span>{t('Supplier Application', 'Supplier Application')}</span>
            </div>
          </div>
          <div className="mb-4">
            <strong>{t('Description:', 'Description:')}</strong>
            <p>
              {t(
                'A supplier workflow to streamline application approvals.',
                'A supplier workflow to streamline application approvals.'
              )}
            </p>
          </div>
          <div className="mb-4">
            <strong>{t('Date Created:', 'Date Created:')}</strong>
            <p>{new Date().toLocaleDateString()}</p>
          </div>
        </div> */}

        <CustomTable
          data={steps}
          columns={columns}
          pageCount={pageCount} // Pass the total page count
          showPagination={true} // Control whether to show pagination
          showSearch={true} // Control whether to show the search box
          onSearch={handleSearch} // Pass the search handler
          showAddButton={true}
          addButtonText={t('+ Add', '+ Add')}
          title=""
          onAddButtonClick={handleAddButtonClick}
        />

        {isDeleteModalOpen && (
          <Delete
            title={t('Are you sure', 'Are you sure')}
            desc={t(
              'This action is permanent, all data would be lost.',
              'This action is permanent, all data would be lost.'
            )}
            onDelete={deleteSelectedStep}
            onCancel={() => setIsDeleteModalOpen(false)}
          />
        )}
      </div>
    </>
  );
};

export default WorkFlowSteps;
