import React, { useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  createRole,
  getAllMenu,
  updateRole,
  getPermissions,
  getRole,
} from '../../../services/AccountServices';
import { RoleItem } from './RolePermission';

export interface OptionType {
  value: string;
  label: string;
}

export interface Role {
  id: string;
  roleName: string;
  description: string;
  menuIds: string[];
  permissions: string[];
}

interface RolePermissionModalProps {
  onClose: () => void;
  isEditable: boolean;
  role?: RoleItem | null;
  onSubmit: () => void;
}

// Validation schema
const validationSchema = Yup.object().shape({
  roleName: Yup.string().required('Role Name is required'),
  // description: Yup.string().required('Description is required'),
  navigationMenu: Yup.array().min(
    1,
    'At least one Navigation Menu is required'
  ),
  permissions: Yup.array().min(1, 'At least one Permission is required'),
});

const RolePermissionModal: React.FC<RolePermissionModalProps> = ({
  onClose,
  isEditable,
  role,
  onSubmit,
}) => {
  const [navigationMenuOptions, setNavigationMenuOptions] = useState<
    OptionType[]
  >([]);
  const [permissionOptions, setPermissionOptions] = useState<OptionType[]>([]);
  const [initialValues, setInitialValues] = useState({
    roleName: '',
    description: '',
    navigationMenu: [],
    permissions: [],
  });
  const [loading, setLoading] = useState(true);

  const handleSubmit = async (values: any) => {
    const payload = {
      roleId: role ? role.roleId : undefined, // Include roleId for updates
      roleName: values.roleName,
      description: values.description,
      menuIds: values.navigationMenu.map((menu: OptionType) => menu.value),
      permissions: values.permissions.map(
        (permission: OptionType) => permission.value
      ),
    };

    try {
      if (role) {
        const response = await updateRole(payload);
        if (response?.isSuccess) {
          toast.success(response?.data?.message);
          onSubmit();
        }
      } else {
        const response = await createRole(payload);
        if (response?.isSuccess) {
          toast.success(response?.data?.message);
          onSubmit();
        }
      }
      onClose();
    } catch (error: any) {
      toast.error('Error saving role permissions');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [menuResponse, permissionResponse] = await Promise.all([
          getAllMenu({ pageNumber: 1, pageSize: 1000 }),
          getPermissions(),
        ]);

        if (menuResponse?.data) {
          const menuOptions = menuResponse.data.map((menu: any) => ({
            value: menu.menuId,
            label: menu.menuName,
          }));
          setNavigationMenuOptions(menuOptions);
        }

        if (permissionResponse) {
          const permissionOptions = Object.keys(permissionResponse).map(
            (permission: any) => ({
              value: permission,
              label: permissionResponse[permission],
            })
          );
          setPermissionOptions(permissionOptions);
        }

        if (role) {
          const response = await getRole(role.roleId);
          if (!response.isSuccess) {
            toast.error('Role not found');
            return;
          }
          const selectedRole = response.data;
          setInitialValues({
            roleName: selectedRole.roleName,
            description: selectedRole.description,
            navigationMenu: (selectedRole.menuIds || [])
              .map((menuId: string) =>
                navigationMenuOptions.find((option) => option.value === menuId)
              )
              .filter(Boolean),
            permissions: (selectedRole.permissions || [])
              .map((permissionId: string) =>
                permissionOptions.find(
                  (option) => option.value === permissionId
                )
              )
              .filter(Boolean),
          });
        }
        setLoading(false);
      } catch (error) {
        toast.error('Error fetching data.');
        setLoading(false);
      }
    };

    fetchData();
  }, [role, loading]);

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-4xl h-[500px] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            {role ? 'Edit Role Permissions' : 'Add Role Permissions'}
          </h2>
          <button className="text-red-500 text-xl" onClick={onClose}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
              alt="close"
            />
          </button>
        </div>

        <div className="divider my-6 !bg-[#E5E5E5]"></div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form className="flex flex-col gap-y-6">
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <label htmlFor="roleName" className="block mb-1">
                    Role Name<span className="text-red-500"> *</span>
                  </label>
                  <Field
                    disabled={!isEditable}
                    name="roleName"
                    placeholder="Type Role Name"
                    className="w-full border rounded-lg text-sm p-2 h-11"
                  />
                  <ErrorMessage
                    name="roleName"
                    component="span"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="description" className="block mb-1">
                    Description
                  </label>
                  <Field
                    disabled={!isEditable}
                    name="description"
                    placeholder="Enter description"
                    className="w-full border rounded-lg text-sm p-2 h-11"
                  />
                  <ErrorMessage
                    name="description"
                    component="span"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <label htmlFor="navigationMenu" className="block mb-1">
                    Navigation Menu<span className="text-red-500"> *</span>
                  </label>
                  <Select
                    isMulti
                    options={navigationMenuOptions as any}
                    value={values.navigationMenu || []}
                    onChange={(selected) =>
                      setFieldValue('navigationMenu', selected)
                    }
                    placeholder="Navigation Menu"
                    className="w-full"
                    isDisabled={!isEditable}
                  />
                  <ErrorMessage
                    name="navigationMenu"
                    component="span"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="permissions" className="block mb-1">
                    Permissions<span className="text-red-500"> *</span>
                  </label>
                  <Select
                    isMulti
                    options={permissionOptions as any}
                    value={values.permissions || []}
                    onChange={(selected) =>
                      setFieldValue('permissions', selected)
                    }
                    placeholder="Permissions"
                    className="w-full"
                    isDisabled={!isEditable}
                  />
                  <ErrorMessage
                    name="permissions"
                    component="span"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>

              <div className="divider my-2 !bg-[#E5E5E5]"></div>

              <div className="flex justify-end mt-4">
                <button
                  type="submit"
                  className="bg-green-500 text-white px-6 py-2 rounded-md"
                >
                  {role ? 'Update Role Permission' : 'Save Role Permission'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RolePermissionModal;
