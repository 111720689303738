import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import debounce from 'lodash/debounce';
import { getAllCategories } from '../../../services/catalogService';
import { FaChevronDown, FaChevronLeft } from 'react-icons/fa';
import SearchBox from '../../../components/SearchBox';
import AppButton from '../../../components/AppButton/AppButton';
import Breadcrumb from '../../../components/BreadCrumb/BreadCrumb';
import { ChipBtn, IProduct } from '../../../types/interfaces';
import Chips from '../../../components/Chips/Chips';
// import GoodsSearch from './GoodsSearch';
import ServiceSearch from './ServiceSearch';
import VerifiedSuppliers from './VerifiedSuppliers';
import { useLocation, useNavigate } from 'react-router-dom';
import GoodsSearch from './GoodsSearch';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { getTags } from '../../../services/supplierService';
import { ProductType } from '../../../types/enums';

const SearchResults = () => {
  const [categories, setCategories] = useState<
    { categorId: string; categoryName: string }[]
  >([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState('bestMatches');
  const [tags, setTags] = useState<any[]>([]);

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search).get('query') || '';
  const { supplierId, setSupplierId } = useGlobalContext();
  const [searchParams, setSearchParams] = useState<any>({
    searchValue: query,
    productType: ProductType.Goods,
    startDate: new Date(0).toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0],
    categoryId: '',
    country: '',
  });

  useEffect(() => {
    if (query) {
      // Perform search with the query
      // console.log('Search query:', query);
    }
  }, [query]);

  const handleBestMatchChange = (event: any) => {
    setSelectedOption(event.target.value);
    // console.log("Selected option:", event.target.value);
  };

  const selectOptions = ['all-goods', 'all-services', 'verified-suppliers'];
  const suppliers = [
    {
      logo: 'images/ShellPix.svg',
      name: 'Green Earth Consultants Ltd',
      verified: 'Verified Supplier',
      rating: 4.3,
      reviews: 6,
      services: [
        'Gas Exploration',
        'Solar Installation',
        'Quality Assurance',
        'Maintenance',
      ],
      products: [
        { image: 'images/compressor.svg', price: '$12,000' },
        { image: 'images/legalServices.svg', price: '$12,000' },
        { image: 'images/compressor.svg', price: '$12,000' },
        { image: 'images/legalServices.svg', price: '$12,000' },
        { image: 'images/yellowPipe.svg', price: '$12,000' },
      ],
    },
    {
      logo: 'images/ShellPix.svg',
      name: 'Green Earth Consultants Ltd',
      verified: 'Verified Supplier',
      rating: 2,
      reviews: 6,
      services: [
        'Gas Exploration',
        'Solar Installation',
        'Quality Assurance',
        'Maintenance',
      ],
      products: [
        { image: 'images/compressor.svg', price: '$12,000' },
        { image: 'images/legalServices.svg', price: '$12,000' },
        { image: 'images/compressor.svg', price: '$12,000' },
        { image: 'images/legalServices.svg', price: '$12,000' },
        { image: 'images/yellowPipe.svg', price: '$12,000' },
      ],
    },
    {
      logo: 'images/ShellPix.svg',
      name: 'Green Earth Consultants Ltd',
      verified: 'Verified Supplier',
      rating: 5,
      reviews: 6,
      services: [
        'Gas Exploration',
        'Solar Installation',
        'Quality Assurance',
        'Maintenance',
      ],
      products: [
        { image: 'images/compressor.svg', price: '$12,000' },
        { image: 'images/legalServices.svg', price: '$12,000' },
        { image: 'images/compressor.svg', price: '$12,000' },
        { image: 'images/legalServices.svg', price: '$12,000' },
        { image: 'images/yellowPipe.svg', price: '$12,000' },
      ],
    },
  ];

  useEffect(() => {
    const fetchCategories = async () => {
      const params = { isParentCategory: false };
      const response = await getAllCategories(params);
      if (response?.isSuccess) {
        setCategories(response.data);
      }
    };

    const fetchSupplierTags = async () => {
      if (!supplierId) return;
      const response = await getTags(supplierId);
      if (response?.isSuccess) {
        setTags(response.data);
      }
    };

    fetchCategories();
    fetchSupplierTags();
  }, []);

  const formik = useFormik({
    initialValues: {
      select: 'all-goods',
      date: 'all',
      category: '',
      experienceLevel: '',
      clientLocation: '',
    },
    onSubmit: (values) => {
      let productType: ProductType;
      switch (values.select) {
        case 'all-goods':
          productType = ProductType.Goods;
          break;
        case 'all-services':
          productType = ProductType.Services;
          break;
        case 'verified-suppliers':
          productType = ProductType.Suppliers;
          break;
        default:
          productType = ProductType.Goods;
          break;
      }

      let startDate: Date = new Date();
      const endDate: Date = new Date();
      switch (values.date) {
        case 'today':
          startDate = new Date();
          break;
        case 'last-week':
          startDate.setDate(startDate.getDate() - 7);
          break;
        case 'this-week':
          // const day = startDate.getDay();
          startDate.setDate(startDate.getDate() - startDate.getDay());
          break;
        default:
          startDate = new Date(0);
          break;
      }

      const filteredValues = {
        searchValue: query,
        productType,
        startDate: startDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0],
        categoryId: values.category,
        country: values.clientLocation,
      };

      const filteredValuesWithoutEmpty = Object.fromEntries(
        Object.entries(filteredValues).filter(
          ([_, v]) =>
            v !== undefined &&
            v !== null &&
            v !== '' &&
            (!Array.isArray(v) || v.length > 0)
        )
      );

      setSearchParams(filteredValuesWithoutEmpty);
    },
  });

  const handleProductTypeChange = (event: any) => {
    formik.setFieldValue('select', event.target.value);
    let productType: ProductType;
    switch (event.target.value) {
      case 'all-goods':
        productType = ProductType.Goods;
        break;
      case 'all-services':
        productType = ProductType.Services;
        break;
      case 'verified-suppliers':
        productType = ProductType.Suppliers;
        break;
      default:
        productType = ProductType.Goods;
        break;
    }

    setSearchParams({
      ...searchParams,
      productType,
    });
  };

  const handleDateChange = (event: any) => {
    formik.setFieldValue('date', event.target.value);
    let startDate: Date = new Date();
    const endDate: Date = new Date();
    switch (event.target.value) {
      case 'today':
        startDate = new Date();
        break;
      case 'last-week':
        startDate.setDate(startDate.getDate() - 7);
        break;
      case 'this-week':
        // const day = startDate.getDay();
        startDate.setDate(startDate.getDate() - startDate.getDay());
        break;
      default:
        startDate = new Date(0);
        break;
    }

    setSearchParams({
      ...searchParams,
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0],
    });
  };

  const handleCategoryChange = (event: any) => {
    formik.setFieldValue('category', event.target.value);
    setSearchParams({
      ...searchParams,
      categoryId: event.target.value,
    });
  };

  const handleLocationChange = (event: any) => {
    formik.setFieldValue('clientLocation', event.target.value);
    setSearchParams({
      ...searchParams,
      country: event.target.value,
    });
  };

  const handleSearch = useCallback(
    debounce((value: string) => {
      const searchValue = value;
      setSearchParams({
        ...searchParams,
        searchValue,
      });
    }, 600),
    []
  );

  return (
    <>
      <div>
        <div className="w-full px-6 pb-2 bg-gray-50">
          <div className="flex w-full items-center md:flex-row gap-28 py-4  ">
            <div className="flex gap-8">
              <div className="flex items-center border rounded-xl border-gray-400 px-4 py-2 h-[3.3rem]">
                <button>
                  <FaChevronLeft
                    size={10}
                    onClick={() => navigate(`/catalogue`)}
                  />
                </button>
              </div>
              <div className="flex gap-4 border border-gray-400 rounded-full items-center px-4 py-2">
                <div>
                  <p>Refresh</p>
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/refresh.svg`}
                    alt={'refresh'}
                    className={`w-[12px] h-[12px] object-cover rounded-t-lg`}
                  />
                </div>
              </div>
            </div>
            {/* <div className=" w-[20%] flex items-center justify-center">
              <select
                className="w-full p-2 h-[3.3rem] text-lg border rounded-lg focus:outline-none bg-white"
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                <option value="">Categories</option>
                {categories.map((category) => (
                  <option key={category.categorId} value={category.categorId}>
                    {category.categoryName}
                  </option>
                ))}
              </select>
            </div> */}
            <div className="w-[82%] ">
              <SearchBox onSearch={handleSearch} defaultSearchValue={query} />
            </div>
            {/* <div>
              <AppButton
                title={'+ Add'}
                className="bg-primary py-3 px-6 border rounded-lg text-lg font-semibold"
                onClick={() => {
                  // console.log('add')
                }}
              />
            </div> */}
          </div>
          <div className="flex w-full">
            <div className="w-full md:w-[15%] space-y-10 pl-3">
              <div>
                <h2 className="font-semibold text-2xl mt-6 mb-6">Filter</h2>
                <form onSubmit={formik.handleSubmit} className="space-y-6">
                  {/* Select Filter */}
                  <div>
                    <p className="font-semibold text-lg mb-2 ">Select</p>
                    <div className="space-y-2 ">
                      {selectOptions.map((value) => (
                        <label
                          key={value}
                          className="flex items-center space-x-2"
                        >
                          <input
                            type="radio"
                            name="select"
                            value={value}
                            checked={formik.values.select === value}
                            onChange={handleProductTypeChange}
                            className="border-gray-300 focus:ring-blue-500 w-[10%] "
                          />
                          <span className="capitalize">
                            {value.replace('-', ' ')}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>

                  {/* Date Filter */}
                  <div>
                    <p className="font-semibold text-lg mb-2">Date</p>
                    <div className="space-y-2">
                      {['all', 'today', 'last-week', 'this-week'].map(
                        (value) => (
                          <label
                            key={value}
                            className="flex items-center space-x-2"
                          >
                            <input
                              type="radio"
                              name="date"
                              value={value}
                              checked={formik.values.date === value}
                              onChange={handleDateChange}
                              className="border-gray-300 focus:ring-blue-500 w-[10%]"
                            />
                            <span className="capitalize">
                              {value.replace('-', ' ')}
                            </span>
                          </label>
                        )
                      )}
                    </div>
                  </div>

                  {/* Category Filter */}
                  <div>
                    <label
                      htmlFor="category"
                      className="block font-semibold text-lg mb-2"
                    >
                      Category
                    </label>
                    <select
                      id="category"
                      name="category"
                      value={formik.values.category}
                      onChange={handleCategoryChange}
                      className="w-full border rounded p-2"
                    >
                      <option value="">Categories</option>
                      {categories.map((category) => (
                        <option
                          key={category.categorId}
                          value={category.categorId}
                        >
                          {category.categoryName}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Experience Level */}
                  {/* <div>
                    <p className="font-semibold text-lg mb-2">
                      Experience Level
                    </p>
                    <div className="space-y-2">
                      {['non-experienced', 'experienced'].map((value) => (
                        <label
                          key={value}
                          className="flex items-center space-x-2"
                        >
                          <input
                            type="radio"
                            name="experienceLevel"
                            value={value}
                            checked={formik.values.experienceLevel === value}
                            onChange={formik.handleChange}
                            className="border-gray-300 focus:ring-blue-500 w-[10%]"
                          />
                          <span className="capitalize">
                            {value.replace('-', ' ')}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div> */}

                  {/* Client Location */}
                  <div>
                    <label
                      htmlFor="clientLocation"
                      className="block font-semibold text-lg mb-2"
                    >
                      Client Location
                    </label>
                    <select
                      id="clientLocation"
                      name="clientLocation"
                      value={formik.values.clientLocation}
                      onChange={handleLocationChange}
                      className="w-full border rounded p-2"
                    >
                      <option value="">Set Location</option>
                      <option value="usa">USA</option>
                      <option value="europe">Europe</option>
                    </select>
                  </div>

                  {/* Submit Button */}
                  <button
                    type="submit"
                    className="w-full bg-gray-500 text-white py-2 rounded "
                  >
                    Apply Filters
                  </button>
                </form>
              </div>
            </div>
            <div className="px-8 w-[85%]">
              <Breadcrumb
                paths={[
                  { label: 'Catalogue', path: '/catalogue' },
                  { label: 'Search', path: '/search' },
                ]}
              />
              <div className="flex gap-3 mb-3">
                <label className="flex items-center space-x-2 font-semibold text-xl">
                  <input
                    type="radio"
                    name="sortOptions"
                    value="bestMatches"
                    checked={selectedOption === 'bestMatches'}
                    onChange={handleBestMatchChange}
                    className=" border-gray-300 focus:ring-green-500 w-[10%]"
                  />
                  <span>Best Matches</span>
                </label>
                <label className="flex items-center space-x-2 font-semibold text-xl">
                  <input
                    type="radio"
                    name="sortOptions"
                    value="mostRecent"
                    checked={selectedOption === 'mostRecent'}
                    onChange={handleBestMatchChange}
                    className=" border-gray-300 focus:ring-green-500 w-[10%]"
                  />
                  <span>Most Recent</span>
                </label>
              </div>
              <div className="w-full flex items-center gap-4 px-4 py-3 border rounded-xl bg-blue-100 ">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/outline/info.svg`}
                  alt={'info'}
                  className={`w-[16px] h-[16px] object-cover rounded-t-lg `}
                />
                <p className="text-blue-400 text-lg">
                  Search through the latest opportunities that align with your
                  skills and profile, ensuring they meet the criteria sought by
                  business.
                </p>
              </div>
              <div className="w-full flex px-4 py-3 mt-3 items-center bg-white border rounded-full mb-3">
                <div className="flex gap-4">
                  {tags.map((tag, index) => (
                    <Chips
                      key={index}
                      title={tag.tagName}
                      bgColor={'bg-green-50'}
                    />
                  ))}
                </div>
              </div>
              <div>
                {formik.values.select === 'all-goods' && (
                  <GoodsSearch searchProps={searchParams} />
                )}
                {formik.values.select === 'all-services' && (
                  <ServiceSearch searchProps={searchParams} />
                )}
                {formik.values.select === 'verified-suppliers' && (
                  <div className="space-y-6">
                    {suppliers.map((supplier, index) => (
                      <VerifiedSuppliers key={index} supplier={supplier} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchResults;
