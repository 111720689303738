import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import axiosInstance from '../../axiosInstance';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { getAProcurement } from '../../services/procurementService';
import { string } from 'yup';

interface CompanyInformation {
  CompanyId: string;
  BusinessName: string;
  DateOfEstablishment: string;
  SupplierCategory: string;
  StreetName: string;
  Region: string;
  City: string;
  Activate: boolean;
  Town: string;
  PhoneNumber: string;
  Website: string;
  Email: string;
  CategoryId: string;
  RegistrationNumber: string;
  CreatedDate: string;
  ModifiedDate: string;
  ParentCompanyId: string | null;
  BusinessType: string | null;
  Country: string | null;
  Status: number;
}

interface SupplierRequest {
  requestAccessToProcurementId: string;
  procurementProjectId: string;
  supplierId: string;
  createdByUserId: string;
  companyInformation: CompanyInformation;
  lastUpdated: string;
}

interface ApiResponse {
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  data: SupplierRequest[];
}

interface Procurement {
  procurementProjectId: string;
  projectTitle: string;
  projectDescription: string;
  prerequisites: string;
  status: number;
  lastUpdated: string;
  additionalInformation: string;
  tags: { tagName: string }[];
  industryId: string;
  isBiddingProject: boolean;
  supplierTypeToBid: string;
  projectGoodsAndServices: any[];
  projectDocuments: any[];
  referenceToWork: string;
  assignPointsToReferenceToWork: boolean;
  approvedDate: Date | null;
  biddingStartDate: Date;
  biddingEndDate: Date;
  bidRequirement: string;
}

interface SupplierRequestsViewProps {
  onClose: () => void;
}

const baseUrl = process.env.REACT_APP_BASE_URL;

const SupplierRequestsView: React.FC<SupplierRequestsViewProps> = ({
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState<
    'All Bids' | 'Request to View' | 'Requests for proposal (RFP)'
  >('Request to View');
  const [viewRequests, setViewRequests] = useState<SupplierRequest[]>([]);
  const [proposalRequests, setProposalRequests] = useState<SupplierRequest[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const { procurementId } = useParams<string>();
  const [procurement, setProcurements] = useState<Procurement>();

  const fetchRequests = async (viewsOrBidsRequests: number) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get<ApiResponse>(
        `${baseUrl}/procurementProjects/getSupplierAccessRequestsForProcurement`,
        {
          params: {
            procurementProjectId: procurementId,
            viewsOrBidsRequests,
            pageNumber: 1,
            pageSize: 1000,
          },
        }
      );
      if (viewsOrBidsRequests === 0) {
        setViewRequests(response.data.data);
      } else {
        setProposalRequests(response.data.data);
      }
    } catch (error) {
      toast.error('Failed to fetch requests');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRequests(0);
    fetchRequests(1);
  }, [procurementId]);

  const handleViewAccessRequest = async (
    request: SupplierRequest,
    approve: boolean
  ) => {
    try {
      const response = await axiosInstance.post(
        `${baseUrl}/procurementProjects/approveAccessToViewProcurement`,
        {
          requestAccessToProcurementId: request.requestAccessToProcurementId,
          procurementProjectId: request.procurementProjectId,
          approvalToViewStatus: approve ? 0 : 2,
        }
      );

      if (response.data.isSuccess) {
        toast.success(response.data.data.message);
        // Remove the request from the list
        setViewRequests((prev) =>
          prev.filter(
            (r) =>
              r.requestAccessToProcurementId !==
              request.requestAccessToProcurementId
          )
        );
      } else {
        toast.error('Failed to process request');
      }
    } catch (error) {
      toast.error('Failed to process request');
    }
  };

  const handleProposalRequest = async (
    request: SupplierRequest,
    approve: boolean
  ) => {
    try {
      const response = await axiosInstance.post(
        `${baseUrl}/procurementProjects/approveAccessToBidProcurement`,
        {
          requestAccessToProcurementId: request.requestAccessToProcurementId,
          procurementProjectId: request.procurementProjectId,
          approvalToBidStatus: approve ? 0 : 2,
          toApprove: approve,
        }
      );

      if (response.data.isSuccess) {
        toast.success(response.data.data.message);
        // Remove the request from the list
        setProposalRequests((prev) =>
          prev.filter(
            (r) =>
              r.requestAccessToProcurementId !==
              request.requestAccessToProcurementId
          )
        );
      } else {
        toast.error('Failed to process request');
      }
    } catch (error) {
      toast.error('Failed to process request');
    }
  };

  const loadProcurementProject = async () => {
    try {
      const response = await getAProcurement(procurementId);
      if (response.isSuccess) {
        setProcurements(response.data);
      }
    } catch {
      // console.error('Error loading procurement project:', error);
    }
  };

  useEffect(() => {
    loadProcurementProject(); // Call your method here
  }, []);

  const RequestCard = ({ request }: { request: SupplierRequest }) => {
    const businessName =
      request.companyInformation?.BusinessName || 'Unknown Company';
    const initial = businessName.charAt(0).toUpperCase();

    return (
      <div className="border mb-4 p-4 bg-white shadow-md rounded-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center">
              <span className="text-xl font-semibold text-gray-600">
                {initial}
              </span>
            </div>
            <div>
              <h3 className="font-semibold text-gray-900">{businessName}</h3>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-600">
                  {request.companyInformation?.SupplierCategory ||
                    'No category'}
                </span>
                <span className="bg-green-100 text-green-700 text-xs px-2 py-1 rounded">
                  Verified Supplier
                </span>
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            <button className="px-4 py-2 text-sm font-medium text-black border rounded hover:bg-gray-100">
              View Profile
            </button>
            <button
              onClick={() =>
                activeTab === 'Request to View'
                  ? handleViewAccessRequest(request, false)
                  : handleProposalRequest(request, false)
              }
              className="px-4 py-2 text-sm font-medium text-black border rounded hover:bg-gray-100"
            >
              Not Interested
            </button>
            <button
              onClick={() =>
                activeTab === 'Request to View'
                  ? handleViewAccessRequest(request, true)
                  : handleProposalRequest(request, true)
              }
              className="px-4 py-2 text-sm font-medium text-black bg-primary rounded hover:bg-primary"
            >
              {activeTab === 'Request to View'
                ? 'Allow Access'
                : 'Invite to Tender'}
            </button>
          </div>
        </div>
        <div className="mt-4 grid grid-cols-3 gap-4">
          {[1, 2, 3, 4, 5].map((item) => (
            <div
              key={item}
              className="bg-gray-100 flex items-center justify-center h-24 rounded-lg shadow-inner"
            >
              <span className="text-gray-500">$12,000</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="flex items-center justify-between p-6 bg-white shadow">
        <h2 className="text-3xl font-semibold">{procurement?.projectTitle}</h2>
        <button
          onClick={onClose}
          className="p-2 hover:bg-gray-100 rounded-full"
        >
          <X className="w-6 h-6" />
        </button>
      </div>

      <div className="flex border-b bg-white">
        {['All Bids', 'Request to View', 'Requests for proposal (RFP)'].map(
          (tab) => (
            <button
              key={tab}
              className={`px-6 py-4 font-medium text-lg ${
                activeTab === tab
                  ? 'border-b-2 border-primary text-black text-lg font-bold'
                  : 'text-gray-600'
              }`}
              onClick={() => setActiveTab(tab as typeof activeTab)}
            >
              {tab}
            </button>
          )
        )}
      </div>

      <div className="p-4">
        {loading ? (
          <div className="p-6 text-center text-gray-500">Loading...</div>
        ) : (
          <>
            {(activeTab === 'Request to View'
              ? viewRequests
              : proposalRequests
            ).map((request) => (
              <RequestCard
                key={request.requestAccessToProcurementId}
                request={request}
              />
            ))}
            {(activeTab === 'Request to View' ? viewRequests : proposalRequests)
              .length === 0 && (
              <div className="p-6 text-center text-gray-500">
                No requests found
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SupplierRequestsView;
