import React from 'react';

interface ProgressBarProps {
  steps: string[]; // Array of step names
  currentStep: number; // Current active step (1-indexed)
  activeColor?: string; // Color for active steps
  inactiveColor?: string; // Color for inactive steps
}

const ProgressBarNew: React.FC<ProgressBarProps> = ({
  steps,
  currentStep,
  activeColor = 'bg-green-500', // Default active color
  inactiveColor = 'bg-gray-300', // Default inactive color
}) => {
  return (
    <div className="flex items-center justify-between">
      {steps.map((step, index) => {
        const isActive = index + 1 <= currentStep;
        const isLast = index === steps.length - 1;

        return (
          <div key={index} className="flex items-center ">
            <div className="relative flex flex-col items-center">
              {/* Step Label */}
              <span
                className={`mt-2 text-sm ${
                  isActive ? 'text-green-600' : 'text-gray-500'
                }`}
              >
                {step}
              </span>
              {/* Step Circle */}
              <div
                className={`w-8 h-8 flex items-center justify-center rounded-full font-bold ${
                  isActive
                    ? `${activeColor} text-white`
                    : `${inactiveColor} text-gray-500`
                }`}
                aria-current={isActive ? 'step' : undefined}
              >
                {index + 1}
              </div>
            </div>

            {/* Progress Line */}
            {!isLast && (
              <div
                className={`h-1 mx-2 flex-1 ${isActive ? activeColor : inactiveColor}`}
                style={{ minWidth: `${100 / (steps.length - 1)}%` }}
              ></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ProgressBarNew;
