import React, { useState, useEffect } from 'react';
import CustomTable, {
  Column,
  IPageRequest,
} from '../../../components/CustomTable';
import { useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import Delete from '../../../components/Delete/Delete';
import Breadcrumb from '../../../components/BreadCrumb/BreadCrumb';
import RolePermissionModal, { Role } from './RolePermissionModal';
import { deleteRole, getRoles } from '../../../services/AccountServices';
import { toast } from 'react-toastify';

export interface RoleItem {
  role: string;
  roleId: string;
}

const breadcrumbPaths = [
  { label: 'Dashboard', path: '/dashboard' },
  { label: 'Settings', path: '/dashboard/' },
  { label: 'Roles Permission', path: '/settings/roles' },
];

const RolePermission = () => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [pageCount, setPageCount] = useState(1);
  const [roles, setRoles] = useState<RoleItem[]>([]);
  const [selectedRole, setSelectedRole] = useState<RoleItem | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRolePermissionModalOpen, setIsRolePermissionModalOpen] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for controlling the modal
  const [isEditMode, setIsEditMode] = useState(false);

  const navigate = useNavigate();

  const pageRequest: IPageRequest = {
    pageNumber: currentPage + 1,
    pageSize: itemsPerPage,
  };

  // Fetch roles data from the API
  const fetchRolesData = async () => {
    try {
      const response = await getRoles(pageRequest);
      if (response) {
        setRoles(response);
        if (response?.totalRecords) {
          setPageCount(Math.ceil(response.totalRecords / itemsPerPage));
        }
      }
    } catch (error) {
      // console.error('Error fetching roles:', error);
    }
  };

  useEffect(() => {
    fetchRolesData();
  }, [currentPage, itemsPerPage]);

  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    // Implement additional search logic if needed
  };

  const handleDelete = (item: RoleItem) => {
    setSelectedRole(item);
    setIsDeleteModalOpen(true);
  };

  const deleteSelectedRole = async (selectedRole?: RoleItem | null) => {
    if (selectedRole) {
      const response = await deleteRole(selectedRole.roleId);
      if (response?.isSuccess) {
        toast.success(response?.data?.message);
        fetchRolesData();
      } else {
        toast.error(response?.errorMessage);
      }
      setIsDeleteModalOpen(false);
      setSelectedRole(null);
    }
  };
  const handleEditMenu = (item: RoleItem) => {
    setSelectedRole(item);
    setIsEditMode(true);
    setIsModalOpen(true);
    setIsRolePermissionModalOpen(true);
  };

  const handleOpenRolePermissionModal = () => {
    setIsRolePermissionModalOpen(true);
  };

  const handleCloseRolePermissionModal = () => {
    setSelectedRole(null);
    setIsRolePermissionModalOpen(false);
  };

  const columns: Column[] = [
    {
      title: 'ID',
      dataKey: 'roleId',
      type: 'string',
    },
    {
      title: 'Role Name',
      dataKey: 'role',
      type: 'string',
    },
    {
      title: 'Description',
      dataKey: 'description',
      type: 'string',
    },
    {
      title: 'Actions',
      dataKey: 'actions',
      type: 'action',
      actions: [
        {
          label: 'Edit',
          className: 'text-green-500',
          action: handleEditMenu,
          status: true,
        },
        {
          label: 'Delete',
          className: 'text-red-500',
          action: handleDelete,
          status: true,
        },
      ],
    },
  ];

  return (
    <div className="px-[32px] py-6">
      <h1 className="text-4xl font-bold mb-4">Role Permission</h1>
      <Breadcrumb paths={breadcrumbPaths} />

      <CustomTable
        data={roles}
        columns={columns}
        pageCount={pageCount}
        showTitle={false}
        addButtonText="+ Add"
        showPagination={true}
        showSearch={true}
        onSearch={handleSearch}
        showAddButton={true}
        title="Add Roles Permission"
        onAddButtonClick={handleOpenRolePermissionModal}
        onPageChange={(selectedPage) => handlePageChange(selectedPage)}
      />

      {isDeleteModalOpen && (
        <Delete
          title="Are you sure?"
          desc="This action is permanent, all data would be lost."
          onDelete={() => deleteSelectedRole(selectedRole)}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}

      {isRolePermissionModalOpen && (
        <RolePermissionModal
          onClose={handleCloseRolePermissionModal}
          onSubmit={() => {
            fetchRolesData();
            setSelectedRole(null);
          }}
          isEditable={true}
          role={selectedRole}
        />
      )}
    </div>
  );
};

export default RolePermission;
