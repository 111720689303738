import React, { useState } from 'react';

interface ExpressionOfInterestProps {
  formData: {
    expressionOfInterests: {
      documentType: string;
      assignPoints: boolean;
      points: number;
      type: number;
    }[];
    referenceToWork: string;
    assignPointsToReferenceToWork: boolean;
  };
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}

const Modal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-xl">
            &times;
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

const ExpressionOfInterest: React.FC<ExpressionOfInterestProps> = ({
  formData,
  setFormData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newDocument, setNewDocument] = useState({
    documentType: '',
    assignPoints: false,
    points: 0,
    type: 0,
  });

  const addDocument = () => {
    setFormData((prev: { expressionOfInterests: any }) => ({
      ...prev,
      expressionOfInterests: Array.isArray(prev.expressionOfInterests)
        ? [...prev.expressionOfInterests, newDocument]
        : [newDocument],
    }));
    setNewDocument({
      documentType: '',
      assignPoints: false,
      points: 0,
      type: 0,
    });

    setIsModalOpen(false);
  };

  const removeDocument = (index: number) => {
    setFormData((prev: { expressionOfInterests: any[] }) => ({
      ...prev,
      expressionOfInterests: prev.expressionOfInterests.filter(
        (_: any, i: number) => i !== index
      ),
    }));
  };

  return (
    <div className="space-y-6 p-6 bg-white rounded-lg shadow">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold">
          Expression of Interest Criteria
        </h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="px-4 py-2 bg-white border border-black text-black rounded-md"
        >
          + Add
        </button>
      </div>

      <div>
        <h3 className="text-xl font-medium mb-4">Request Documents</h3>
        <table className="w-full">
          <thead>
            <tr>
              <th className="text-left">Document Name</th>
              <th className="text-left">Points Assigned</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {formData.expressionOfInterests &&
              formData.expressionOfInterests.map((doc, index) => (
                <tr key={index}>
                  <td>{doc.documentType}</td>
                  <td>{doc.points} Points</td>
                  <td>
                    <button
                      onClick={() => removeDocument(index)}
                      className="text-red-600 hover:underline"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="w-[600px] mx-auto p-6 bg-white rounded-md shadow-xl">
          {' '}
          {/* Increased width and centralized the modal */}
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-semibold">Request documents</h3>
            <button
              onClick={() => setIsModalOpen(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          </div>
          <div className="space-y-6">
            {' '}
            {/* Added more spacing for better field separation */}
            <div>
              <label
                htmlFor="documentType"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Type of document
              </label>
              <input
                id="documentType"
                value={newDocument.documentType}
                onChange={(e) =>
                  setNewDocument({
                    ...newDocument,
                    documentType: e.target.value,
                  })
                }
                placeholder="Licenses and Permits"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="assignPointsModal"
                  checked={newDocument.assignPoints}
                  onChange={(e) =>
                    setNewDocument({
                      ...newDocument,
                      assignPoints: e.target.checked,
                    })
                  }
                  className="w-5 h-5 text-yellow-400 border-gray-300 rounded focus:ring-yellow-400"
                />
                <label
                  htmlFor="assignPointsModal"
                  className="ml-2 text-sm text-gray-700"
                >
                  Assign points to this requirement
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="number"
                  value={newDocument.points}
                  onChange={(e) =>
                    setNewDocument({
                      ...newDocument,
                      points: parseInt(e.target.value),
                    })
                  }
                  className="w-20 p-2 border border-gray-300 rounded-lg text-right" /* Increased width of the input field */
                />
                <span className="ml-2 text-gray-500">/100</span>
              </div>
            </div>
            <div className="flex justify-end items-start">
              <button
                onClick={addDocument}
                className="w-1/5 h-12 p-2 bg-primary mt-7 text-black rounded-md"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ExpressionOfInterest;
