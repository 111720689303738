export enum OnboardingStatus {
  New,
  Processing,
  Rejected,
  Approved,
  Draft,
}

export enum WorkflowType {
  Supplier = 0,
  Bid = 1,
  Procurement = 2,
  Contract = 3,
  Project = 4,
}

export enum ProductType {
  Goods = 0,
  Services = 1,
  Suppliers = 2,
}

export enum SortEnum {
  Newest = 0,
  PriceLowHigh = 1,
  PriceHighLow = 2,
  Ascending = 3,
  Descending = 4,
}

export enum ProcurementProjectStatus {
  Approved = 0,
  Reviewing = 1,
  Denied = 2,
  Draft = 3,
}
