import React, { useEffect, useState } from 'react';
import ModalContactForm from './AddPrimaryContact';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import Delete from '../../../../components/Delete/Delete';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTable, {
  Column,
  IPageRequest,
} from '../../../../components/CustomTable';
import {
  deleteContact,
  getContacts,
} from '../../../../services/supplierService';
import { useTranslation } from 'react-i18next';

interface PrimaryProps {
  isEditable: boolean;
  checkListLength: (length: number) => void;
}

export type PrimaryContact = {
  fullName: string;
  email: string;
  nationality: string;
  phoneNumber: string;
  contactId: string;
  designation: string;
};

export const PrimaryContactTable: React.FC<PrimaryProps> = ({
  isEditable,
  checkListLength,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [primaryContact, setPrimaryContact] = useState<PrimaryContact[]>([]);
  const [modalContact, setModalContact] = useState<PrimaryContact | null>(null);
  const { supplierId } = useGlobalContext();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(25); // Items per page for pagination
  const [pageCount, setPageCount] = useState(1);

  const location = useLocation();
  const isSupplier = location.state?.isSupplier;
  const urlParams = new URLSearchParams(window.location.search);
  const companyId = urlParams.get('supplierId');

  const pageRequest: IPageRequest = {
    pageNumber: currentPage + 1,
    pageSize: itemsPerPage,
  };

  const columns: Column[] = [
    {
      title: t('fullName', 'Full Name'),
      dataKey: 'fullName',
      type: 'string',
    },
    { title: t('email', 'Email'), dataKey: 'email', type: 'string' },
    {
      title: t('nationality', 'Nationality'),
      dataKey: 'nationality',
      type: 'string',
    },
    {
      title: t('phoneNumber', 'Phone Number'),
      dataKey: 'phoneNumber',
      type: 'string',
    },
    {
      title: t('actions', 'Actions'),
      dataKey: 'actions', // This key is used but not directly needed for rendering
      type: 'action',
      actions: [
        {
          label: t('view', 'View'),
          className: '',
          action: (item: PrimaryContact) => handleView(item),
          status: !isEditable,
        },
        {
          label: t('edit', 'Edit'),
          className: 'text-green-500',
          action: (item: PrimaryContact) => handleView(item),
          status: isEditable,
        },
        {
          label: t('delete', 'Delete'),
          className: 'text-red-500',
          action: (item: PrimaryContact) => deleteContactModal(item),
          status: isEditable,
        },
      ],
    },
  ];

  const deleteContactModal = (item: PrimaryContact) => {
    setIsDeleteModalOpen(true);
    setModalContact(item);
  };

  const handleView = (item: PrimaryContact) => {
    // Open the modal with the data from the clicked row
    setModalContact(item);
    setIsModalOpen(true);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    // You can implement a search filter here if needed
  };

  const deletePrimaryContact = async () => {
    if (!modalContact) return;
    setIsDeleteModalOpen(false);
    const response = await deleteContact(modalContact.contactId);
    if (response?.isSuccess) {
      toast.success(response?.data.message);
      fetchContactsData();
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    setModalContact(null); // Clear the form for adding a new contact
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContact(null);
    fetchContactsData();
  };

  const fetchContactsData = async () => {
    const fetchedId = isSupplier ? supplierId : companyId;
    if (!fetchedId) return;
    const response = await getContacts(fetchedId, pageRequest);
    if (response) {
      setPrimaryContact(response.data);
      checkListLength(response.data.length);
      if (response?.totalRecords) {
        setPageCount(Math.ceil(response.totalRecords / itemsPerPage));
      }
    }
  };

  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    fetchContactsData();
  }, [currentPage, itemsPerPage]);

  return (
    <div className="p-4">
      <div>
        <CustomTable
          data={primaryContact}
          columns={columns}
          pageCount={pageCount} // Pass the total page count
          showPagination={true} // Control whether to show pagination
          showSearch={true} // Control whether to show the search box
          onSearch={handleSearch} // Pass the search handler
          showAddButton={isEditable} // Conditionally show the Add button based on isEditable
          addButtonText={t('add', '+ Add')}
          title=""
          showTitle={false} // Control whether to show the title
          onAddButtonClick={openModal} // Open modal for adding a new contact
          onPageChange={(selectedPage) => handlePageChange(selectedPage)} // Handle page change
        />
      </div>

      {isModalOpen && (
        <ModalContactForm
          isOpen={isModalOpen}
          onClose={closeModal}
          initialValues={modalContact} // Set form values for editing or empty for new contact
          isEditable={isEditable}
        />
      )}

      {isDeleteModalOpen && (
        <Delete
          title={t('areYouSure', 'Are you sure?')}
          desc={t(
            'deleteConfirmation',
            'This action is permanent, all data would be lost.'
          )}
          onDelete={deletePrimaryContact}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}
    </div>
  );
};
