import React, { useEffect, useState } from 'react';
import CustomTable, {
  Column,
  IPageRequest,
} from '../../../components/CustomTable';

import { useNavigate } from 'react-router-dom';
import MyCatalogueDeleteModal from './MyCatalogueDeleteModal';
import OrderCompletedModal from '../../../components/OrderCompletedModal/OrderCompletedModal';

const MyCatalogue: React.FC = () => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [catalogueItems, setCatalogueItems] = useState<any[]>([]);
  const [showFeature, setShowFeature] = useState<boolean>(true);

  const pageRequest: IPageRequest = {
    pageNumber: currentPage + 1,
    pageSize: itemsPerPage,
  };

  const navigate = useNavigate();

  const columns: Column[] = [
    { title: 'Product/Service', dataKey: 'productName', type: 'string' },
    { title: 'Description', dataKey: 'description', type: 'string' },
    { title: 'Category', dataKey: 'category', type: 'string' },
    { title: 'Sub-category', dataKey: 'subCategory', type: 'string' },
    { title: 'Location', dataKey: 'location', type: 'string' },
    { title: 'Status', dataKey: 'status', type: 'status' },
  ];

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };
  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };
  const handleConfirmClick = () => {
    setIsDeleteModal(false);
    setShowConfirmModal(true);
  };

  if (!showFeature) {
    return <div className="text-center text-xl font-semibold">Coming soon</div>;
  }

  return (
    <div>
      <CustomTable
        data={catalogueItems}
        columns={columns}
        pageCount={pageCount} // Total page count for pagination
        showPagination={true} // Show pagination control
        showSearch={true} // Show search bar
        onSearch={handleSearch} // Search function
        showAddButton={false} // Add button (set to false here)
        showTitle={false}
        onPageChange={(selectedPage) => handlePageChange(selectedPage)}
      />
      {/* <button
        onClick={() => setIsDeleteModal(true)}
        className="py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700"
      >
        Open Modal
      </button> */}
      <MyCatalogueDeleteModal
        isOpen={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        handleConfirmClick={handleConfirmClick}
      />

      {showConfirmModal && (
        <OrderCompletedModal
          orderNumber={''}
          orderText="Product/service removed completely"
          orderStatus="Deleted"
          appBtnOkay=""
          appBtnView=""
          onView={() => {}}
          onBack={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default MyCatalogue;
