import React from 'react';
import { MyCards } from '../../types/interfaces';
import { FaMapMarkerAlt } from 'react-icons/fa';
const Cards: React.FC<MyCards> = ({
  imagePath,
  title,
  description,
  currency = 'USD',
  showDefaultImage = true,
  price,
  ratings,
  ratingsCount,
  showRatings,
  showPrice,
  showLocation,
  showDescription,
  location,
  styleConfig = {},
  onClick,
}) => {
  return (
    <div
      className={`bg-white border border-gray-300 rounded-lg p-4 $${styleConfig.container || ''}`}
      onClick={onClick}
    >
      {showDefaultImage && (
        <img
          src={`${process.env.PUBLIC_URL}/assets/${imagePath}`}
          alt={title}
          className={`${styleConfig.image || ''} w-full h-48 object-cover rounded-t-lg`}
        />
      )}
      {!showDefaultImage && (
        <img
          src={imagePath}
          alt={title}
          className={`${styleConfig.image || ''} w-full h-48 object-cover rounded-t-lg`}
        />
      )}

      <div className="mt-4">
        <h3 className={`${styleConfig.title || ''}text-lg font-semibold`}>
          {title}
        </h3>
        {/* Description */}
        {showDescription && description && (
          <p className="text-gray-600 mt-2">{description}</p>
        )}

        {showRatings && ratings && (
          <div className="flex items-center mt-2">
            <div className="flex text-yellow-500">
              {Array.from({ length: 5 }).map((_, index) => {
                const isFullStar = index < Math.floor(Number(ratings));
                const isHalfStar =
                  index === Math.floor(Number(ratings)) &&
                  Number(ratings) % 1 !== 0;

                return (
                  <span
                    key={index}
                    className={
                      isFullStar
                        ? 'text-yellow-500'
                        : isHalfStar
                          ? 'text-yellow-300'
                          : 'text-gray-400'
                    }
                  >
                    &#9733;
                  </span>
                );
              })}
            </div>
            {/* Show ratings count if available */}
            {ratingsCount && (
              <span className="text-gray-500 text-sm ml-2">
                ({ratingsCount})
              </span>
            )}
          </div>
        )}

        <div className="flex justify-between">
          {/* Price */}
          {showPrice && price && (
            <div className="text-xl font-bold text-green-400 mt-4">
              {currency == 'USD' ? '$' : ''} {price}
            </div>
          )}

          {/* Location */}
          {showLocation && location && (
            <div className="flex items-center text-gray-500 text-sm mt-2">
              <FaMapMarkerAlt />
              <span className="ml-1">{location}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cards;
