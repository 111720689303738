import React, { useEffect, useState } from 'react';
import { AddShareholderModal } from './AddShareholderModal';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import {
  deleteShareholder,
  getShareholders,
} from '../../../../services/supplierService';
import Delete from '../../../../components/Delete/Delete';
import { toast } from 'react-toastify';
import CustomTable, { Column } from '../../../../components/CustomTable';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ShareHolderProps {
  isEditable: boolean;
  checkListLength: (length: number) => void;
}

export interface ShareholderInterface {
  shareholderId: string;
  fullName: string;
  maritalStatus: string;
  tin: string;
  identificationNumber: string;
  identificationType: string;
  percentageOwned: number;
}

export const ShareholdersTable: React.FC<ShareHolderProps> = ({
  isEditable,
  checkListLength,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedShareHolder, setSelectedShareHolder] =
    useState<ShareholderInterface | null>(null);
  const [shareHolders, setShareHolders] = useState<ShareholderInterface[]>([]);
  const { supplierId } = useGlobalContext();
  const [isViewMode, setIsViewMode] = useState(false); // New state to handle view-only mode
  const [currentPage, setCurrentPage] = useState(10);
  const [itemsPerPage] = useState(10); // Items per page for pagination

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = shareHolders.slice(indexOfFirstItem, indexOfLastItem);
  const pageCount = Math.ceil(shareHolders.length / itemsPerPage);

  const location = useLocation();
  const isSupplier = location.state?.isSupplier;
  const urlParams = new URLSearchParams(window.location.search);
  const companyId = urlParams.get('supplierId');

  const deleteSelectedShareholder = async () => {
    if (!selectedShareHolder) return;
    const { shareholderId } = selectedShareHolder;
    const response: any = await deleteShareholder(shareholderId);
    if (response.isSuccess) {
      toast.success(
        t('shareholderDeletedSuccessfully', 'Shareholder deleted successfully')
      );
      setIsDeleteModalOpen(false);
      fetchData();
    }
  };

  const openModal = (shareholder?: ShareholderInterface, viewMode = false) => {
    setIsModalOpen(true);
    setSelectedShareHolder(shareholder || null); // Set selected shareholder for viewing/editing
    setIsViewMode(viewMode); // If it's a view, we set viewMode to true
  };

  const deleteModal = (shareholder: ShareholderInterface) => {
    setSelectedShareHolder(shareholder);
    setIsDeleteModalOpen(true); // Open delete confirmation modal
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedShareHolder(null);
    fetchData();
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedShareHolder(null);
  };

  const columns: Column[] = [
    {
      title: t('shareholderName', 'Shareholder Name'),
      dataKey: 'fullName',
      type: 'string',
    },
    {
      title: t('maritalStatus', 'Marital Status'),
      dataKey: 'maritalStatus',
      type: 'string',
    },
    {
      title: t('individualTIN', 'Individual TIN'),
      dataKey: 'tin',
      type: 'string',
    },
    {
      title: t('idNumber', 'ID Number'),
      dataKey: 'identificationNumber',
      type: 'string',
    },
    {
      title: t('typeOfID', 'Type of ID'),
      dataKey: 'identificationType',
      type: 'string',
    },
    {
      title: t('percentageOwned', '$-% / Quota/Shares'),
      dataKey: 'percentageOwned',
      type: 'number',
    },
    {
      title: t('actions', 'Actions'),
      dataKey: 'actions',
      type: 'action',
      actions: [
        {
          label: t('view', 'View'),
          className: '',
          action: (item: ShareholderInterface) => openModal(item), // Open in edit mode
          status: !isEditable, // Enable edit action if editable
        },
        {
          label: t('edit', 'Edit'),
          className: 'text-green-500',
          action: (item: ShareholderInterface) => openModal(item), // Open in edit mode
          status: isEditable, // Enable edit action if editable
        },
        {
          label: t('delete', 'Delete'),
          className: 'text-red-500',
          action: (item: ShareholderInterface) => deleteModal(item),
          status: isEditable, // Enable delete action if editable
        },
      ],
    },
  ];

  const fetchData = async () => {
    const fetchedId = isSupplier ? supplierId : companyId;
    if (!fetchedId) return;
    const response = await getShareholders(fetchedId, {
      pageNumber: 1,
      pageSize: 10,
    });
    if (response) {
      setShareHolders(response?.data);
      checkListLength(response?.data?.length);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-4">
      <CustomTable
        data={shareHolders}
        columns={columns}
        pageCount={pageCount}
        showPagination={true}
        showSearch={true}
        showTitle={false}
        addButtonText={t('add', '+ Add')}
        showAddButton={isEditable} // Conditionally show the Add button based on isEditable
        onAddButtonClick={() => openModal()} // Open modal for adding a new shareholder
      />

      {isModalOpen && (
        <AddShareholderModal
          isOpen={isModalOpen}
          onClose={closeModal}
          initialValues={selectedShareHolder} // Pass selected shareholder for editing or viewing
          isEditable={isEditable} // Editable if true and not in view mode
        />
      )}

      {isDeleteModalOpen && (
        <Delete
          title={t('areYouSure', 'Are you sure?')}
          desc={t(
            'deleteConfirmation',
            'This action is permanent, all data would be lost.'
          )}
          onDelete={deleteSelectedShareholder}
          onCancel={closeDeleteModal}
        />
      )}
    </div>
  );
};
