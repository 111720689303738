import React, { useEffect, useState } from 'react';
import ApplicationProgress from './components/ApplicationProgressBar';
import { ModuleCard } from './components/CardList';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getSupplierProgress } from '../../../services/supplierService';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { toast } from 'react-toastify';
import { updateOnboardingStatus } from '../../../utils/helper';
import { OnboardingStatus } from '../../../types/enums';
// import { OnboardingStatus } from '../../../types/enums';

const SupplierApplication: React.FC = () => {
  const [progress, setProgress] = useState<number>(20);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { supplierId } = useGlobalContext();
  const [onboardingStatus, setOnboardingStatus] =
    useState<OnboardingStatus | null>(null);

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        if (!supplierId) return;
        const response = await getSupplierProgress(supplierId);
        if (response?.isSuccess) {
          let percentage = 0;
          if (response?.data.totalStage > 0) {
            percentage =
              (response?.data.currentStage / response?.data.totalStage) * 100;
          }
          const status = updateOnboardingStatus(response?.data.status);
          setOnboardingStatus(status);

          if (status === OnboardingStatus.Approved) {
            setProgress(100);
          } else if (
            percentage === 100 &&
            response?.data.status !== OnboardingStatus.Approved
          ) {
            setProgress(80);
          } else {
            setProgress(percentage);
          }
        } else {
          // toast.error(response.errorMessage);
        }
      } catch (error: any) {
        toast.error(error);
      }
    };

    fetchProgress();
  }, []);

  return (
    <div className="max-w-[1440px] mx-auto p-6">
      <h1 className="text-3xl font-bold my-4">
        {t('supplierModule', 'Supplier Module')}
      </h1>
      <ApplicationProgress percentage={progress} />
      <div className="mt-8">
        {onboardingStatus === OnboardingStatus.Approved && (
          <div className="w-full p-8 bg-white rounded-lg border border-[#E5E5E5]">
            <div className="flex justify-between items-center mb-2">
              <div>
                <h3 className="text-lg font-semibold">
                  {t('applicationCompleted', 'Application completed')}
                </h3>
                <p className="text-gray-500">
                  {t('youAreVerified', 'You are verified')}
                </p>
              </div>
              <button className="bg-[#F5F5F5] text-[#C6C6C6] font-semibold w-24 h-12 p-2 rounded-lg">
                {t('completed', 'Completed')}
              </button>
            </div>
          </div>
        )}
        {(onboardingStatus === OnboardingStatus.Draft ||
          onboardingStatus === null) && (
          <div className="w-full p-8 bg-white rounded-lg border border-[#E5E5E5]">
            <div className="flex justify-between items-center mb-2">
              <div>
                <h3 className="text-lg font-semibold">
                  {t('applicationSavedProgress', 'Application Saved Progress')}
                </h3>
                <p className="text-gray-500">
                  {t(
                    'supplierApplicationVerificationProgress',
                    'This shows your supplier application verification progress'
                  )}
                </p>
              </div>
              <button
                className="bg-primary text-black font-semibold w-24 h-12 p-2 rounded-lg"
                onClick={() =>
                  navigate('/supplier-registration', {
                    state: { isSupplier: true },
                  })
                }
              >
                {t('update', 'Update')}
              </button>
            </div>
          </div>
        )}
        {onboardingStatus === OnboardingStatus.Rejected && (
          <div className="mt-8 w-full p-8 bg-[#EDFCFF] rounded-lg border border-[#118AA2]">
            <div className="flex justify-between items-center mb-2">
              <div className="flex gap-4">
                <div className="flex items-start">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/outline/Info.svg`}
                    alt={t('info', 'info')}
                  />
                </div>
                <div>
                  <h3 className="text-lg font-semibold">
                    {t('requestedDocuments', 'Requested Documents')}
                  </h3>
                  <p className="text-gray-500">
                    {t(
                      'additionalDocumentsRequired',
                      'These shows additional documents you need to provide to complete your verification progress'
                    )}
                  </p>
                  <ol className="list-decimal list-inside mt-4">
                    <li>Import and Export Licenses</li>
                    <li>Letter of Authorisation from a Licensed body</li>
                  </ol>
                </div>
              </div>
              <button
                className="bg-[#118AA211] text-black font-semibold w-24 h-12 p-2 rounded-lg"
                onClick={() =>
                  navigate('/supplier-registration', {
                    state: { isSupplier: true },
                  })
                }
              >
                {t('update', 'Update')}
              </button>
            </div>
          </div>
        )}

        {onboardingStatus !== OnboardingStatus.Approved && (
          <div className="mt-8 w-full p-8 bg-white rounded-lg border border-[#E5E5E5]">
            <div>
              <h3 className="text-xl font-semibold mb-2">
                {t('relevantTrainings', 'Relevant Trainings')}
              </h3>
              <p className="text-gray-500 mb-4">
                {t(
                  'trainingsAssistOnboarding',
                  'These trainings will assist you in your onboarding process.'
                )}
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {ModuleCard.map((card) => (
                  <div
                    key={card.id}
                    className="p-4 bg-white rounded-lg flex flex-col items-center cursor-pointer justify-between hover:shadow-md transition-shadow duration-300"
                  >
                    <img
                      src={card.image}
                      alt={card.title}
                      className="w-full h-40 object-cover mb-4 rounded-md"
                    />
                    <h4 className="text-base font-semibold flex w-full justify-start mb-2">
                      {t(card.title, card.title)}
                    </h4>
                    <p className="text-sm text-gray-500 flex w-full justify start">
                      {t(card.description, card.description)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SupplierApplication;
