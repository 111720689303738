import axiosInstance from '../axiosInstance';
import { Endpoint } from './endpoint/endpoint';

export const uploadImage = async (data: any) => {
  const url = Endpoint.edms.imageUpload;
  try {
    const response = await axiosInstance.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const uploadDocument = async (data: any) => {
  const url = Endpoint.edms.uploadDocument;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};
