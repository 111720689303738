import React from 'react';
import AppButton from '../../../components/AppButton/AppButton';

interface SupplierData {
  logo: string;
  name: string;
  verified: string;
  rating: number;
  reviews: number;
  services: string[];
  products: {
    image: string;
    price: string;
  }[];
}
type VerifiedSuppliersProps = {
  supplier: SupplierData;
};
const VerifiedSuppliers: React.FC<VerifiedSuppliersProps> = ({ supplier }) => {
  return (
    <>
      <div className="w-full bg-white py-4 px-3 rounded-lg">
        <div className="flex justify-between mb-6 items-center">
          <div className="flex gap-3">
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/assets/${supplier.logo}`}
                alt={'Shell Pix'}
                className={`w-full h-16 object-cover rounded-t-lg`}
              />
            </div>
            <div>
              <h2 className="text-xl text-gray-700 underline decoration-gray-700">
                {supplier.name}
              </h2>
              <p className="w-[50%] bg-green-500 text-center border rounded-full  mt-1 text-white">
                {supplier.verified}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-5">
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/heart.svg`}
                alt={'love'}
                className={`w-full h-6 object-cover rounded-t-lg`}
              />
            </div>
            <AppButton
              title={'Chat Now'}
              onClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              className="px-3 py-2 border border-gray-300 rounded-lg font-semibold"
            />
            <AppButton
              title={'Contact Supplier'}
              onClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              className="px-3 py-2 border border-gray-300 rounded-lg font-semibold"
            />
            <AppButton
              title={'View Profile'}
              onClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              className="px-3 py-2 bg-primary rounded-lg font-semibold"
            />
          </div>
        </div>
        <hr />
        <div className="flex justify-between mt-2 py-2">
          <div>
            <p className="text-gray-500 mt-2">Ratings & Reviews</p>
            <div className="flex items-center mt-2 text-lg">
              <div className="flex text-yellow-500">
                {Array.from({ length: 5 }).map((_, index) => (
                  <span
                    key={index}
                    className={
                      index < supplier.rating
                        ? 'text-yellow-500' // Full stars
                        : index === 3
                          ? 'text-yellow-300' // Half star
                          : 'text-gray-400' // Empty star
                    }
                  >
                    &#9733;
                  </span>
                ))}
              </div>
              <span className="text-gray-800 ml-4">
                ({supplier.reviews} Reviews)
              </span>
            </div>
            <p className="font-semibold text-xl mt-4 mb-2 text-gray-700">
              Services
            </p>
            <ul className="list-disc ml-8 text-lg">
              {supplier.services.map((service, index) => (
                <li key={index} className="text-gray-700">
                  {service}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex gap-6">
            <div className="relative">
              <img
                src={`${process.env.PUBLIC_URL}/assets/${supplier.products[0].image}`}
                alt={'compressor'}
                className={`w-full h-[200px]  object-cover rounded-t-lg`}
              />
              <span className="w-full absolute bottom-5 bg-gray-400 text-lg opacity-80 font-bold text-center">
                {supplier.products[0].price}
              </span>
            </div>
            <div className="grid grid-cols-2 gap-3">
              <div className="relative">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/${supplier.products[1].image}`}
                  alt={'legalServices'}
                  className={`w-full h-[95px] object-cover rounded-t-lg`}
                />
                <span className="w-full absolute bottom-5 bg-gray-400 text-lg opacity-80 font-bold text-center">
                  {supplier.products[1].price}
                </span>
              </div>
              <div className="relative">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/${supplier.products[2].image}`}
                  alt={'compressor'}
                  className={`w-full h-[95px] object-cover rounded-t-lg`}
                />
                <span className="w-full absolute bottom-5 bg-gray-400 text-lg opacity-80 font-bold text-center">
                  {supplier.products[2].price}
                </span>
              </div>
              <div className="relative">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/legalServices.svg`}
                  alt={'legalServices'}
                  className={`w-full h-[95px] object-cover rounded-t-lg`}
                />
                <span className="w-full absolute bottom-5 bg-gray-400 text-lg opacity-80 font-bold text-center">
                  {supplier.products[3].price}
                </span>
              </div>
              <div className="relative">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/${supplier.products[3].image}`}
                  alt={'compressor'}
                  className={`w-full h-[95px] object-cover rounded-t-lg`}
                />
                <span className="w-full absolute bottom-5 bg-gray-400 text-lg opacity-80 font-bold text-center">
                  {supplier.products[4].price}
                </span>
              </div>
            </div>
            <div className="relative">
              <img
                src={`${process.env.PUBLIC_URL}/assets/${supplier.products[4].image}`}
                alt={'yellow-pipe'}
                className={`w-full h-[200px] object-cover rounded-t-lg`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifiedSuppliers;
