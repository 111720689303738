import React, { useMemo, useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import {
  createMenu,
  updateMenu,
  getAllMenu,
} from '../../../services/AccountServices'; // Import your API functions
import { useTranslation } from 'react-i18next';

interface NavigationMenuModalProps {
  onClose: () => void;
  initialValues?: any | null;
  isEditable: boolean;
  menu?: any; // Add the new menu prop
}

const validationSchema = Yup.object().shape({
  menuUrl: Yup.string().required('Menu URL is required'),
  menuName: Yup.string().required('Menu Name is required'),
});

const NavigationMenuModal: React.FC<NavigationMenuModalProps> = ({
  onClose,
  initialValues,
  isEditable,
  menu, // Destructure the new menu prop
}) => {
  const [navigationMenus, setNavigationMenus] = useState<any[]>([]);
  const currentPage = 1; // Define currentPage and itemsPerPage as needed
  const itemsPerPage = 100;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchMenusData = async () => {
      const pageRequest = { pageNumber: currentPage, pageSize: itemsPerPage };
      try {
        const response = await getAllMenu(pageRequest);
        if (response?.data) {
          setNavigationMenus(response.data);
        }
      } catch (error) {
        // console.error('Error fetching menus:', error);
        toast.error('Error occurred while fetching navigation menus');
      }
    };

    fetchMenusData();
  }, [currentPage, itemsPerPage]);

  const formInitialValues = useMemo(() => {
    if (menu) {
      return {
        ...menu,
        active: menu.active || false, // Ensure active is handled
        parentMenuId: menu?.parentMenu?.menuId || null, // Initialize parentMenuId
      };
    }
    return {
      parentMenuId: null,
      menuUrl: '',
      menuName: '',
      active: false, // Initialize toggle as false
    };
  }, [menu]);

  const handleSubmit = async (values: any) => {
    try {
      if (menu) {
        // Call the updateMenu API
        const response = await updateMenu(values);
        if (response?.isSuccess) {
          toast.success(response?.data?.message);
          onClose();
        } else {
          toast.error(response?.errorMessage);
        }
      } else {
        // Call the createMenu API
        const response = await createMenu(values);
        if (response?.isSuccess) {
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.errorMessage);
        }
      }
      onClose(); // Close modal after successful operation
    } catch (error: any) {
      toast.error('Error occurred while saving menu');
    }
    // console.log(values);
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-4xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            {menu ? 'Edit Navigation Menu' : 'Add Navigation Menu'}
          </h2>
          <button className="text-red-500 text-xl" onClick={onClose}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
              alt={t('close', 'close')}
            />
          </button>
        </div>

        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="flex flex-col gap-y-8 p-8 bg-white rounded-[30px]">
              <div className="grid grid-cols-1 gap-4 mb-4">
                {/* Parent Name Dropdown */}
                <div>
                  <label htmlFor="parentMenuId" className="block mb-1">
                    Parent Name
                  </label>
                  <Field
                    as="select"
                    // disabled={!isEditable}
                    name="parentMenuId"
                    className="w-full border rounded-lg text-sm p-2 h-11"
                  >
                    <option value="" label="Select Parent Name" />
                    {navigationMenus.map((navMenu, index) => (
                      <option
                        key={index}
                        value={navMenu.menuId}
                        label={navMenu.menuName}
                      />
                    ))}
                  </Field>
                  <ErrorMessage
                    name="parentName"
                    component="span"
                    className="text-red-500 text-sm"
                  />
                </div>

                {/* Menu URL */}
                <div>
                  <label htmlFor="menuUrl" className="block mb-1">
                    Menu URL<span className="text-red-500"> *</span>
                  </label>
                  <Field
                    // disabled={!isEditable}
                    name="menuUrl"
                    placeholder="Enter Menu URL"
                    className="w-full border rounded-lg text-sm p-2 h-11"
                  />
                  <ErrorMessage
                    name="menuUrl"
                    component="span"
                    className="text-red-500 text-sm"
                  />
                </div>

                {/* Menu Name */}
                <div>
                  <label htmlFor="menuName" className="block mb-1">
                    Menu Name<span className="text-red-500"> *</span>
                  </label>
                  <Field
                    // disabled={!isEditable}
                    name="menuName"
                    placeholder="Enter Menu Name"
                    className="w-full border rounded-lg text-sm p-2 h-11"
                  />
                  <ErrorMessage
                    name="menuName"
                    component="span"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>

              {/* Toggle Status */}
              <div className="flex flex-col">
                <label htmlFor="activeStatus" className="mr-2">
                  Toggle Status
                </label>
                <label className="flex items-center mt-2">
                  <Toggle
                    // disabled={!isEditable}
                    checked={values.active}
                    name="active"
                    icons={false}
                    onChange={(e) => setFieldValue('active', e.target.checked)} // Set toggle value correctly
                    className="toggle-green"
                  />
                  <span className="ml-2">Active</span>
                </label>
              </div>

              {/* Submit Button */}
              <div className="flex justify-end mt-4">
                <button
                  type="submit"
                  className="bg-green-500 text-white px-6 py-2 rounded-md"
                >
                  {menu ? 'Update Menu' : 'Save Menu'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NavigationMenuModal;
