import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaArrowLeft } from 'react-icons/fa';
import { BiChevronDown } from 'react-icons/bi';
import { getAllCategories } from '../../services/catalogService';
import { OptionType } from '../../types/interfaces';
import { getIndustries, getTags } from '../../services/supplierService';
import { useGlobalContext } from '../../contexts/GlobalContext';
import Select, { ActionMeta, MultiValue } from 'react-select';

interface FilterFormProps {
  onClose?: (values: any) => void;
}
const FilterForm: React.FC<FilterFormProps> = ({ onClose }) => {
  const [categories, setCategories] = useState<
    { categorId: string; categoryName: string }[]
  >([]);
  const [tags, setTags] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<OptionType[]>([]);
  const [subCategories, setSubCategories] = useState<
    { categorId: string; categoryName: string }[]
  >([]);
  const [isSubCategoryDisabled, setIsSubCategoryDisabled] = useState(true);
  const [industries, setIndustries] = useState<
    { industryId: string; industryName: string }[]
  >([]);

  const { supplierId, setSupplierId } = useGlobalContext();

  useEffect(() => {
    const fetchCategories = async () => {
      const params = { isParentCategory: true };
      const response = await getAllCategories(params);
      if (response?.isSuccess) {
        setCategories(response.data);
      }
    };

    const fetchSupplierTags = async () => {
      if (!supplierId) return;
      const response = await getTags(supplierId);
      if (response?.isSuccess) {
        setTags(response.data);
      }
    };

    const fetchIndustries = async () => {
      const response = await getIndustries();
      if (response?.isSuccess) {
        setIndustries(response.data);
      }
    };

    fetchCategories();
    fetchSupplierTags();
    fetchIndustries();
  }, []);

  const handleCategoryChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedCategoryId = event.target.value;
    formik.setFieldValue('category', selectedCategoryId);

    if (selectedCategoryId) {
      setIsSubCategoryDisabled(false);
      const params = {
        categoryId: selectedCategoryId,
        isParentCategory: false,
      };
      const response = await getAllCategories(params);
      if (response?.isSuccess) {
        setSubCategories(response.data);
      }
    } else {
      setIsSubCategoryDisabled(true);
      setSubCategories([]);
    }
  };

  const handleTagChange = (
    newValue: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    const selectedOptions = newValue as OptionType[];
    setSelectedTags(selectedOptions);
    formik.setFieldValue('tagIds', selectedOptions);
  };

  const validationSchema = Yup.object({
    // currency: Yup.string()
    //   .oneOf(['USD', 'NGN'], 'Invalid currency selected') // Currency validation
    //   .required('Currency is required'),
    // country: Yup.string().required('Country is required'),
    category: Yup.string().required('Category is required'),
    categoryId: Yup.string().required('Sub Category is required'),
    // industryId: Yup.string().required('Industry selection is required'),
    // tagIds: Yup.string().required('Tags selection is required'),
    startDate: Yup.date().nullable().required('Start date is required'),
    endDate: Yup.date()
      .nullable()
      .min(Yup.ref('startDate'), "End date can't be before start date") // End date validation
      .required('End date is required'),
  });
  const formik = useFormik({
    initialValues: {
      // currency: 'USD',
      country: '',
      category: '',
      categoryId: '',
      industryId: '',
      tagIds: [],
      startDate: '',
      endDate: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log('Form values:', values);
      // formik.resetForm();
      const tags = values.tagIds.map((tag: OptionType) => tag.value);
      const { category, ...filteredValues } = { ...values, tagIds: tags };
      const filteredValuesWithoutEmpty = Object.fromEntries(
        Object.entries(filteredValues).filter(
          ([_, v]) =>
            v !== undefined &&
            v !== null &&
            v !== '' &&
            (!Array.isArray(v) || v.length > 0)
        )
      );
      if (onClose) {
        onClose(filteredValuesWithoutEmpty);
      }
    },
  });

  return (
    <div className="p-16 bg-white rounded-xl shadow-md w-full h-full">
      <div className="flex items-center mb-12">
        <button onClick={onClose} className="mr-5">
          <FaArrowLeft size={15} />
        </button>
        <p className="text-2xl ">Filters</p>
      </div>
      <div className="h-[calc(100vh-12rem)] overflow-y-auto">
        <form onSubmit={formik.handleSubmit} className="space-y-4">
          {/* <div className="mb-10">
            <label className="block text-lg font-medium text-gray-700 mb-1">
              Currency
            </label>
            <div className="flex space-x-4">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="currency"
                  value="USD"
                  checked={formik.values.currency === 'USD'}
                  onChange={formik.handleChange}
                />
                <span>USD</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="currency"
                  value="NGN"
                  checked={formik.values.currency === 'NGN'}
                  onChange={formik.handleChange}
                />
                <span>NGN</span>
              </label>
            </div>
          </div> */}

          {/* Countries */}
          <div className="">
            <label className="block text-lg font-medium text-gray-700 mb-1">
              Country
            </label>
            <div className="relative">
              <select
                name="country"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.country}
                className="w-full border border-gray-400 rounded-lg px-4 py-3 appearance-none focus:outline-none bg-white"
              >
                <option value="">Select Country</option>
                <option value="">All</option>
                <option value="Algeria">Algeria</option>
                <option value="Egypt">Egypt</option>
                <option value="Ghana">Ghana</option>
                <option value="Namibia">Namibia</option>
                <option value="Tanzania">Tanzania</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
              <BiChevronDown
                className="absolute right-3 top-3 text-gray-500 pointer-events-none "
                size={15}
              />
            </div>
            {/* <div className="flex float-end">
              <p className="text-gray-700">
                selected <span>6</span> of <span>25</span>
              </p>
            </div> */}
            {formik.errors.country && formik.touched.country ? (
              <div className="text-red-600 text-sm">
                {formik.errors.country}
              </div>
            ) : null}
          </div>
          <div className="mb-10">
            <label className="block text-xl font-medium text-gray-700 mb-1">
              Category
            </label>
            <div className="relative">
              <select
                name="category"
                onChange={handleCategoryChange}
                onBlur={formik.handleBlur}
                value={formik.values.category}
                className="w-full border border-gray-400 rounded-lg px-4 py-3 appearance-none focus:outline-none bg-white"
              >
                <option value="">All</option>
                {categories.map((category) => (
                  <option key={category.categorId} value={category.categorId}>
                    {category.categoryName}
                  </option>
                ))}
              </select>
              <BiChevronDown
                className="absolute right-3 top-3 text-gray-500 pointer-events-none "
                size={15}
              />
            </div>
            {formik.errors.category && formik.touched.category ? (
              <div className="text-red-600 text-sm">
                {formik.errors.category}
              </div>
            ) : null}
          </div>
          <div className="mb-10">
            <label className="block text-xl font-medium text-gray-700 mb-1">
              Sub Category
            </label>
            <div className="relative">
              <select
                name="categoryId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.categoryId}
                className="w-full border border-gray-400 rounded-lg px-4 py-3 appearance-none focus:outline-none bg-white"
                disabled={isSubCategoryDisabled}
              >
                <option value="" disabled>
                  Select
                </option>
                {subCategories.map((subCategory) => (
                  <option
                    key={subCategory.categorId}
                    value={subCategory.categorId}
                  >
                    {subCategory.categoryName}
                  </option>
                ))}
              </select>
              <BiChevronDown
                className="absolute right-3 top-3 text-gray-500 pointer-events-none "
                size={15}
              />
            </div>
            {formik.errors.categoryId && formik.touched.categoryId ? (
              <div className="text-red-600 text-sm">
                {formik.errors.categoryId}
              </div>
            ) : null}
          </div>

          <div className="mb-10">
            <label className="block text-lg font-medium text-gray-500 mb-1">
              Industry
            </label>
            <div className="relative">
              <select
                name="industryId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.industryId}
                className="w-full border border-gray-400 rounded-lg px-4 py-3 appearance-none focus:outline-none bg-white"
              >
                <option value="">All</option>
                {industries.map((industry) => (
                  <option key={industry.industryId} value={industry.industryId}>
                    {industry.industryName}
                  </option>
                ))}
              </select>
              <BiChevronDown
                className="absolute right-3 top-3 text-gray-500 pointer-events-none"
                size={15}
              />
            </div>
            {formik.errors.industryId && formik.touched.industryId ? (
              <div className="text-red-600 text-sm">
                {formik.errors.industryId}
              </div>
            ) : null}
          </div>
          <div className="mb-10">
            <label className="block text-xl font-medium text-gray-500 mb-1">
              Tags
            </label>
            <div>
              <Select
                id="tagIds"
                isMulti
                options={tags.map((tag) => ({
                  value: tag.tagId,
                  label: tag.tagName,
                }))}
                value={selectedTags}
                onChange={handleTagChange}
                className="text-sm"
              />
              {formik.touched.tagIds && formik.errors.tagIds ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.tagIds}
                </div>
              ) : null}
            </div>
          </div>

          <div className="mb-10">
            <label className="block text-lg font-medium text-gray-700 mb-1">
              Start Date
            </label>
            <div className="relative">
              <input
                type="date"
                name="startDate"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.startDate}
                className="w-full border border-gray-400 rounded-lg px-4 py-2 focus:outline-none  "
              />
            </div>
            {formik.errors.startDate && formik.touched.startDate ? (
              <div className="text-red-600 text-sm">
                {formik.errors.startDate}
              </div>
            ) : null}
          </div>

          {/* End Date */}
          <div className="mb-10">
            <label className="block text-lg font-medium text-gray-700 mb-1">
              End Date
            </label>
            <div className="relative">
              <input
                type="date"
                name="endDate"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.endDate}
                className="w-full border border-gray-400 rounded-lg px-4 py-2 focus:outline-none"
              />
            </div>
            {formik.errors.endDate && formik.touched.endDate ? (
              <div className="text-red-600 text-sm">
                {formik.errors.endDate}
              </div>
            ) : null}
          </div>

          <div className="flex w-full justify-end">
            <div className="flex gap-4">
              <button
                type="button"
                onClick={() => formik.resetForm()}
                className="py-2 text-lg text-gray-700 font-semibold"
              >
                Clear Filters
              </button>
              <button
                type="submit"
                className="text-lg bg-primary border font-semibold rounded-lg py-2 px-6"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FilterForm;
