import React, { useState } from 'react';
import { ProfileProps, TabItemProps } from '../../../../types/interfaces';
import { useTranslation } from 'react-i18next';
import SeamlessSteelCylinder from './SeamlessSteelCylinder';
import SeamlessAluminiumCylinder from './SeamlessAluminiumCylinder';
import All from './All';

const Products: React.FC<ProfileProps> = ({ company }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<
    'All' | 'Seamless Steel Cylinder' | 'Seamless Aluminium Cylinder'
  >('All');
  // Handler for switching tabs
  const handleTabClick = (
    tab: 'All' | 'Seamless Steel Cylinder' | 'Seamless Aluminium Cylinder'
  ) => {
    setActiveTab(tab);
  };
  return (
    <div className="border border-gray-300 bg-white shadow-sm rounded-lg px-6 py-4">
      <div>
        <h2 className="text-2xl font-medium mb-2">Products</h2>
      </div>
      <hr />
      {/* Tabs Section */}
      <div className=" mb-4 mt-4 ">
        <div className="flex justify-start space-x-8 w-full px-3 pt-4 mb-4 ">
          <TabItem
            title={t('All', 'All')}
            isActive={activeTab === 'All'}
            onClick={() => handleTabClick('All')}
          />
          {/* <TabItem
            title={t('Seamless Steel Cylinder', ' Seamless Steel Cylinder')}
            isActive={activeTab === 'Seamless Steel Cylinder'}
            onClick={() => handleTabClick('Seamless Steel Cylinder')}
          />
          <TabItem
            title={t(
              'Seamless Aluminium Cylinder',
              ' Seamless Aluminium Cylinder'
            )}
            isActive={activeTab === 'Seamless Aluminium Cylinder'}
            onClick={() => handleTabClick('Seamless Aluminium Cylinder')}
          /> */}
        </div>
        {/* Content for Each Tab */}
        <div>
          {activeTab === 'All' && <All companyId={company?.companyId} />}
          {activeTab === 'Seamless Steel Cylinder' && <SeamlessSteelCylinder />}
          {activeTab === 'Seamless Aluminium Cylinder' && (
            <SeamlessAluminiumCylinder />
          )}
        </div>
      </div>
    </div>
  );
};
const TabItem: React.FC<TabItemProps> = ({ title, isActive, onClick }) => {
  return (
    <button
      className={`pb-2 text-lg font-medium focus:outline-none mb-8 ${
        isActive ? 'border-b-4 border-[#FFC726] font-bold' : 'text-gray-500'
      }`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};
export default Products;
