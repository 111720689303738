import React, { useState } from 'react';
import AppButton from '../../../../components/AppButton/AppButton';
import { IOrder } from '../../../../types/interfaces';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import OrderCompletedModal from '../../../../components/OrderCompletedModal/OrderCompletedModal';
import { toast } from 'react-toastify';

const OrderDetailsPage = () => {
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showOrderConfirmModal, setShowOrderConfirmModal] = useState(false);
  const [items, setItems] = useState<IOrder[]>([]);
  const { supplierId, setSupplierId } = useGlobalContext();
  const discount = 0;
  const shippingFee = 0;

  const data = {
    firstName: 'Samuel',
    lastName: 'McQueen',
    email: 'Samuel_McQueen@gmail.com',
    phoneNumber: '+1 (555) 000-0000',
    country: 'United States of America',
    officeAddress: 'POX 23, Lewisville Avenue',
    city: 'POX 23, Lewisville Avenue',
    suburb: 'Roswell',
    businessName: 'Shell USA',
    municipality: 'Atlanta',
    paymentMethod: 'Offline Mode',
    paymentStatus: 'Unpaid',
    paymentDescription:
      'One unit of a pressurized outer shell encloses a collection of tubes. One fluid flows through the tubes, while the other flows over the tubes.',
    uploadedFile: 'Payment Receipt.pdf',
  };
  return (
    <>
      <div className="py-4 px-8">
        <h2 className="text-2xl">Order Details</h2>
        <p className="text-gray-600 mt-2">Track and manage your orders</p>
        <div className="flex justify-between border border-gray-100 bg-white shadow-sm rounded-lg px-6 pt-4 pb-4 mt-5">
          <div>
            <h2 className="text-lg">Payment Received?</h2>
            <p className="text-lg">
              Kindly confirm or deny if you have received payment for this
              order.
            </p>
          </div>
          <div className="flex gap-8">
            <AppButton
              title={'Reject'}
              onClick={() => {}}
              className="rounded-lg text-lg font-semibold text-red-700 bg-gray-100 px-10 py-2"
            />
            <AppButton
              title={'Confirm'}
              onClick={() => setShowConfirmModal(true)}
              className="rounded-lg text-lg font-semibold text-white bg-green-600 px-6 py-2"
            />
          </div>
        </div>
        {showConfirmModal && (
          <ConfirmationModal
            title={'Approve Payment?'}
            desc={
              'By clicking confirm, you verify that you have received payment.'
            }
            appBtnConfirm={'Yes, confirm'}
            appBtnCancel={'Cancel'}
            onConfirm={() => setShowOrderConfirmModal(true)}
            onCancel={() => setShowConfirmModal(false)}
          />
        )}
        {showOrderConfirmModal && (
          <OrderCompletedModal
            orderNumber={''}
            orderText="This order is now active"
            orderStatus="Order Confirmed"
            appBtnOkay="Okay"
            onView={() => toast.info('view order')}
            onBack={() => setShowOrderConfirmModal(false)}
          />
        )}
        {/* Order Summary */}
        <div className=" border border-gray-100 bg-white shadow-sm rounded-lg px-10 pt-4 pb-4 mt-5">
          <div className=" mt-2">
            <h2 className="text-2xl">Order Summary</h2>
          </div>
          <div className="w-full flex justify-between mt-10">
            <div className="w-[40%]">
              {items.map((item) => (
                <div key={item.orderId} className="flex justify-between mt-8">
                  <div>
                    <img
                      src={
                        item.image ||
                        `${process.env.PUBLIC_URL}/assets/images/compressor.svg`
                      }
                      alt={item.descriptions || 'product'}
                      className={`w-full h-36 object-cover rounded-t-lg`}
                    />
                  </div>
                  <div className="flex flex-col gap-4 mt-4">
                    <p className="text-lg text-gray-600 font-semibold">
                      {item.descriptions}
                    </p>
                    <p className="text-lg font-semibold">
                      ${item.cost?.toLocaleString()}
                    </p>
                  </div>
                  <div>
                    <p className="text-gray-600 mt-4">{item.quantity}x</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-[45%] pr-10">
              <div className="flex justify-between mb-10">
                <div className="space-y-3 text-xl">
                  <p>Subtotal</p>
                  <p>Shipping</p>
                  <p>Discount</p>
                  <p>Tax</p>
                </div>
                <div className="space-y-3 text-xl">
                  <p className="font-bold">
                    $
                    {items
                      .reduce(
                        (acc, item) => acc + (item.cost || 0) * item.quantity,
                        0
                      )
                      .toLocaleString()}
                  </p>
                  <p className="text-green-500 font-semibold">
                    {shippingFee <= 0 ? 'FREE' : shippingFee}
                  </p>
                  <p className="">{discount <= 0 ? '-' : discount}</p>
                  <p>
                    {items.reduce(
                      (acc: number, item: IOrder) =>
                        acc +
                        ((item.vat || 0) / 100) * item.cost * item.quantity,
                      0
                    ) <= 0
                      ? '-'
                      : `$${items
                          .reduce(
                            (acc: number, item: IOrder) =>
                              acc +
                              ((item.vat || 0) / 100) *
                                item.cost *
                                item.quantity,
                            0
                          )
                          .toLocaleString()}`}
                  </p>
                </div>
              </div>
              <hr className="text-gray-400" />
              <div>
                <div className="flex justify-between mt-5 mb-8 items-center">
                  <p className="text-xl">Total</p>
                  <p className="text-2xl font-semibold">
                    $
                    {(
                      items.reduce(
                        (acc, item) => acc + (item.cost || 0) * item.quantity,
                        0
                      ) -
                      discount +
                      items.reduce(
                        (acc: number, item: IOrder) =>
                          acc +
                          ((item.vat || 0) / 100) * item.cost * item.quantity,
                        0
                      )
                    ).toLocaleString()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Shipping Information */}
        <div className=" border border-gray-100 bg-white shadow-sm rounded-lg px-10 pt-4 pb-8 mt-5">
          <h2 className="text-xl mb-6">Shipping Information</h2>
          <div className="grid grid-cols-2 gap-4 mb-10 ml-24">
            <div>
              <p className="text-lg text-gray-500 font-medium">First Name</p>
              <p className="text-black">{data.firstName}</p>
            </div>
            <div>
              <p className="text-lg text-gray-500 font-medium">Business Name</p>
              <p className="text-black">{data.businessName}</p>
            </div>
            <div>
              <p className="text-lg text-gray-500 font-medium">Last Name</p>
              <p className="text-black">{data.lastName}</p>
            </div>
            <div>
              <p className="text-lg text-gray-500 font-medium">
                Municipality/Region
              </p>
              <p className="text-black">{data.municipality}</p>
            </div>
            <div>
              <p className="text-lg text-gray-500 font-medium">Email</p>
              <p className="text-black">{data.email}</p>
            </div>
            <div>
              <p className="text-lg text-gray-500 font-medium">
                Suburb or Town
              </p>
              <p className="text-black">{data.suburb}</p>
            </div>
            <div>
              <p className="text-lg text-gray-500 font-medium">Country</p>
              <p className="text-black">{data.country}</p>
            </div>
            <div>
              <p className="text-lg text-gray-500 font-medium">Phone Number</p>
              <p className="text-black">{data.phoneNumber}</p>
            </div>
            <div>
              <p className="text-lg text-gray-500 font-medium">
                Office Address Line 1
              </p>
              <p className="text-black">{data.officeAddress}</p>
            </div>
            <div>
              <p className="text-lg text-gray-500 font-medium">
                Post Administrative/City
              </p>
              <p className="text-black">{data.city}</p>
            </div>
          </div>
          {/* Payment Method */}
          <div>
            <h2 className="text-xl mb-4">Payment Method</h2>
            <div className="ml-24">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-lg text-gray-500 font-medium mt-4">
                    Method
                  </p>
                  <p className="text-black">{data.paymentMethod}</p>
                </div>
                <div>
                  <p className="text-lg text-gray-500 font-medium">Status</p>
                  <p
                    className={`text-white font-bold px-4 py-1 rounded-full inline-block ${
                      data.paymentStatus === 'Unpaid'
                        ? 'bg-red-500'
                        : 'bg-green-500'
                    }`}
                  >
                    {data.paymentStatus}
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <p className="text-lg text-gray-500 font-medium">
                  Description or Reason for Payment
                </p>
                <p className="text-black">{data.paymentDescription}</p>
              </div>
              <div className="mt-4">
                <p className="text-lg text-gray-500 font-medium">
                  Upload Proof of Payment
                </p>
                <div className="flex items-center gap-3 mt-2">
                  <p className="w-[70%] bg-gray-100 py-2 px-3 rounded-lg">
                    {data.uploadedFile}
                  </p>
                  <p className=" text-green-600 text-lg underline">Download</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetailsPage;
